import { Product } from "../../service/products.service";
import { useAppDispatch, useAppSelector } from "../../store/store";
import React, { useState } from "react";
import { displayAmount, useLg, withLoading } from "../../service/utils";
import { ImageBlock } from "../../common/ImageBlock";
import { ProductOptions } from "./ProductOptions";
import { addProductToCart } from "../../service/checkout.slice";
import "./ProductCard.sass";
import { isProviderDelivery, selectedDeliveryProviderSelector } from "../../service/common.slice";
import productImagePlaceholder from "./../../assets/common/images/product-image-placeholder-squared.jpg";
import { IconPlus } from "../../common/Icons";

export const ProductCard = (
    { product, showPlaceHolderImage }: { product: Product, showPlaceHolderImage?: boolean }
) => {
    const deliveryProvider = useAppSelector(selectedDeliveryProviderSelector);
    const isDelivery = deliveryProvider ? isProviderDelivery(deliveryProvider) : false;

    const _ = useLg();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);
    const [optionsOpen, setOptionsOpen] = useState<boolean>(false);

    const addToCart = async () => {
        if (loading || !product.isActive) return;

        if (product.orderlyOptions.length > 0
            || product.description
            || product.image
        ) {
            setOptionsOpen(true);
            return;
        }
        wl.load(dispatch(addProductToCart(
            {
                productWithOptions: { product, options: [] },
                quantity: 1
            }
        )));
    };

    const undeliverable = !product.isActive && isDelivery
        && product.tags.includes("undeliverable");

    const stripHtml = (str: string) => {
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    return <>
        <div className={"product-card product " + (loading ? "loading" : "") + " " + (!product.isActive ? "inactive" : "")} data-product-id={product.id}
            onClick={addToCart}
        >
            <div className="loading-overlay">
                <div className="icon icon-refresh" />
            </div>
            <div className="image">
                {product.image && <ImageBlock src={product.image.medium_src} />}
                {!product.image && showPlaceHolderImage && <ImageBlock src={productImagePlaceholder} />}
            </div>
            <div className="product-name">
                <div className="name">{product.title}</div>
                <div className="description">
                    {stripHtml(product.subtitle ?? "")}
                </div>
            </div>
            {product.isActive && <div className="price">
                <div className="value">
                    {displayAmount(product.price.amount)}
                </div>
                <div className="add icon">
                    <IconPlus />
                </div>
            </div>}
            {!product.isActive && <>
                {!undeliverable && <div className="price unavailable">
                    {_("Nicht verfügbar")}
                </div>}
                {undeliverable && <div className="price unavailable">
                    {_("Nur Abholung")}
                </div>}
            </>}
        </div>
        <ProductOptions
            product={product}
            open={optionsOpen}
            onClose={() => setOptionsOpen(false)}
        />
    </>;
};
