import { useEffect, useMemo, useRef, useState } from "react";
import { ReservationHours } from "../../service/checkout.slice";
import {
    getDateOfWeekday,
    getTimesRange,
    getTodayWeekday,
    isPossibleReservationDate,
    minutesToTimeString,
    timeStringToMinutes,
    utcDateToUtcMinutes,
    utcDateToUtcTimeString,
    utcTimeStringToLocalTime
} from "../../service/utils";
import { OrderlySectorFragment } from "../../service/__generated__/OrderlySectorFragment";

export type SameDayPreOrderTimeSelectorProps = {
    currentDay: Date | null;
    sector: OrderlySectorFragment;
    onDateChange: (date: Date | null) => void
}

type SelectedDate = {
    day: number;
    time: string;
}


export const SameDayPreOrderTimeSelector = (props: SameDayPreOrderTimeSelectorProps) => {
    const [times, setTimes] = useState<ReservationHours | null>(null);
    const [componentDate, _setDate] = useState<SelectedDate | null>(null);
    const setDateTO = useRef<number | null>(null);

    const setDate = (d: SelectedDate) => {
        if (setDateTO.current) {
            clearTimeout(setDateTO.current);
        }
        setDateTO.current = setTimeout(() => _setDate(d), 300) as any as number;
    }

    const reservationHoursSorted = useMemo(() => {
        let reservationHours = [...(props.sector.reservationHours || []) as ReservationHours[]];
        reservationHours.sort((a, b) => a.weekday - b.weekday);
        reservationHours = reservationHours.filter(s => s.active);

        const today = getTodayWeekday();
        const todayReservationHoursIdx = reservationHours.findIndex(r => r.weekday === today);
        if (todayReservationHoursIdx >= 0) {
            const todayReservationHours = { ...reservationHours[todayReservationHoursIdx] };
            const startTimeMinutes = timeStringToMinutes(todayReservationHours.start_time),
                endTimeMinutes = timeStringToMinutes(todayReservationHours.end_time);
            const nowTimeUtcMinutes = utcDateToUtcMinutes(new Date());
            if (nowTimeUtcMinutes > startTimeMinutes) {
                const nextStartTimeMinutes = Math.ceil(nowTimeUtcMinutes / 30) * 30;
                todayReservationHours.start_time = minutesToTimeString(nextStartTimeMinutes);
                reservationHours.splice(todayReservationHoursIdx, 1, todayReservationHours);
            }
            if (nowTimeUtcMinutes > endTimeMinutes - 30) {
                reservationHours.splice(todayReservationHoursIdx, 1);
            }
        }


        const reservationHoursSorted = [...reservationHours];
        reservationHoursSorted.sort((a, b) => getDateOfWeekday(a.weekday).getTime() - getDateOfWeekday(b.weekday).getTime());

        return reservationHoursSorted;
    }, [props.sector]);


    useEffect(() => {
        if (reservationHoursSorted.length === 0) return;

        if (props.currentDay && isPossibleReservationDate(props.currentDay, props.sector)) {
            let day = props.currentDay.getDay() - 1;
            if (day < 0) day = 6;
            setTimes(reservationHoursSorted.find(r => r.weekday === day)!);
            setDate({
                day, time: utcDateToUtcTimeString(props.currentDay)
            });
        } else {
            setTimes(reservationHoursSorted[0]);
            setDate({
                day: reservationHoursSorted[0].weekday,
                time: reservationHoursSorted[0].start_time
            });
        }
    }, [props.currentDay, props.sector, reservationHoursSorted]);

    const onTimeChange = (time: string) => {

        const newComponentDate = {
            ...componentDate,
            time,
        } as SelectedDate;
        _setDate(newComponentDate);

        const selectedMinutesUtc = timeStringToMinutes(time);
        const selectedMinutesLocal = selectedMinutesUtc - new Date().getTimezoneOffset();
        const { day } = componentDate!;
        const targetDay = getDateOfWeekday(day);
        targetDay.setHours(Math.floor(selectedMinutesLocal / 60));
        targetDay.setMinutes(selectedMinutesLocal % 60);

        props.onDateChange(targetDay);
    }


    let timesRange: string[] | null = null;
    if (times) {
        timesRange = getTimesRange(times.start_time, times.end_time);
    }

    return <div className="reservation-times">

        <div className={"select-container"}>
            <select
                disabled={false}
                onChange={e => onTimeChange(e.target.value as any)}
                value={componentDate?.time}
                style={{ width: '200px', height: '50px', paddingLeft: '8px', paddingRight: '8px' }}
            >
                {timesRange && <>
                    {timesRange.map((tS, i) => {
                        return <option
                            value={tS}
                            key={tS}
                        >
                            {utcTimeStringToLocalTime(tS)}
                        </option>
                    })}
                </>}
            </select>
        </div>

    </div>;


};