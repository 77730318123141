import React from "react";
import {__, TranslatedString} from "../../translations";
import {useNavigate} from "react-router-dom";
import paymentError from "../../assets/common/images/payment-error.svg";
import {useLg} from "../../service/utils";


export const PaymentError = () => {
    const _ = useLg();
    const navigate = useNavigate();
    const back = () => {
        navigate("/checkout/step3");
    };

    return <div className="page-unavailable bb-container">
        <div className="img">
            <img src={paymentError} alt="Payment error"/>
        </div>
        <h3>
            {_("Zahlungsabbruch")}
        </h3>
        <div className="comment">
            <TranslatedString str={__("Während des Zahlungsvorganges gab es einen Fehler oder einen Abbruch. Gehe zum Warenkorb zurück und versuche es erneut.")}/>
        </div>
        <div className="bb-button-container">
            <button className="btn btn-primary big-primary"
                    onClick={back}
            >
                {_("Zum Warenkorb")}
            </button>
        </div>
    </div>;
};
