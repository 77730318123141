import { useEffect, useMemo, useRef, useState } from "react";
import { CheckInput } from "../../common/contols/CheckInput";
import { DownPopup } from "../../common/DownPopup";
import { ReservationHours } from "../../service/checkout.slice";
import {
    dateOnlyToString,
    getDateOfWeekday,
    getTimesRange, getTodayWeekday,
    isPossibleReservationDate,
    isSectorActive,
    minutesToTimeString,
    timeStringToMinutes,
    useLg,
    utcDateToUtcMinutes,
    utcDateToUtcTimeString,
    utcTimeStringToLocalTime,
    weekdayTranslationKeys
} from "../../service/utils";
import { OrderlySectorFragment } from "../../service/__generated__/OrderlySectorFragment";
import { ReservationOrderType } from "./checkout-steps/CheckoutStep2";
import { useNavigate } from "react-router-dom";

export type ReservationsTimeDownPopupProps = {
    show: boolean;
    onClose: () => void;
    orderType: ReservationOrderType;
    onOrderTypeChange: (orderType: ReservationOrderType) => void;
    date: Date | null;
    isDelivery: boolean;
    isTableService: boolean;
    sameDayPreorderEnabled: boolean;
    onDateChange: (date: Date | null) => void
    sector: OrderlySectorFragment;
}

type SelectedDate = {
    day: number;
    time: string;
}


const getDateStringOfWeekday = (weekday: number) => {
    return dateOnlyToString(getDateOfWeekday(weekday));
};

export const ReservationsTimeDownPopup = (props: ReservationsTimeDownPopupProps) => {
    const _ = useLg();
    const navigate = useNavigate();

    const reservationHoursSorted = useMemo(() => {
        let reservationHours = [...(props.sector.reservationHours || []) as ReservationHours[]];
        reservationHours.sort((a, b) => a.weekday - b.weekday);
        reservationHours = reservationHours.filter(s => s.active);

        const today = getTodayWeekday();
        const todayReservationHoursIdx = reservationHours.findIndex(r => r.weekday === today);
        if (todayReservationHoursIdx >= 0) {
            const todayReservationHours = { ...reservationHours[todayReservationHoursIdx] };
            const startTimeMinutes = timeStringToMinutes(todayReservationHours.start_time),
                endTimeMinutes = timeStringToMinutes(todayReservationHours.end_time);
            const nowTimeUtcMinutes = utcDateToUtcMinutes(new Date());
            if (nowTimeUtcMinutes > startTimeMinutes) {
                const nextStartTimeMinutes = Math.ceil(nowTimeUtcMinutes / 30) * 30;
                todayReservationHours.start_time = minutesToTimeString(nextStartTimeMinutes);
                reservationHours.splice(todayReservationHoursIdx, 1, todayReservationHours);
            }
            if (nowTimeUtcMinutes > endTimeMinutes - 30) {
                reservationHours.splice(todayReservationHoursIdx, 1);
            }
        }


        const reservationHoursSorted = [...reservationHours];
        reservationHoursSorted.sort((a, b) => getDateOfWeekday(a.weekday).getTime() - getDateOfWeekday(b.weekday).getTime());

        return reservationHoursSorted;
    }, [props.sector]);
    const hasReservations = reservationHoursSorted.length > 0;

    const [date, _setDate] = useState<SelectedDate | null>(null);
    const [times, setTimes] = useState<ReservationHours | null>(null);

    const setDateTO = useRef<number | null>(null);

    const setDate = (d: SelectedDate) => {
        if (setDateTO.current) {
            clearTimeout(setDateTO.current);
        }
        setDateTO.current = setTimeout(() => _setDate(d), 300) as any as number;
    }

    useEffect(() => {
        if (reservationHoursSorted.length === 0) return;

        if (props.date && isPossibleReservationDate(props.date, props.sector)) {
            let day = props.date.getDay() - 1;
            if (day < 0) day = 6;
            setTimes(reservationHoursSorted.find(r => r.weekday === day)!);
            setDate({
                day, time: utcDateToUtcTimeString(props.date)
            });
        } else {
            setTimes(reservationHoursSorted[0]);
            setDate({
                day: reservationHoursSorted[0].weekday,
                time: reservationHoursSorted[0].start_time
            });
        }
    }, [props.date, props.sector, reservationHoursSorted]);

    const onDayChange = (day: number) => {
        const r = reservationHoursSorted.find(h => h.weekday === day)!
        setTimes(r);
        setDate({
            day, time: r.start_time
        })
    }

    const onTimeChange = (time: string) => {
        setDate({
            ...date,
            time,
        } as SelectedDate)
    }

    const close = () => {

        if (!date) {
            props.onClose();
            return
        }
        const { day, time } = date;

        if (!time) {
            props.onClose();
            return
        }
        if (props.orderType === "immediate") {

            props.onClose();
            return
        }

        const selectedMinutesUtc = timeStringToMinutes(time);
        const selectedMinutesLocal = selectedMinutesUtc - new Date().getTimezoneOffset();

        const targetDay = getDateOfWeekday(day);
        targetDay.setHours(Math.floor(selectedMinutesLocal / 60));
        targetDay.setMinutes(selectedMinutesLocal % 60);
        props.onDateChange(targetDay);
        props.onClose()
    }

    let timesRange: string[] | null = null;
    if (times) {
        timesRange = getTimesRange(times.start_time, times.end_time);
    }
    
    const hasAvailableOptions = isSectorActive(props.sector) || hasReservations;

    return <DownPopup
        show={props.show}
        onClose={close}
        showCloseIcon={hasAvailableOptions}
    >
        <div className="header"> {props.isDelivery ? _("Voraussichtl. Lieferzeitpunkt") : props.isTableService === true ? _("Tisch ab") : _("Abholzeitpunkt")}</div>
        <div className="reservations-time-popup">
            {hasAvailableOptions
                ? (
                    <>
                        <div className="form-check primary">
                            <CheckInput
                                className="form-check-input"
                                type="radio"
                                name="order-type"
                                id={"order-type-immediate"}
                                onChange={() => props.onOrderTypeChange("immediate")}
                                value={"immediate"}
                                checked={props.orderType === "immediate"}
                                disabled={!isSectorActive(props.sector)}
                            />
                            <label className="form-check-label"
                                htmlFor={"order-type-immediate"}
                            >
                                {_("Sobald wie möglich") + " "}
                                {!isSectorActive(props.sector) ? `(${_("nicht verfügbar")})` : ""}
                            </label>
                        </div>
                        {hasReservations && <>
                            <hr />
                            <div className="form-check primary">
                                <CheckInput
                                    className="form-check-input"
                                    type="radio"
                                    name="order-type"
                                    id={"order-type-reservation"}
                                    onChange={() => props.onOrderTypeChange("reservation")}
                                    value={"reservation"}
                                    checked={props.orderType === "reservation"}
                                />
                                <label className="form-check-label"
                                    htmlFor={"order-type-reservation"}
                                >
                                    {_("Andere Uhrzeit / nächster Tag")}
                                    <div className="label-comment">
                                        {_("Du möchtest dein Essen später abholen oder bis zu einer Woche im Vorraus bestellen?")}
                                    </div>
                                </label>
                            </div>
                            <div className="reservation-times">
                                <div className="reservation-times-input-container">
                                    <label className="form-label fw-bold">
                                        {_("Tag")}
                                    </label>
                                    <div className={"select-container"}>
                                        <select
                                            onChange={e => onDayChange(Number(e.target.value as any))}
                                            value={date?.day}
                                            disabled={props.sameDayPreorderEnabled}
                                        >
                                            {reservationHoursSorted.map((rH, i) => {
                                                return <option value={rH.weekday} key={rH.weekday}>
                                                    {_(weekdayTranslationKeys[rH.weekday])}&nbsp;
                                                    {getDateStringOfWeekday(rH.weekday)}
                                                </option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="reservation-times-input-container">
                                    <label className="form-label fw-bold">
                                        {_("Uhrzeit")}
                                    </label>
                                    <div className={"select-container"}>
                                        <select
                                            disabled={!timesRange}
                                            onChange={e => onTimeChange(e.target.value as any)}
                                            value={date?.time}
                                        >
                                            {timesRange && <>
                                                {timesRange.map((tS, i) => {
                                                    return <option
                                                        value={tS}
                                                        key={tS}
                                                    >
                                                        {utcTimeStringToLocalTime(tS)}
                                                    </option>
                                                })}
                                            </>}
                                        </select>
                                    </div>
                                    <div className="bb-button-container" style={{ paddingTop: '40px' }}>
                                        <button className="btn btn-primary big-primary"
                                            onClick={close}
                                        >
                                            {_("bestätigen")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </>
                )
                : (
                    <>
                        <div className="no-options">
                            {_("Derzeit ist der Sektor nicht verfügbar und es sind keine Reservierungen möglich")}
                        </div>
                        <div>
                            <button className="btn btn-primary big-primary"
                                onClick={()=>navigate("/")}
                            >
                                {_("Zur Homeseite")}
                            </button>
                        </div>
                        
                    </>
                )
            }
            
        </div>
    </DownPopup>
}