import {OrderlyGeoLocationInput} from "../../service/checkout.service";
import {OrderlyTheme} from "../../service/common.service";
import {useEffect, useState} from "react";


export const getCurrentGeoLocation = async (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            position => {
                resolve(position)
            },
            reject,
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0,
            }
        );
    })
}

export const sleep = (timeMs: number) => new Promise(resolve=>setTimeout(resolve, timeMs))

export const useWatchCurrentLocation = (): [GeolocationPosition|undefined, GeolocationPositionError|undefined] => {
    const [location, setLocation] = useState<GeolocationPosition|undefined>(undefined);
    const [error, setError] = useState<GeolocationPositionError|undefined>(undefined);

    useEffect(()=>{
        const id = navigator.geolocation.watchPosition(
            (position) => {
                setLocation(position);
                setError(undefined);
            },
            (e) => {
                setLocation(undefined);
                setError(e)
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            });
        return () => {
            navigator.geolocation.clearWatch(id);
        }
    }, []);

    return [location, error];
}

export const isLocationInRadius = (location: OrderlyGeoLocationInput, theme: OrderlyTheme) => {
    const distance = haversineDistance(
        {latitude: location.latitude!, longitude: location.longitude!},
        {latitude: theme.geoFenceCircle?.latitude!, longitude: theme.geoFenceCircle?.longitude!}
    );
    return distance < theme.geoFenceCircle?.radiusInMeters!;
}


// from: https://github.com/dcousens/haversine-distance/blob/main/index.js
const asin = Math.asin
const cos = Math.cos
const sin = Math.sin
const sqrt = Math.sqrt
const PI = Math.PI

// equatorial mean radius of Earth (in meters)
const R = 6378137

function squared (x: number) { return x * x }
function toRad (x: number) { return x * PI / 180.0 }
function hav (x: number) {
    return squared(sin(x / 2))
}

// hav(theta) = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLon - aLon)
type Coord = {
    latitude: number;
    longitude: number;
}

function haversineDistance (a: Coord, b: Coord) {
    const aLat = toRad(a.latitude)
    const bLat = toRad(b.latitude )
    const aLng = toRad(a.longitude)
    const bLng = toRad(b.longitude)

    const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLng - aLng)
    return 2 * R * asin(sqrt(ht))
}
