import React from "react";
import {useLg} from "../../../service/utils";
import { useAppSelector } from "../../../store/store";
import { selectedSectorSelector } from "../../../service/common.slice";

type SeatNumberInputProps = {
    error?: string;
    value: string;
    onChange: (value: string)=>void;
    isTableService: boolean;
}

export const SeatNumberInput = ({error, value, onChange, isTableService}: SeatNumberInputProps) => {
    const sector = useAppSelector(selectedSectorSelector);
    const hasAdditionalSeatField = sector?.isThirdSeatFieldEnabled||false;
    const _ = useLg();
    let row = value.split('/')[0].trim()
    let seat = (value.split('/')[1]||"").trim();
    let additional = (value.split('/')[2]||"").trim();

    const setRow = (value: string) => {
        row = value;
        setComboSeat();
    }
    const setSeat = (value: string) => {
        seat = value;
        setComboSeat();
    }
    const setAdditional = (value: string) => {
        additional = value;
        setComboSeat();
    }
    const setComboSeat = () => {
        if (hasAdditionalSeatField) {
            onChange(`${row}/${seat}/${additional}`);
        } else {
            onChange(`${row}/${seat}`);
        }
    }
    const setPlace = (value: string) => {
        onChange(value);
    }

    return <div className={"input-container " + (error ? "error" : "")}>
        {isTableService
            ? <>
                <div className="place-input full-width">
                    <input
                        type="text"
                        placeholder={_("Platz")}
                        value={value}
                        onChange={e => setPlace(e.target.value)}
                    />
                </div>
            </>
            : <>
                <div className="place-input">
                    <input
                        type="text"
                        placeholder={_("SeatField_1")}
                        value={row}
                        onChange={e => setRow(e.target.value)}
                    />
                    <div className="separator" />
                    <input
                        type="text"
                        placeholder={_("SeatField_2")}
                        value={seat}
                        onChange={e => setSeat(e.target.value)}
                    />
                    {hasAdditionalSeatField&&<>
                        <div className="separator" />
                        <input
                            type="text"
                            placeholder={_("SeatField_3")}
                            value={additional}
                            onChange={e => setAdditional(e.target.value)}
                        />
                    </>}
                </div>
            </>
        }
        
    </div>;
}
