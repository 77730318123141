import "./CurrentOrdersShort.sass";
import {useNavigate} from "react-router-dom";
import {getOrderColorAndStatus, useLg} from "../../service/utils";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {languageSelector, ordersSelector, refreshOrders} from "../../service/common.slice";
import {useEffect} from "react";

export const CurrentOrdersShort = (
    {showCartIcon}:{showCartIcon?: boolean}
) => {
    const dispatch = useAppDispatch();
    const lang = useAppSelector(languageSelector);
    const _ = useLg();
    const navigate = useNavigate();
    const orders = useAppSelector(ordersSelector);

    useEffect(()=>{
        const to = setInterval(()=>{
            dispatch(refreshOrders());
        }, 10000);
        return () => {
            clearInterval(to);
        };
    }, []); // eslint-disable-line

    if (!orders || orders.length === 0) return <div/>;

    return <div className={"current-orders-short"}>
        {orders.map(order=>{
            const goto = () => {
                navigate("/order?order_id="+order._id)
            };
            const {status, color} = getOrderColorAndStatus(order);

            return <div className="order"
                        key={order._id}
                        style={{backgroundColor: color}}
                        onClick={goto}
            >
                {showCartIcon&&<div className="checkout-icon checkout-icon-cart-2 cart-icon" />}
                <div className="order-number">{_("Bestellnr.")} {order.orderNumber}</div>
                <div className="status">
                    {status(lang)}

                    <div className="checkout-icon checkout-icon-arrow-right-white-2"/>
                </div>
            </div>
        })}
    </div>;
};
