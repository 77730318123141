import React, {useEffect, useState} from "react";
import {PageHeader} from "../../common/PageHeader";
import {__} from "../../translations";
import {useNavigate} from "react-router-dom";
import {OrderIsEmptyBox} from "./OrderIsEmptyBox";
import {getOrderColorAndStatus, getOrderedTime, useLg, withLoading} from "../../service/utils";
import {OrderTotal} from "./OrderTotal";
import "./CheckoutPage.sass";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {isProviderDelivery, languageSelector, refreshOrders} from "../../service/common.slice";
import {CreatedOrderFragment} from "../../service/__generated__/CreatedOrderFragment";
import {OrderItems} from "./OrderItems";


export const MyOrdersPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);
    const [orders, setOrders] = useState<CreatedOrderFragment[]>([]);

    const back = () => {
        navigate("/");
    };

    useEffect(()=>{
        wl.load(dispatch(refreshOrders()).unwrap())
            .then(orders=>setOrders(orders));
        return ()=>wl.cancelLoad();
    }, []); // eslint-disable-line

    if (loading || orders.length === 0) {
        return <>
            <PageHeader
                header={__("Meine Bestellungen")}
                isBackAvailable={true}
                onBack={back}
            />
            <div className={"checkout-contents " + (loading?"opacity-blink":"")}>
                {loading&&<OrderIsEmptyBox label={__("Loading")}/>}
                {!loading&&<OrderIsEmptyBox label={__("Sie haben noch keine Bestellung aufgegeben")}/>}
            </div>
        </>;
    }

    return <>
        <PageHeader
            header={__("Meine Bestellungen")}
            isBackAvailable={true}
            onBack={back}
        />
        <div className="checkout-contents">
            {orders.map(o=><OrderInList order={o} key={o._id}/>)}
        </div>
    </>;
};


const OrderInList = (
    {order}: {order: CreatedOrderFragment}
) => {
    const lang = useAppSelector(languageSelector);
    const _ = useLg();
    const navigate = useNavigate();
    const goto = () => {
        navigate("/order?order_id="+order._id+"&from="+encodeURIComponent("/orders"))
    };
    const {status, color} = getOrderColorAndStatus(order);

    const deliveryProviderName = 
        order.orderlyIsTableservice
            ? _("Tischservice")
            : isProviderDelivery(order.deliveryProvider as any)
                ? _("Lieferung")
                : _("Abholung");

    return <div className="box cart" onClick={goto}>

        <div className="order-summary-box">
            <div className="summary-row">
                <div><strong>{_("Bestellnummer")}</strong></div>
                <div className="right">
                    <strong>{order?.orderNumber}</strong>
                </div>
            </div>
            <div className="summary-row">
                <div className="checkout-icon checkout-icon-time-black" />
                <div>{_("Bestellt um")}</div>
                <div className="right">
                    {getOrderedTime(order)}
                </div>
            </div>
            <div className="summary-row">
                {order.deliveryProvider&&<div className="fw-bolder">
                    {deliveryProviderName}
                </div>}
                <div className="right" style={{color}}>
                    {status(lang)}
                </div>
            </div>
        </div>
        <OrderItems
            orderItems={order.items!}
            readOnly={true}
        />
        <OrderTotal
            cart={order}
            displayTipSection={false}
        />
    </div>
};
