import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store/store";
import {hasUnavailableProductsInCart, isSectorActive, sectorHasAvailableReservations, showError} from "./utils";
import {OrderItemFragment} from "./__generated__/OrderItemFragment";
import {CartFragment} from "./__generated__/CartFragment";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {
    addToCart,
    getCart, getFullCartStatus,
    OrderlyGeoLocationInput,
    ProductWithOptions,
    removeFromCart, requestPaymentLink,
    saveOrderAdditionalData,
    saveOrderDeliveryMode, saveOrderGeoLocation,
    saveOrderSeatNumber,
    saveOrderTip,
    setOrderPositionQty
} from "./checkout.service";
import {PaymentProviderFragment} from "./__generated__/PaymentProviderFragment";
import {commonSlice, selectedSectorSelector} from "./common.slice";
import {createGetters, createSetter} from "../store/sliceHelpers";

export enum CheckoutStep {
    Cart,
    Form,
    Payment
}

interface CheckoutSlice {
    cart?: CartFragment;
    geoLocationObtained: boolean
}

const initialState: CheckoutSlice = {
    cart: undefined,
    geoLocationObtained: false
};

const setter = createSetter<CheckoutSlice>();

export const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        setCart: (state, action: PayloadAction<CartFragment|undefined>) => {
            state.cart = action.payload;
        },
        setGeoLocationObtained: setter("geoLocationObtained")
    }
});

const {getter} = createGetters(state => state.checkout);
export const cartSelector = (state: RootState) => state.checkout.cart;
export const geoLocationObtainedSelector = getter("geoLocationObtained");

export const getCartQty = (cart: CartFragment): number => {
    return cart.items!
        .map(value => value.quantity)
        .reduce((acc, v) => acc + v, 0);
};

export const getCartItemPrice = (item: OrderItemFragment): number => {
    return item.total!.amount - item.discount!.amount;
};

export const dispatchCartFragment = createAsyncThunk(
    "dispatchCartFragment",
    async (cart: CartFragment, {dispatch}) => {
        await dispatch(commonSlice.actions.setDelivery(cart.deliveryObject||undefined));
        await dispatch(checkoutSlice.actions.setCart(
            (!cart.items)?undefined:cart
        ));
    }
);

export const refreshCart = createAsyncThunk(
    "refreshCart",
    async (_: void, {dispatch}) => {
        const cart = await getCart();
        await dispatch(dispatchCartFragment(cart));
    }
);

export type ReservationHours = {
    weekday: number;
    start_time: string;
    end_time: string;
    active: boolean;
}

export enum CartStatus {
    OK,
    UnavailableProducts,
    UnavailableSector,
    UnavailableSectorWithReservations,
    UnavailableDelivery
}

export const getCartStatus = createAsyncThunk(
    "canProceedToNextCheckoutStep",
    async (_: void, {dispatch, getState}): Promise<CartStatus> => {
        const state = (getState() as RootState);
        const fullStatus = await getFullCartStatus();
        await dispatch(dispatchCartFragment(fullStatus.cart));
        await dispatch(commonSlice.actions.setCommonInfo(fullStatus.common));

        const cart = cartSelector(state);
        if (hasUnavailableProductsInCart(cart)) {
            return CartStatus.UnavailableProducts;
        }
        const selectedSector = selectedSectorSelector(state);
        if (!selectedSector 
            || (
                !isSectorActive(selectedSector) && !sectorHasAvailableReservations(selectedSector)
            )
        ) {
            return CartStatus.UnavailableSector;
        }
        let delivery = fullStatus.common.delivery as unknown as any;
        if (!delivery
            || !delivery.activePickUpLocation
            || !delivery.activePickUpLocation.orderlyIsAvailable
        ) {
            return CartStatus.UnavailableDelivery;
        }
        if (!isSectorActive(selectedSector)) {
            return CartStatus.UnavailableSectorWithReservations;
        }
        return CartStatus.OK;
    }
);

export const navigateByCartStatus = (
    navigateFn: (path: string)=>void,
    cartStatus: CartStatus,
    next?: string
): boolean => {
    if (cartStatus === CartStatus.OK
        || cartStatus === CartStatus.UnavailableSectorWithReservations
    ) {
        if (next) {
            navigateFn(next);
        }
        return true;
    }
    if (cartStatus === CartStatus.UnavailableProducts) {
        navigateFn("/checkout");
        return false;
    }
    if (cartStatus === CartStatus.UnavailableSector) {
        navigateFn("/sector_unavailable");
        return false;
    }
    if (cartStatus === CartStatus.UnavailableDelivery) {
        navigateFn("/delivery_unavailable");
        return false;
    }
    return false;
};

export const addProductToCart = createAsyncThunk(
    "addProductToCart",
    async (
        {productWithOptions, quantity}:
        {productWithOptions: ProductWithOptions, quantity: number}
        , {dispatch}) => {
        try {
            await addToCart(productWithOptions, quantity);
            const cart = await getCart();
            dispatch(dispatchCartFragment(cart));
        } catch (e: any) {
            showError(e.toString());
        }
    }
);

export const removeProductFromCart = createAsyncThunk(
    "removeProductFromCart",
    async (orderItemFragment: OrderItemFragment, {dispatch}) => {
        try {
            await removeFromCart(orderItemFragment);
            const cart = await getCart();
            dispatch(dispatchCartFragment(cart));
        } catch (e: any) {
            showError(e.toString());
        }
    }
);

export const setOrderItemQty = createAsyncThunk(
    "increaseCartPosition",
    async (
        {orderItem, quantity}: {orderItem: OrderItemFragment, quantity: number},
        {dispatch}
    ) => {
        try {
            await setOrderPositionQty(orderItem, quantity);
            const cart = await getCart();
            dispatch(dispatchCartFragment(cart));
        } catch (e: any) {
            showError(e.toString());
        }
    }
);

export const setOrderAdditionalData = createAsyncThunk(
    "setSeatPhoneComment",
    async (
        {seat, phone, reservationDate}: {seat: string, phone: string, reservationDate?: Date},
        {dispatch}
    ) => {
        try {
            const cart = await saveOrderAdditionalData(seat, phone, reservationDate);
            dispatch(dispatchCartFragment(cart));
        } catch (e: any) {
            showError(e.toString());
            throw e;
        }
    }
);

export const setOrderSeatNumber = createAsyncThunk(
    "setOrderSeatNumber",
    async ({seat}: {seat: string}, {dispatch}) => {
        try {
            const cart = await saveOrderSeatNumber(seat);
            dispatch(dispatchCartFragment(cart));
        } catch (e: any) {
            showError(e.toString());
            throw e;
        }
    }
)

export const setTipPercentage = createAsyncThunk(
    "setTipPercentage",
    async ({tipPercentage}: {tipPercentage: number}, {dispatch}) => {
        try {
            const cart = await saveOrderTip(tipPercentage);;
            dispatch(dispatchCartFragment(cart));
        } catch (e: any) {
            showError(e.toString());
            throw e;
        }
    }
)

export const setOrderDeliveryMode = createAsyncThunk(
    "setOrderDeliveryMode",
    async (
        {isTableservice, geoLocationEnabled}: {isTableservice: boolean, geoLocationEnabled: boolean},
        {dispatch}
    ) => {
        try {
            const cart = await saveOrderDeliveryMode(isTableservice, geoLocationEnabled);
            dispatch(dispatchCartFragment(cart));
        } catch (e: any) {
            showError(e.toString());
            throw e;
        }
    }
)

export const setOrderGeoLocation = createAsyncThunk(
    "setOrderGeoLocation",
    async (
        geoLocation: OrderlyGeoLocationInput,
        {dispatch}
    ) => {
        const cart = await saveOrderGeoLocation(geoLocation);
        dispatch(dispatchCartFragment(cart));
    }
)

export const getPaymentLink = createAsyncThunk(
    "getPaymentLink",
    async (provider: PaymentProviderFragment, {dispatch, getState}) => {
        try {
            const cart = cartSelector(getState() as RootState)!;
            return await requestPaymentLink(provider, cart._id);
        } catch (e: any) {
            showError(e.toString());
            throw e;
        }
    }
);

