import "./CheckoutSteps.sass";
import {useAppSelector} from "../../../store/store";
import {CheckoutStep} from "../../../service/checkout.slice";
import {isProviderDelivery, selectedDeliveryProviderSelector} from "../../../service/common.slice";
import {useNavigate} from "react-router-dom";
import { CheckoutIconStep1Active, CheckoutIconStep2, CheckoutIconStep2Active, CheckoutIconStep2Point, CheckoutIconStep2PointActive, CheckoutIconStep3, CheckoutIconStep3Active } from "../Icons";
import {useLg} from "../../../service/utils";


export const CheckoutSteps = ({step}: {step: CheckoutStep}) => {
    const _ = useLg();
    const deliveryProvider = useAppSelector(selectedDeliveryProviderSelector);
    const isDelivery = deliveryProvider?isProviderDelivery(deliveryProvider):false;
    const navigate = useNavigate();

    const step2Label = isDelivery?
        _("Sitzplatz"):
        _("Kontaktdaten");

    const goToStep1 = () => {
        navigate("/checkout");
    };
    const goToStep2 = () => {
        if (step !== CheckoutStep.Payment) return;
        navigate("/checkout/step2");
    };

    return <div className="checkout-steps">
        <div className="lines">
            <div className={"line line-left " + (step>CheckoutStep.Cart?"active":"")}/>
            <div className={"line line-right " + (step===CheckoutStep.Payment?"active":"")}/>
        </div>
        <div className="steps">
            <div className={"step step-1 active"}
                 onClick={goToStep1}
            >
                <div className="icon">
                    <CheckoutIconStep1Active />
                </div>
                <div className={"label " + (step===CheckoutStep.Cart?"active":"")}>
                    {_("Warenkorb")}
                </div>
            </div>
            <div className={"step step-2 " + (step>CheckoutStep.Cart?"active":"") + " " + (isDelivery?"point":"")}
                 onClick={goToStep2}
            >
                <div className="icon">
                    {isDelivery?
                        step>CheckoutStep.Cart ? 
                            <CheckoutIconStep2PointActive />
                        : 
                            <CheckoutIconStep2Point />
                    : 
                        step>CheckoutStep.Cart ? 
                            <CheckoutIconStep2Active />
                        : 
                            <CheckoutIconStep2 />
                    }
                </div>
                <div className={"label " + (step===CheckoutStep.Form?"active":"")}>
                    {step2Label}
                </div>
            </div>
            <div className={"step step-3 " + (step===CheckoutStep.Payment?"active":"")}>
                <div className="icon">
                    {step===CheckoutStep.Payment?
                        <CheckoutIconStep3Active />
                        :
                        <CheckoutIconStep3 />
                    }
                </div>
                <div className={"label " + (step===CheckoutStep.Payment?"active":"")}>
                    {_("Bezahlen")}
                </div>
            </div>
        </div>
    </div>;
};
