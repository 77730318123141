import {DownPopup} from "../../../common/DownPopup";
import React, {useCallback, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    cartSelector,
    checkoutSlice,
    geoLocationObtainedSelector,
    setOrderGeoLocation
} from "../../../service/checkout.slice";
import {getQueryParams, isGeoLocationDeliverySelected, useLg, useLoading} from "../../../service/utils";
import {getCurrentGeoLocation, isLocationInRadius, sleep} from "../../geo-location/geoLocationUtils";
import {OrderlyGeoLocationInput} from "../../../service/checkout.service";
import {selectedSectorSelector, themeSelector} from "../../../service/common.slice";
import "./GeoLocationSetter.sass"
import {IconGeoLocation, IconGeoDeniedLocation} from "../../../common/Icons";
import {useNavigate} from "react-router-dom";


type LocError = {
    header: string;
    message: string;
}
export const GeoLocationSetter = () => {
    const dispatch = useAppDispatch();
    const cart = useAppSelector(cartSelector);
    const isGeoLocationMode = cart&&isGeoLocationDeliverySelected(cart);
    const theme = useAppSelector(themeSelector);
    const _ = useLg();
    const sector = useAppSelector(selectedSectorSelector);
    const navigate = useNavigate();
    const gotoChangeMode = () => navigate("/checkout");
    const goToActivateGPSInstructions = () => navigate("/help/activate_gps");

    const obtained = useAppSelector(geoLocationObtainedSelector);

    const [error, setError] = useState<LocError|undefined>(undefined);
    const [loading, wl] = useLoading();

    const [permissionDeniedPopup, setPermissionDeniedPopup] = useState(false);

    const obtainGeoLocation = useCallback(async () => {
        const minAccuracy = theme?.minLocationAccuracyInMeters||10;
        const debugMode = getQueryParams()['debug'] === "1";
        const log = (message: string) => {
            console.log(message);
            if (!debugMode) return;
            alert(message);
        }

        setPermissionDeniedPopup(false);
        const setObtained = (value: boolean) => dispatch(checkoutSlice.actions.setGeoLocationObtained(value))
        setError(undefined);
        setObtained(false);
        let orderlyGeoPosition: OrderlyGeoLocationInput;
        let maxRetries = 3, retry = 0;
        try {
            while (retry < maxRetries) {
                log(`getCurrentPosition start, retry ${retry+1}`);
                const position = await wl.load(getCurrentGeoLocation());
                const {accuracy, longitude, latitude} = position.coords;
                orderlyGeoPosition = {
                    accuracy, longitude, latitude
                } as OrderlyGeoLocationInput;
                log(`getCurrentPosition (${retry+1}) result: ${JSON.stringify(orderlyGeoPosition)}`);
                if (orderlyGeoPosition.accuracy! > minAccuracy) {
                    retry++
                    await wl.load(sleep(1000));
                    if (retry === maxRetries) {
                        throw new Error(`too low accuracy ${orderlyGeoPosition.accuracy}, should be less than ${minAccuracy}`);
                    }
                } else {
                    break
                }
            }
        } catch (e) {
            console.error(e);
            log('Error: ' + (e as any).message);
            if ('code' in (e as any) && (e as GeolocationPositionError).code === 1) {
                // PERMISSION_DENIED
                
                setPermissionDeniedPopup(true);
                return;
            }
            setError({
                header: _("Wir konnten deinen Standort leider nicht genau ermitteln."),
                message: _("Bitte verwende eine andere Art wie du deine 1-2-Eat Lieferung erhalten möchtest.")
            });
            return;
        }
        setObtained(true);
        orderlyGeoPosition = orderlyGeoPosition!;
        if (!isLocationInRadius(orderlyGeoPosition, theme!)) {
            log("Error: location not in radius");
            setError({
                header: _("Du kannst von deinem standort leider nicht bestellen."),
                message: _("Eine GPS Bestellung ist nur im Umkreis von {{Kioskname}} möglich.", {
                    "{{Kioskname}}": sector!.title
                })
            });
            return;
        }
        try {
            log("setOrderGeoLocation start, params: "+JSON.stringify(orderlyGeoPosition));
            await wl.load(dispatch(setOrderGeoLocation(orderlyGeoPosition)));
            log("setOrderGeoLocation succeeded");
        } catch (e) {
            log("setOrderGeoLocation error: "+((e as any).message));
            setError({
                header: _("Wir konnten deinen Standort leider nicht genau ermitteln."),
                message: _("Bitte verwende eine andere Art wie du deine 1-2-Eat Lieferung erhalten möchtest.")
            });
            return;
        }
    }, [dispatch, wl, _, theme, sector]);

    useEffect(()=>{
        if (!isGeoLocationMode) return;
        obtainGeoLocation();
    }, [isGeoLocationMode, obtainGeoLocation]);
    
    if (!isGeoLocationMode) return null;

    return <DownPopup show={loading||!obtained||Boolean(error)||permissionDeniedPopup} zIndex={120} showCloseIcon={false} onClose={()=>{}}>
        {loading
            ? (
                <div className="geo-popup">
                    <div className="popup-icon icon icon-refresh spin" />
                    <div className="header">
                        {_("Deine Standort wird ermittelt.")}
                    </div>
                    <div className="message">
                        {_("Bitte habe einen Moment Geduld während wir deinen Standort ermitteln")}
                    </div>
                </div>
            )
            : permissionDeniedPopup
            ? (<div className="geo-popup">
                <div className="popup-icon">
                    <IconGeoDeniedLocation />
                </div>
                <div className="header">
                    {_("Standort Bestellungen sind nur mit GPS möglich.")}
                </div>
                <div className="message">
                    {_("Bitte habe einen Moment Geduld während wir deinen Standort ermitteln")}
                </div>
                <div className="buttons">
                    <button
                        className="btn btn-primary big-primary"
                        onClick={goToActivateGPSInstructions}
                    >
                        {_("Wie aktiviere ich GPS?")}
                    </button>
                    <button
                        className="btn btn-primary big-primary"
                        onClick={gotoChangeMode}
                    >
                        {_("Liefermethode ändern")}
                    </button>
                </div>
            </div>)
            : error
            ? (
                <div className={"geo-popup"}>
                    <div className="popup-icon">
                        <IconGeoLocation />
                    </div>
                    <div className="header">
                        {error.header}
                    </div>
                    <div className="message">
                        {error.message}
                    </div>
                    <div className="buttons">
                        <button
                            className="btn btn-primary big-primary outline"
                            onClick={obtainGeoLocation}
                        >
                            {_("Erneut versuchen")}
                        </button>
                        <button
                            className="btn btn-primary big-primary"
                            onClick={gotoChangeMode}
                        >
                            {_("Liefermethode ändern")}
                        </button>
                    </div>
                </div>
            )
            : !obtained
            ? (
                <div className={"geo-popup"}>
                    <div className="popup-icon">
                        <IconGeoLocation />
                    </div>
                    <div className="header">
                        {_("Wir konnten deinen Standort leider nicht genau ermitteln.")}
                    </div>
                    <div className="message">
                        {_("Bitte verwende eine andere Art wie du deine 1-2-Eat Lieferung erhalten möchtest.")}
                    </div>
                    <div className="buttons">
                        <button
                            className="btn btn-primary big-primary"
                            onClick={obtainGeoLocation}
                        >
                            {_("Standort erlauben")}
                        </button>
                        <button
                            className="btn btn-primary big-primary"
                            onClick={gotoChangeMode}
                        >
                            {_("Liefermethode ändern")}
                        </button>
                    </div>
                </div>
            )
            : null
        }
    </DownPopup>
}

export const NotMyLocationButton = () => {
    const _ = useLg();
    const dispatch = useAppDispatch();

    const action = () => dispatch(checkoutSlice.actions.setGeoLocationObtained(false));

    return <span onClick={action}>
        {_("Nicht mein Standort")}
    </span>
}
