import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState, useAppSelector} from "../store/store";
import {applyLocalizations, Language, Languages} from "../translations";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {
    initialize, OrderlyTheme,
    rememberSectorSelectionDate,
    selectDeliveryProvider,
    selectSector, SliderImage
} from "./common.service";
import {showError} from "./utils";
import {dispatchCartFragment} from "./checkout.slice";
import {PaymentProviderFragment} from "./__generated__/PaymentProviderFragment";
import {OrderlySectorFragment} from "./__generated__/OrderlySectorFragment";
import {DeliveryProviderFragment} from "./__generated__/DeliveryProviderFragment";
import {Product} from "./products.service";
import {OrderDeliveryFragment} from "./__generated__/OrderDeliveryFragment";
import {CreatedOrderFragment} from "./__generated__/CreatedOrderFragment";
import {getOrderlySectors, getOrders} from "./checkout.service";
import fallbackSlider from "./../assets/main/fallback_slider.jpg";



export const getStoredLanguage = (): Language => {
    let lang = localStorage.getItem("language") as Language;
    if (!Languages.includes(lang)) {
        lang = Language.De;
    }
    return lang;
};

export interface CommonSlice {
    isLeftMenuOpen: boolean;
    isLanguageSelectorOpen: boolean;
    isSearchOpen: boolean;
    searchFocusProduct?: Product;
    language: Language;
    isCommonInitialized: boolean;
    isOffline: boolean;
    address: string;
    slider?: SliderImage[],
    deliveryCarousel?: SliderImage[],
    pickupCarousel?: SliderImage[],
    theme?: OrderlyTheme;

    paymentProviders?: PaymentProviderFragment[];

    deliveryProviders?: DeliveryProviderFragment[];
    delivery?: OrderDeliveryFragment; // TODO move to checkout.slice cart

    sectors?: OrderlySectorFragment[];
    selectedSector?: OrderlySectorFragment;
    orders: CreatedOrderFragment[];
}

const initialState: CommonSlice = {
    isLeftMenuOpen: false,
    isLanguageSelectorOpen: false,
    isSearchOpen: false,
    language: getStoredLanguage(),
    isCommonInitialized: false,
    isOffline: false,
    address: "",
    slider: [],
    orders: []
};

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        toggleLeftMenu: state => {
            state.isLeftMenuOpen = !state.isLeftMenuOpen;
        },
        closeLanguageSelector: state => {
            state.isLanguageSelectorOpen = false;
        },
        openLanguageSelector: state => {
            state.isLanguageSelectorOpen = true;
        },
        _setLanguage: (state, action: PayloadAction<Language>) => {
            state.language = action.payload;
            localStorage.setItem("language", state.language);
        },
        openSearch: state => {
            state.isSearchOpen = true;
        },
        closeSearch: state => {
            state.isSearchOpen = false;
        },
        setSearchFocusProduct: (state, action: PayloadAction<Product|undefined>) => {
            state.searchFocusProduct = action.payload;
        },
        setCommonInitialized: state => {
            state.isCommonInitialized = true;
        },
        setCommonInfo: (state, action: PayloadAction<Partial<CommonSlice>>) => {
            const data = action.payload;
            for (let k in data) {
                // @ts-ignore
                state[k] = data[k]; // eslint-ignore-line
            }
        },
        setDelivery: (state, action: PayloadAction<OrderDeliveryFragment|undefined>) => {
            state.delivery = action.payload;
        },
        setSectors: (state, action: PayloadAction<OrderlySectorFragment[]>) => {
            state.sectors = action.payload;
        },
        setSelectedSector: (state, action: PayloadAction<OrderlySectorFragment>) => {
            state.selectedSector = action.payload;
        },
        setOrders: (state, action: PayloadAction<CreatedOrderFragment[]>) => {
            state.orders = action.payload;
        }
    }
});

export const languageSelector = (state: RootState): Language => state.common.language;
export const isLeftMenuOpenSelector = (state: RootState): boolean => state.common.isLeftMenuOpen;
export const isLanguageSelectorOpenSelector = (state: RootState): boolean => state.common.isLanguageSelectorOpen;

export const isSearchOpenSelector = (state: RootState): boolean => state.common.isSearchOpen;
export const searchFocusProductSelector = (state: RootState): Product|undefined => state.common.searchFocusProduct;

export const selectedSectorSelector = (state: RootState): OrderlySectorFragment|undefined => state.common.selectedSector;
export const sectorsSelector = (state: RootState): OrderlySectorFragment[]|undefined => state.common.sectors;

const emptyDeliveryProviders: DeliveryProviderFragment[] = [];
export const deliveryProvidersSelector = (state: RootState): DeliveryProviderFragment[] => state.common.selectedSector?.supportedDeliveryProviders || emptyDeliveryProviders;

export const selectedDeliveryProviderSelector = (state: RootState): DeliveryProviderFragment|undefined => state.common.delivery?.provider!;

export const isProviderDelivery = (deliveryProvider: DeliveryProviderFragment) => deliveryProvider._id==="runner";

export const stadiumAddressDeliverySelector = (state: RootState) => state.common.address;

export const useTimeToDeliverySeconds = () => {
    const sector = useAppSelector(selectedSectorSelector);
    if (!sector) return undefined;
    return sector.timeToDeliverySeconds;
}

const defaultSlider: SliderImage[] = [
    {
        imageSrc: fallbackSlider,
        title: "Bestelle bequem von deinem Sitzplatz aus!"
    }
];

export const sliderSelector = (state: RootState) => {
    const delivery = selectedDeliveryProviderSelector(state);
    let slider: SliderImage[]|undefined;
    if (!delivery) {
        slider = state.common.slider;
    } else {
        slider = isProviderDelivery(delivery)?
            state.common.deliveryCarousel:
            state.common.pickupCarousel;
    }
    if (slider && slider.length > 0) {
        return slider;
    } else {
        return defaultSlider;
    }
};
export const defaultSliderSelector = (state: RootState) => {
    return state.common.slider&&state.common.slider.length>0?state.common.slider:defaultSlider;
};

export const themeSelector = (state: RootState) => state.common.theme;

export const paymentProvidersSelector = (state: RootState) => state.common.paymentProviders;

export const isOfflineSelector = (state: RootState): boolean => state.common.isOffline;
export const isCommonInitializedSelector = (state: RootState): boolean => state.common.isCommonInitialized;

export const ordersSelector = (state: RootState): CreatedOrderFragment[] => {
    const orders = state.common.orders;
    const DAY = 1000 * 60 * 60 * 24;
    return orders.filter(order => new Date().getTime() - new Date(order.ordered).getTime() < DAY
        || (order.orderlyReservationDate && (new Date().getTime() - new Date(order.orderlyReservationDate).getTime()) < DAY)
    );
};

export const setLanguage = createAsyncThunk(
    "setLanguage",
    (language: Language, {dispatch}) => {
        dispatch(commonSlice.actions._setLanguage(language));
        dispatch(doInitialization());
    }
);

export const refreshSectors = createAsyncThunk(
    "refreshSectors",
    async (_: void, {dispatch}) => {
        const sectors = await getOrderlySectors();
        await dispatch(commonSlice.actions.setCommonInfo({sectors}));
    }
);

export const setSector = createAsyncThunk(
    "setSector",
    async (sector: OrderlySectorFragment,{dispatch}) => {
        try {
            const cart = await selectSector(sector);
            rememberSectorSelectionDate();

            await dispatch(dispatchCartFragment(cart));
            await dispatch(commonSlice.actions.setSelectedSector(sector));
        } catch (e: any) {
            console.error(e);
            showError(e.toString());
        }
    }
);

export const setDeliveryProvider = createAsyncThunk(
    "setDeliveryProvider",
    async (deliveryProvider: DeliveryProviderFragment, {dispatch, getState}) => {
        try {
            const selectedSector = selectedSectorSelector(getState() as RootState);
            const delivery = await selectDeliveryProvider(deliveryProvider, selectedSector!);
            dispatch(commonSlice.actions.setDelivery(delivery));
        } catch (e: any) {
            console.error(e);
            showError(e.toString());
        }
    }
);

export const ensureCorrectDelivery = createAsyncThunk(
    "ensureCorrectDelivery",
    async (_: void, {dispatch, getState}) => {
        // it's not needed anymore as cart.delivery.sector will be set automatically
        // const state = getState() as RootState;
        // const selectedSector = selectedSectorSelector(state);
        // const delivery = await setSectorInCartDelivery(selectedSector!, state.common.delivery!._id);
        // await dispatch(commonSlice.actions.setDelivery(delivery));
    }
);

export const doInitialization = createAsyncThunk(
    "initializeCommon",
    async (_: void, {dispatch}) => {
        const info = await initialize();
        applyLocalizations(info.common.theme?.localizations);
        dispatch(commonSlice.actions.setCommonInfo(info.common));
        if (info.cart)
            dispatch(dispatchCartFragment(info.cart));
        dispatch(commonSlice.actions.setCommonInitialized());
    }
);

export const refreshOrders = createAsyncThunk(
    "refreshOrders",
    async (_: void, {dispatch}) => {
        const orders = await getOrders();
        dispatch(commonSlice.actions.setOrders(orders));
        return orders;
    }
);


