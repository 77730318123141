import { useAppDispatch, useAppSelector } from "../../store/store";
import {
    deliveryProvidersSelector,
    isProviderDelivery,
    selectedDeliveryProviderSelector,
    selectedSectorSelector,
    setDeliveryProvider,
} from "../../service/common.slice";
import "./DeliveryModeSelector.sass";
import { DeliveryProviderFragment } from "../../service/__generated__/DeliveryProviderFragment";
import {
    isGeoLocationDeliveryEnabled,
    isGeoLocationDeliveryOnly,
    isTableServiceOnly,
    isGeoLocationDeliverySelected,
    isSectorHasTableService,
    useLg,
    useLoading,
} from "../../service/utils";
import { cartSelector, setOrderDeliveryMode } from "../../service/checkout.slice";
import { CheckInput } from "../../common/contols/CheckInput";
import useId from "@mui/utils/useId";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";


export const DeliveryModeSelector = () => {
    const [loading, wl] = useLoading();
    const _ = useLg();
    const dispatch = useAppDispatch();
    const selectedSector = useAppSelector(selectedSectorSelector)!;
    const selectedDeliveryProvider = useAppSelector(selectedDeliveryProviderSelector);
    const selectedDeliveryProviderId = selectedDeliveryProvider?._id;
    const cart = useAppSelector(cartSelector);
    const allDeliveryProviders = useAppSelector(deliveryProvidersSelector);
    const deliveryProviders = useMemo(()=>allDeliveryProviders.filter(p=>p.isActive), [allDeliveryProviders]);

    const setProviderAndMode = useCallback(async (provider: DeliveryProviderFragment, isTableservice: boolean, geoLocationEnabled: boolean) => {
        if (wl.loading) return;
        await wl.load(dispatch(setDeliveryProvider(provider)));
        await wl.load(dispatch(setOrderDeliveryMode({isTableservice, geoLocationEnabled})));
    }, [dispatch, wl]);

    const isTableserviceSelected = !!cart?.orderlyIsTableservice;
    const isGeoLocationSelected = isGeoLocationDeliverySelected(cart!);
    const isGeoLocationEnabled = isGeoLocationDeliveryEnabled(selectedSector!);
    const isGeoLocationOnly = isGeoLocationDeliveryOnly(selectedSector!);
    const tableServiceOnly = isTableServiceOnly(selectedSector!);

    const deliveryOptions = useMemo(()=>{
        const checkboxes: ReactNode[] = [];

        deliveryProviders.forEach(deliveryProvider => {
            const isProviderSelected = selectedDeliveryProviderId===deliveryProvider._id;
    
            if (isProviderDelivery(deliveryProvider)) {
                
                if (!isGeoLocationOnly) {
                    checkboxes.push(
                        <CheckboxRow
                            label={_("Bestellung an den Sitzplatz")}
                            checked={
                                isProviderSelected&&!isGeoLocationSelected
                            }
                            onChecked={()=>setProviderAndMode(deliveryProvider, false, false)}
                        />
                    );
                }

                if (isGeoLocationEnabled) {
                    checkboxes.push(
                        <CheckboxRow
                            label={_("Bestellung direkt zu dir (GPS)")}
                            checked={
                                isProviderSelected&&isGeoLocationSelected
                            }
                            onChecked={()=>setProviderAndMode(deliveryProvider, false, true)}
                        />
                    );
                }                
            } else {
                if(!tableServiceOnly){
                    checkboxes.push(
                        <CheckboxRow
                            label={_("Bestellung abholen")}
                            comment={`${_("(Abholung bei Sektor")} ${selectedSector?.title})`}
                            checked={
                                isProviderSelected&&!isTableserviceSelected
                            }
                            onChecked={()=>setProviderAndMode(deliveryProvider, false, false)}
                        />
                    )
                }

    
                if (isSectorHasTableService(selectedSector)) {
                    checkboxes.push(
                        <CheckboxRow
                            label={_("Bestellung Tischservice")}
                            comment={`${_("(Tischservice bei Sektor")} ${selectedSector?.title})`}
                            checked={
                                isProviderSelected&&isTableserviceSelected
                            }
                            onChecked={()=>setProviderAndMode(deliveryProvider, true, false)}
                        />
                    )
                }
            }
        })

        return checkboxes;
    }, [
        selectedDeliveryProviderId,
        deliveryProviders, 
        isTableserviceSelected, 
        isGeoLocationSelected,
        isGeoLocationEnabled,
        isGeoLocationOnly,
        selectedSector,
        tableServiceOnly, 
        setProviderAndMode,
        _
    ]);
    
    useEffect(()=>{
        if (deliveryOptions.length === 1) {
            const deliveryProvider = deliveryProviders.find(p=>p.isActive)!;

            setProviderAndMode(
                deliveryProvider, 
                isSectorHasTableService(selectedSector), 
                isGeoLocationOnly
            )
        }
    }, [selectedSector, isGeoLocationOnly, deliveryOptions, deliveryProviders, setProviderAndMode]);

    if (deliveryOptions.length === 0) {
        return <div className="">
            {_("Leider ist der Dienst derzeit nicht verfügbar")}
        </div>
    }

    return (
        <div className="checkout-contents">
            <div className="box cart">
                {deliveryOptions.length > 1 && <h2>{_("Bitte auswählen*")}</h2>}
                <div style={{ display: 'flex', flexDirection: 'column' }}
                    className={`with-loading-overlay ${loading?'loading':''}`}
                >
                    <div className="loading-overlay">
                        <div className="icon icon-refresh" />
                    </div>
                    <div className="form-check-container">
                        {deliveryOptions.map((o,i)=>
                            <React.Fragment key={i}>
                                {o}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};


const CheckboxRow = ({label, checked, onChecked, comment}: 
    {label: string, checked: boolean, onChecked: VoidFunction, comment?: string}
) => {
    const id = useId();
    return <label>
        <div className={"form-check primary"}>
            <CheckInput
                className="form-check-input"
                type="radio"
                name="delivery_option"
                value={id}
                id={id}
                onChange={onChecked}
                checked={checked}
                disabled={false}
            />
            <div style={{ marginLeft: '5px' }}>
                {label}
                {comment&&<div style={{ alignSelf: 'flex-end', fontSize: '10px' }}>
                    {comment}
                </div>}
            </div>
        </div>
        
    </label>
}
