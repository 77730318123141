import "./ProductOptions.sass";
import {DownPopup} from "../../common/DownPopup";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Product, ProductAvailability, ProductData} from "../../service/products.service";
import {useAppDispatch} from "../../store/store";
import {addProductToCart} from "../../service/checkout.slice";
import {displayAmount, useLg, withLoading} from "../../service/utils";
import {ImageBlock} from "../../common/ImageBlock";
import { IconMinusGrayOutline, IconPlus, IconPlusGreenOutline } from "../../common/Icons";
import { CheckInput } from "../../common/contols/CheckInput";


export interface ProductOptionsProps {
    product: Product;
    open: boolean;
    onClose: () => void;
}


export const ProductOptions = (props: ProductOptionsProps) => {
    const _ = useLg();
    const dispatch = useAppDispatch();
    const [selectedOptions, setSelectedOptions] = useState<ProductData[]>([]);
    const [qty, setQty] = useState<number>(1);
    const [price, setPrice] = useState<number>(0);
    const {product} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);

    const addOption = (option: ProductData) => {
        selectedOptions.push(option);
        setSelectedOptions([...selectedOptions]);
    };

    const removeOption = (option: ProductData) => {
        selectedOptions.splice(selectedOptions.findIndex(v=>v.id===option.id), 1);
        setSelectedOptions([...selectedOptions]);
    };

    const onOptionChange = (e: ChangeEvent) => {
        const input = e.currentTarget as HTMLInputElement,
            optionId = input.value as string,
            checked = input.checked;
        const option = product.orderlyOptions.find(p=>p.id===optionId)!;
        if (checked) {
            addOption(option);
        } else {
            removeOption(option);
        }
    };

    const close = () => {
        props.onClose();
    };

    const recalc = () => {
        const selectedOptionsPrice = selectedOptions
            .map(o=>o.price.amount)
            .reduce((acc, v) => acc+v, 0);
        const basePrice = product.price.amount + selectedOptionsPrice;
        setPrice(basePrice * qty);
    };

    const inc = () => {
        setQty(qty+1);
    };

    const dec = () => {
        setQty(Math.max(1, qty-1));
    };

    const add = async () => {
        if (loading) return;
        await wl.load(dispatch(addProductToCart(
            {
                productWithOptions: {product, options: selectedOptions},
                quantity: qty
            }
        )));
        props.onClose();
    };

    useEffect(()=>{
        recalc();
    }, [product.id, qty, selectedOptions]); // eslint-disable-line

    if (!props.open) return null;

    return <DownPopup show={true} onClose={close}>
        <div className={"product-options " + (loading?"loading":"")}>
            <div className="header">{product.title}</div>
            {product.subtitle&&<div className="description" dangerouslySetInnerHTML={{__html:product.subtitle}}/>}
            {product.image&&<ImageBlock src={product.image.src} squared={true}/>}
            {product.description&&<div className="description" dangerouslySetInnerHTML={{__html:product.description}}/>}
            <form>
                {product.orderlyOptions.map(option =>
                    <div className="form-check primary" key={option.id}>
                        <CheckInput
                            className="form-check-input"
                            type="checkbox"
                            name="product-options"
                            id={"product-option-"+option.id}
                            onChange={onOptionChange}
                            value={option.id}
                            checked={!!selectedOptions.find(o=>o.id===option.id)}
                            disabled={option.availability!==ProductAvailability.AVAILABLE}
                        />
                        <label className="form-check-label"
                               htmlFor={"product-option-"+option.id}
                        >
                            {option.title} ({displayAmount(option.price.amount)})
                            {option.availability!==ProductAvailability.AVAILABLE?(" ("+_("Option derzeit nicht verfügbar")+")"):""}
                        </label>
                    </div>
                )}
            </form>
            <div className="buttons">
                <div className="quantity">
                    <div className="icon"
                         onClick={dec}
                    >
                        <IconMinusGrayOutline />
                    </div>
                    <div className="value">{qty}</div>
                    <div className="icon"
                         onClick={inc}
                    >
                        <IconPlusGreenOutline />
                    </div>
                </div>
                <div className="total" onClick={add}>
                    <div className="value">
                        {displayAmount(price)}
                    </div>
                    <div className="add-button">
                        <div className="icon">
                            <IconPlus />
                        </div>
                        <div className="text">{_("Hinzufügen")}</div>
                    </div>
                </div>
            </div>
        </div>
    </DownPopup>;
};
