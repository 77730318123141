import { ApolloError, ApolloQueryResult, FetchResult, gql } from "@apollo/client";
import {ApiService} from "./service";
import {CartFragment} from "./__generated__/CartFragment";
import {OrderlySectorFragment} from "./__generated__/OrderlySectorFragment";
import {OrderDeliveryFragment} from "./__generated__/OrderDeliveryFragment";
import {DeliveryProviderFragment} from "./__generated__/DeliveryProviderFragment";
import {CommonSlice} from "./common.slice";
import {CreatedOrderFragment} from "./__generated__/CreatedOrderFragment";
import {PaymentProviderFragment} from "./__generated__/PaymentProviderFragment";
import {
    CartFragmentQL,
    CreatedOrderFragmentQL,
    OrderDeliveryFragmentQL,
    OrderlySectorFragmentQL,
    PaymentProviderFragmentQL
} from "./fragments";
import { setupHoursInSector } from "./checkout.service";
import { addLog, showError } from "./utils";


export interface InitializationData extends Partial<CommonSlice> {
    common: Partial<CommonSlice>;
    cart?: CartFragment;
}

export interface SliderImage {
    imageSrc: string;
    title: string;
}

export interface OrderlyTheme {
    title: string;
    info: string;
    logoSrc: string;
    teaserSrc: string;
    headerPrimaryColor: string,
    headerSecondaryColor: string,
    headerTertiaryColor: string,
    backgroundColor: string,
    primaryColor: string,
    secondaryColor: string,
    tertiaryColor: string,
    textColor1: string,
    textColor2: string,
    textColor3: string,
    tipPercentages?: number[],
    slider: SliderImage[],
    deliveryCarousel: SliderImage[],
    pickupCarousel: SliderImage[],
    address: string;
    telNumber: string;
    emailAddress: string;
    offline: string;
    localizations?: Record<string, Record<string, string>>;
    minLocationAccuracyInMeters?: number;
    geoFenceCircle?: {
        radiusInMeters: number;
        latitude: number;
        longitude: number;
    };
    
}


const INITIALIZATION_QUERY = gql`
query meWithShopInfo {
    me {
        orders {
            ...CreatedOrderFragment
        }
        orderlyActiveSector {
            ...OrderlySectorFragment        
        } 
        cart {
            supportedPaymentProviders {
                ...PaymentProviderFragment        
            }
            delivery {...OrderDeliveryFragment} 
        }
        user_cart: cart {
            ...CartFragment
        }
    }
    shopInfo {
        orderlyTheme
    }
    orderlySectors {
        ...OrderlySectorFragment
    }
}

${PaymentProviderFragmentQL}
${CartFragmentQL}
${OrderDeliveryFragmentQL}
${CreatedOrderFragmentQL}
${OrderlySectorFragmentQL}
`;

const LOGIN = gql`
mutation login {
    loginAsGuest {
        id
        token
        tokenExpires
        user {
            cart {
                supportedPaymentProviders {
                    ...PaymentProviderFragment        
                }
                delivery {...OrderDeliveryFragment}
            }
        }
    }
}
${PaymentProviderFragmentQL}
${OrderDeliveryFragmentQL}
`;

const LOGOUT = gql`
mutation logout {
        logout {
            success
        }
}
`;

export const doLogin = async (): Promise<FetchResult<any> | null> => {
    let loginResp = await ApiService.getClient()
            .mutate({mutation:LOGIN});
    let token = loginResp.data.loginAsGuest.token;
    addLog('do login finished, token = '+token);
    if (!token) return null;
    ApiService.updateToken(token);
    addLog('token saved, read token = ' + ApiService.getToken());
    return loginResp;
}

export const initialize = async (): Promise<InitializationData> => {
    let commonResp: ApolloQueryResult<any>;
    
    try {
        commonResp = await ApiService.getClient()
            .query({query:INITIALIZATION_QUERY});
    } catch (e: any) {
        showError("Die App kann nicht initialisiert werden. Bitte versuchen Sie es erneut. " + e.toString());
        throw new Error("could not initialize");
    }

    addLog(`commonResp initialized, data me exists: ${Boolean(commonResp.data.me)}`);

    let delivery: OrderDeliveryFragment;
    const orderlyTheme: OrderlyTheme = commonResp.data.shopInfo.orderlyTheme;

    let cart: CartFragment|undefined = undefined,
        selectedSector: OrderlySectorFragment|undefined = undefined;

    let orders: CreatedOrderFragment[] = [];
    let paymentProviders: PaymentProviderFragment[] = [];

    if (commonResp.data.me) {
        delivery = commonResp.data.me.cart.delivery;
        cart = commonResp.data.me.user_cart;
        selectedSector = setupHoursInSector(commonResp.data.me.orderlyActiveSector);
        orders = commonResp.data.me.orders;
        paymentProviders = commonResp.data.me.cart.supportedPaymentProviders;
        
        if (isSectorSelectionOutdated()) {
            selectedSector = undefined;
        }
    } else {
        ApiService.removeToken();
        let loginResp = await doLogin();
        addLog('loginResp = '+Boolean(loginResp));

        if (!loginResp) {
            // Forcefully logout, then try again
            await ApiService.getClient().mutate({ mutation:LOGOUT, awaitRefetchQueries: true });
            loginResp = await doLogin();   
            addLog('loginResp2 = '+Boolean(loginResp));
        }
        if (!loginResp) {
            showError("Bei der Authentifizierung ist ein Fehler aufgetreten. Kein Token verfügbar");
            
            addLog('could not authorize');
            throw new Error("could not authorize");
        }
        cart = loginResp.data.loginAsGuest.user.cart;
        delivery = loginResp.data.loginAsGuest.user.cart.delivery;
        paymentProviders = loginResp.data.loginAsGuest.user.cart.supportedPaymentProviders;
    }

    addLog('initialization finished');

    return {
        common: {
            isOffline: orderlyTheme.offline === "true",
            address: orderlyTheme.address,
            slider: orderlyTheme.slider,
            deliveryCarousel: orderlyTheme.deliveryCarousel,
            pickupCarousel: orderlyTheme.pickupCarousel,
            theme: orderlyTheme,
            paymentProviders,
            sectors: commonResp.data.orderlySectors,
            selectedSector,
            delivery,
            orders,
        },
        cart
    }
};


export const rememberSectorSelectionDate = () => {
    localStorage.setItem("sectorSelectedAt", new Date().toISOString());
}
const isSectorSelectionOutdated = () => {
    const sectorSelectedAt = new Date(localStorage.getItem("sectorSelectedAt") || "");
    if (isNaN(sectorSelectedAt.getTime())) return true;
    const startOfDay = getStartOfDay();
    return sectorSelectedAt.getTime() < startOfDay.getTime();
}
const getStartOfDay = () => {
    const d = new Date();
    d.setHours(0);
    d.setMinutes(0);
    return d;
}


const SELECT_SECTOR = gql`
mutation SelectSector($sectorId: ID!) {
    orderlySetActiveSector(sectorId: $sectorId){
        ...CartFragment
    }
}
${CartFragmentQL}
`;
export const selectSector = async (sector: OrderlySectorFragment): Promise<CartFragment> => {
    const resp = await ApiService.getClient()
        .mutate({
            mutation: SELECT_SECTOR,
            variables: {
                sectorId: sector._id
            }
        });
    return resp.data.orderlySetActiveSector;
};


// this is not used anymore, remove later
/*const UPDATE_ORDER_DELIVERY_PICKUP = gql`
mutation UpdateOrderDeliveryPickup($orderDeliveryId: ID!, $sectorId: ID!) {
    updateOrderDeliveryPickUp(orderDeliveryId: $orderDeliveryId, orderPickUpLocationId: $sectorId) {
        ...OrderDeliveryFragment
    }
}
${OrderDeliveryFragmentQL}
`;
export const setSectorInCartDelivery = async (sector: OrderlySectorFragment, orderDeliveryId: string): Promise<OrderDeliveryFragment> => {
    const resp = await ApiService.getClient()
        .mutate({
            mutation: UPDATE_ORDER_DELIVERY_PICKUP,
            variables: {
                orderDeliveryId: orderDeliveryId,
                sectorId: sector._id
            }
        });
    return resp.data.updateOrderDeliveryPickUp;
};*/


const SELECT_DELIVERY_PROVIDER = gql` 
mutation SelectDeliveryProvider($deliveryProviderId: ID!) {
    updateCart(deliveryProviderId: $deliveryProviderId) {
        delivery {...OrderDeliveryFragment}
    }
}
${OrderDeliveryFragmentQL}
`;
export const selectDeliveryProvider = async (deliveryProvider: DeliveryProviderFragment, selectedSector: OrderlySectorFragment): Promise<OrderDeliveryFragment> => {
    const resp = await ApiService.getClient()
        .mutate({
            mutation: SELECT_DELIVERY_PROVIDER,
            variables: {
                deliveryProviderId: deliveryProvider._id,
            }
        });
    return resp.data.updateCart.delivery;
};


export interface CheckoutCartResult {
    error?: string;
    success: boolean;
}

const CHECKOUT_CART = gql`
mutation CheckoutCart($orderId: ID!, $transactionId: String!) {
    checkoutCart(orderId: $orderId, paymentContext: {transactionId: $transactionId}) {
        _id 
        status
    }
}    
`;
export const checkoutCart = async (orderId: string, transactionId: string): Promise<CheckoutCartResult> => {
    try {
        await ApiService.getClient()
            .mutate({
                mutation: CHECKOUT_CART,
                variables: {
                    orderId,
                    transactionId
                }
            });
        return {
            success: true,
        }
    } catch (e: any) {
        const apolloError = e as ApolloError;
        let errorString = e.toString();
        const detailMessage = apolloError.graphQLErrors?.[0]?.extensions?.detailMessage;
        if (detailMessage) {
            errorString = detailMessage;
        }
        return {
            success: false,
            error: errorString
        }
    }
};

