import React from 'react';
import { useAppSelector } from '../store/store';
import { sectorsSelector, selectedSectorSelector } from '../service/common.slice';
import "./SectorSelector.sass";
import {useLg} from "../service/utils";

interface SectorSelectorProps {
    onContainerClick: () => void;
}

export const SectorSelector: React.FC<SectorSelectorProps> = ({ onContainerClick }) => {
    const sector = useAppSelector(selectedSectorSelector);
    const sectors = useAppSelector(sectorsSelector);
    const _ = useLg();

    if (sectors?.length === 0 || sectors?.length === 1) {
        return null;
    }

    return (
        <div className="sector-selector-container" onClick={onContainerClick}>
            <div className="sector-selector-contents">
                <div className="sector-selector-contents-inner">
                    <div className="sector-selector">
                        <span>{_("Sektor")} {sector?.title}  </span>
                        <div className="icon icon-arrow-down" />
                    </div>
                </div>
            </div>
        </div>
    );
}

// TODO TRANSLATION
