import { ApmBase, init as initApm } from '@elastic/apm-rum'

let apm;

if (process.env.REACT_APP_APM_SERVER) {
    apm = initApm({
        serviceName: '12eat-frontend',
        environment: process.env.NODE_ENV !== 'production' ? 'test' : 'prod',
        serverUrl: process.env.REACT_APP_APM_SERVER,
        serviceVersion: process.env.REACT_APP_VERSION || "latest",
        breakdownMetrics: true,
    })
}
export default apm as ApmBase;