import { DownPopup } from "../common/DownPopup";
import React, { ChangeEvent, useEffect, useState } from "react";
import {isSectorAvailable, useLg, withLoading} from "../service/utils";
import { useAppDispatch, useAppSelector } from "../store/store";
import { useSelector } from "react-redux";
import { sectorsSelector, selectedSectorSelector, setSector } from "../service/common.slice";
import { OrderlySectorFragment } from "../service/__generated__/OrderlySectorFragment";
import { CheckInput } from "../common/contols/CheckInput";
import "./SelectSectorPage.sass"


export const SectorSelectorDownPopup = (
    {
        onClose,
        showSelectSector,
        showCloseIcon = true // Default to true, if not provided
    }: {
        onClose: () => void,
        showSelectSector: boolean,
        showCloseIcon?: boolean  // New Prop
    }
) => {
    const _ = useLg();

    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);

    const dispatch = useAppDispatch();
    const sector = useSelector(selectedSectorSelector);
    const sectors = useAppSelector(sectorsSelector);

    const [selectedSector, setSelectedSector] = useState<OrderlySectorFragment | undefined>(undefined);

    const onSectorChange = (e: ChangeEvent) => {
        const selectedSectorId = (e.currentTarget as HTMLInputElement).value;
        setSelectedSector(sectors?.find(s => s._id === selectedSectorId));
    };

    const confirmSector = async () => {
        if (!selectedSector) return;
        await doConfirmSector(selectedSector);
    };

    const doConfirmSector = async (sector: OrderlySectorFragment) => {
        await wl.load(dispatch(setSector(sector)));
        closeSectorSelector();
    };

    const closeSectorSelector = () => {
        onClose();
    };

    useEffect(() => {
        if (sector) {
            setSelectedSector(sector);
        }
        if (!sectors || sectors.length === 0) return;
        if (sectors.length === 1) {
            setSelectedSector(sectors[0]);
            doConfirmSector(sectors[0]);
        }
    }, [sector, sectors]);  // eslint-disable-line



    return <DownPopup
        show={showSelectSector}
        onClose={closeSectorSelector}
        showCloseIcon={showCloseIcon}
    >
        <div className="headerPopup">{_("Wähle deinen Sektor aus um zu bestellen")}</div>
        <div className="sector-list-container">
            <form>
                {sectors?.map(sectorOpt =>
                    <div className="form-check primary" key={sectorOpt._id}>
                        <CheckInput
                            className="form-check-input"
                            type="radio"
                            name="sector-selector"
                            id={"sector_" + sectorOpt._id}
                            onChange={onSectorChange}
                            value={sectorOpt._id}
                            checked={selectedSector?._id === sectorOpt._id}
                        />
                        <label className="form-check-label"
                            htmlFor={"sector_" + sectorOpt._id}
                        >
                            {sectorOpt.title}&nbsp;
                            {!isSectorAvailable(sectorOpt) && _("(not available at the moment)")}
                        </label>
                    </div>
                )}
            </form>
        </div>
        <div className="sticky-button-container">
            <button
                type="button"
                className={`btn big-primary ${selectedSector ? 'selectedSectorButton' : ''}`}
                onClick={confirmSector}
                disabled={loading}
            >
                {!loading && _("Auswählen")}
                {loading && _("Loading...")}
            </button>
        </div>
        {/*todo fixed + close button fixed*/}
    </DownPopup>;

};

