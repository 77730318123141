import "./DownStick.sass";
import {ReactNode, useEffect, useState} from "react";


export const DownStick = (
    {
        children
    }:
    {
        children: ReactNode
    }
) => {
    const [showClass, setShowClass] = useState<string>("");

    useEffect(()=>{
        const to = setTimeout(()=>setShowClass("show"), 50);
        return () => clearTimeout(to);
    }, []);

    return <div className={"down-stick " + showClass}>
        {children}
    </div>
};
