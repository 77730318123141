import React from "react";
import "./ProductsVerticalList.sass";
import { Product } from "../../service/products.service";
import { ProductCard } from "./ProductCard";

export const ProductsVerticalList = (
  { categoryName, categoryImage, products }: {
    categoryName: string,
    categoryImage: string,
    products: Product[]
  } = {
    categoryImage: "", categoryName: "", products: []
  }
) => {
  const hasActiveProducts = products.some(product => product.isActive);

  if (!hasActiveProducts) {
    return null; // Return nothing if all products are inactive
  }

  return (
    <div className="product-vertical-category">
      <div className="category-header" style={{ backgroundImage: `url(${categoryImage})` }}>
        <div className={"overlay " + (!categoryImage ? "no-bg" : "")} />
        <div className="header">{categoryName}</div>
      </div>
      <div className="products-list">
        {products.map((product, i) => {
          if (!product.isActive) {
            return <div key={i}></div>; // Return an empty container for inactive products
          } else {
            return <ProductCard product={product} key={i} />;
          }
        })}
      </div>
    </div>
  );
};
