import "./OrderIsEmptyBox.sass";
import {LanguageTranslationFunc} from "../../translations";
import {useAppSelector} from "../../store/store";
import {languageSelector} from "../../service/common.slice";


export const OrderIsEmptyBox = (
    {label}: {label: LanguageTranslationFunc}
) => {
    const lg = useAppSelector(languageSelector);

    return <div className="order-is-empty">
        <div className="checkout-icon checkout-icon-empty-placeholder" />
        <div className="label">
            {label(lg)}
        </div>
    </div>
};
