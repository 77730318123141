import * as React from "react";
import {HelpPageHeader} from "./HelpPage";
import {__} from "../../translations";
import {useNavigate} from "react-router-dom";
import {FaqContent} from "./FaqPage";
import {getQueryParams, useLg} from "../../service/utils";
import {useEffect, useState} from "react";
import {getOrderById} from "../../service/checkout.service";
import {CreatedOrderFragment} from "../../service/__generated__/CreatedOrderFragment";


export const HelpRoot = () => {
    const navigate = useNavigate();
    const [order, setOrder] = useState<CreatedOrderFragment|undefined>(undefined);
    const gotoProblem = () => {
        let url = `/help/problem`;
        const orderId = getQueryParams()["order_id"];
        if (orderId) url += "?order_id="+orderId;
        navigate(url);
    };
    useEffect(()=>{
        (async ()=>{
            const orderId = getQueryParams()["order_id"];
            if (!orderId) return;

            setOrder(await getOrderById(orderId));
        })();
    }, []);

    const _ = useLg();

    return <>
        <HelpPageHeader
            header={__("Fragen zur Bestellung")}
            isRoot={true}
        />
        <FaqContent showCommon={true}
                    order={order}
        />
        <div className="problem-button">
            <div className="comment">{_("Gibt es ein Problem bei deiner Bestellung?")}</div>

            <button className="btn btn-primary big-primary outline"
                    onClick={gotoProblem}
            >
                {_("Problem melden")}
            </button>
        </div>
    </>;
};