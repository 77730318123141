import * as React from "react";
import {Link, useNavigate} from "react-router-dom";
import {PageHeader} from "../../common/PageHeader";
import {__} from "../../translations";
import {useAppSelector} from "../../store/store";
import {
    isProviderDelivery,
    sectorsSelector
} from "../../service/common.slice";
import {useState} from "react";
import {
    CreatedOrderFragment,
    CreatedOrderFragment_deliveryProvider_OrderDeliveryPickUp
} from "../../service/__generated__/CreatedOrderFragment";
import {useLg} from "../../service/utils";


interface FaqItem {
    header: string;
    content: string;
}


export const FaqPage = () => {
    const _ = useLg();

    const navigate = useNavigate();
    const back = () => {
        navigate("/");
    };

    return <>
        <PageHeader onBack={back} isBackAvailable={true} header={__("FAQ")}/>

        <FaqContent
            showCommon={true}
        />

        <div className="problem-button">
            <div className="comment">{_("Gibt es ein Problem bei deiner Bestellung?")}</div>

            <Link to={"/help/problem"}>
                <button className="btn btn-primary big-primary outline">
                    {_("Problem melden")}
                </button>
            </Link>
        </div>
    </>;
};


export const FaqContent = (
    {
        showCommon,
        order
    }: {
        showCommon: boolean,
        order?: CreatedOrderFragment
    }
) => {
    const _ = useLg();
    const sectors = useAppSelector(sectorsSelector);

    let showDelivery, showPickup;
    if (order) {
        const provider = (order.deliveryProvider as CreatedOrderFragment_deliveryProvider_OrderDeliveryPickUp).provider;
        showDelivery = provider?isProviderDelivery(provider):false;
        showPickup = !showDelivery;
    } else {
        showDelivery = !!(sectors?.filter(s=>
            s.supportedDeliveryProviders.filter(isProviderDelivery)
        ));
        showPickup = !!(sectors?.filter(s=>
            s.supportedDeliveryProviders.filter(p=>!isProviderDelivery(p))
        ));
    }

    const general: FaqItem[] = [
        {
            header: _("Wie kann ich bezahlen?"),
            content: _("Wie kann ich bezahlen?")
        },
        {
            header: _("Gibt es einen Mindestbestellwert?"),
            content: _("Gibt es einen Mindestbestellwert?")
        },
        {
            header: _("Wie kann ich den Betreiber kontaktieren?"),
            content: _("Wie kann ich den Betreiber kontaktieren?")
        },
    ];
    const delivery: FaqItem[] = [
        {
            header: _("Wie kommt die Bestellung zu mir?"),
            content: _("Wie kommt die Bestellung zu mir?")
        },
        {
            header: _("Wie schnell liefert 1-2-eat?"),
            content: _("Wie schnell liefert 1-2-eat?")
        },
        {
            header: _("Was passiert wenn ich abwesend bin?"),
            content: _("Was passiert wenn ich abwesend bin?")
        },
        {
            header: _("Wie hoch sind die Lieferkosten?"),
            content: _("Wie hoch sind die Lieferkosten?")
        },
    ];
    const pickup: FaqItem[] = [
        {
            header: _("Wann muss ich abholen?"),
            content: _("Wann muss ich abholen?")
        },
        {
            header: _("Kann ich meine Bestellung stornieren?"),
            content: _("Kann ich meine Bestellung stornieren?")
        },
    ];

    return <div className="help-content">
        {showCommon&&<FaqList items={general} header={_("Allgemein")}/>}
        {showDelivery&&<FaqList items={delivery} header={_("FAQ Lieferung")}/>}
        {showPickup&&<FaqList items={pickup} header={_("FAQ Abholung")}/>}
    </div>
};


export const FaqList = (
    {items, header}: {items: FaqItem[], header: string}
) => {
    const [active, setActive] = useState<number>(-1);

    return <>
        <h3>{header}</h3>
        <div className="faq-accordion">
            {items.map((item, i) => <div className={"item " + (i===active?"active":"")} key={i}>
                <div className="item-header" onClick={()=>setActive((s)=>s!==i?i:-1)}>
                    <div className="item-header-text">{item.header}</div>
                    {i!==active&&<div className="icon icon-arrow-down-black"/>}
                    {i===active&&<div className="icon icon-arrow-up-blue"/>}
                </div>
                <div className="item-content">
                    {item.content}
                </div>
            </div>)}
        </div>
    </>;
};
