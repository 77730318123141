import {OrderItemFragment} from "../../service/__generated__/OrderItemFragment";
import {default as React, useState} from "react";
import {useAppDispatch} from "../../store/store";
import {displayAmount, isOrderItemAvailable, useLg, withLoading} from "../../service/utils";
import {getCartItemPrice, removeProductFromCart, setOrderItemQty} from "../../service/checkout.slice";
import {SimpleProduct} from "../../service/products.service";
import { IconMinusGrayOutline, IconPlusGreenOutline } from "../../common/Icons";


export const OrderItem = ({orderItem, readOnly}: {orderItem: OrderItemFragment, readOnly: boolean}) => {
    const _ = useLg();
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const wl = withLoading(setLoading);

    const dec = async () => {
        if (loading) return;
        if (orderItem.quantity === 1) {
            wl.load(dispatch(removeProductFromCart(orderItem)));
        } else {
            wl.load(dispatch(setOrderItemQty({orderItem, quantity: orderItem.quantity-1})));
        }
    };
    const inc = async () => {
        if (loading) return;
        wl.load(dispatch(setOrderItemQty({orderItem, quantity: orderItem.quantity+1})));
    };
    const remove = async () => {
        if (loading) return;
        wl.load(dispatch(removeProductFromCart(orderItem)));
    };

    const isAvailable = isOrderItemAvailable(orderItem) || readOnly;

    return <div className={"item " + (loading?"loading":"") + " " + (!isAvailable?"not-available":"")}>
        {readOnly&&<div className="qty-col">
            {orderItem.quantity}x
        </div>}
        <div className="name-col">
            <div className="name">
                {orderItem.product.texts?.title}
            </div>
            <div className="extra">
                {orderItem.orderlyOptions?.map(option=>{
                    return `${option.texts?.title} (+${displayAmount((option as SimpleProduct).simulatedPrice!.amount * orderItem.quantity)})`
                }).join(", ")}
            </div>
            {!isAvailable&&<div className="product-error">{_("Produkt nicht verfügbar")}</div>}
        </div>
        {!readOnly&&<div className="price-col">
            {!isAvailable&&<div className="remove-button" onClick={remove}>
                {_("Entfernen")}
            </div>}
            {isAvailable&&<>
                <div className="price">
                    {(orderItem.product as SimpleProduct)
                        .simulatedPrice!.currency}&nbsp;
                    {displayAmount(getCartItemPrice(orderItem))}
                </div>
                <div className="qty">
                    <div className="icon"
                         onClick={dec}
                    >
                        <IconMinusGrayOutline />
                    </div>
                    <div className="value">
                        {orderItem.quantity}
                    </div>
                    <div className="icon"
                         onClick={inc}
                    >
                        <IconPlusGreenOutline />
                    </div>
                </div>
            </>}
        </div>}
    </div>;
};
