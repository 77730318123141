import { configureStore } from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {commonSlice} from "../service/common.slice";
import {productsSlice} from "../service/products.slice";
import {checkoutSlice} from "../service/checkout.slice";

export const store = configureStore({
    reducer: {
        common: commonSlice.reducer,
        products: productsSlice.reducer,
        checkout: checkoutSlice.reducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
