import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../common/PageHeader";
import { __ } from "../../translations";
import * as React from "react";


export const PayoTerms = () => {
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    return <>
        <PageHeader onBack={back} isBackAvailable={true} header={__("Payyo terms")} />
        <div className="help-content">
            <h2>Allgemeine Geschäftsbedingungen</h2>
            <p>zwischen Anbietern (nachfolgend "ANBIETER") und TrekkSoft AG, Hauptstrasse 15, 3800 Matten bei Interlaken, Schweiz (nachfolgend "TREKKSOFT") als Handelsvertreter.</p>
            <p>Der ANBIETER betraut TREKKSOFT mit der Vermittlung und dem Abschluss von Verträgen z.B. in den Bereichen touristische Touren, Aktivitäten, Beherbergung und Erlebnisse auf der ganzen Welt (nachfolgend "SERVICE") mit Endkunden (nachfolgend "KUNDEN"). Das Angebot des ANBIETERS umfasst z.B. geführte Trekking-Touren, Rundgänge, Sightseeing-Touren mit dem Bus, Kanalschifffahrten, Tickets für Sehenswürdigkeiten, Beherbergungsmöglichkeiten, Hotellerie und/oder andere Leistungen. Zum Zwecke des Vertriebs, des Vertragsschlusses und der Abwicklung betreibt TREKKSOFT Website(s), Apps, Tools, Plattformen und/oder sonstige Einrichtungen (nachfolgend "PLATTFORM"), auf denen bzw. über die TREKKSOFT die angebotenen SERVICES den KUNDEN zur Buchung bei dem ANBIETER zur Verfügung stellt. Neben KUNDEN können auch von TREKKSOFT beauftragte gewerbliche Untervermittler zur Buchung von SERVICES bei ANBIETERN auf die PLATTFORM zugreifen.</p>
            <p>TREKKSOFT stellt die PLATTFORM den ANBIETERN zur Verfügung, um es TREKKSOFT zu ermöglichen, den KUNDEN den SERVICE der ANBIETER anzubieten und auf den Abschluss von Verträgen zwischen den KUNDEN und dem ANBIETER hinzuwirken. Dabei beauftragt der ANBIETER TREKKSOFT als Handelsvertreter und ermächtigt TREKKSOFT, selbstständig die Anbahnung und den Vertragsschluss in Bezug auf den vermittelten SERVICE im Namen und im Auftrag des ANBIETERS als dessen Vertreter mit dem KUNDEN über die PLATTFORM abzuwickeln. Gleichzeitig gestattet der ANBIETER die Beauftragung von Untervermittlern durch TREKKSOFT. TREKKSOFT wird zu keiner Zeit Vertragspartner bezüglich des jeweils vermittelten SERVICES, den der ANBIETER gegenüber dem KUNDEN erbringt. TREKKSOFT nimmt diesen Auftrag zu diesen VERTRAGSBEDINGUNGEN an.</p>
            <p>Dies vorausgeschickt, vereinbaren die Parteien das Folgende:</p>
            <h3>1. Anwendungsbereich dieser Allgemeinen Geschäftsbedingungen für ANBIETER</h3>
            <p>Diese Allgemeinen Geschäftsbedingungen gelten für alle ANBIETER (nachfolgend "VERTRAGSBEDINGUNGEN" oder "VERTRAG"), die den KUNDEN ihren SERVICE über die PLATTFORM anbieten, und regeln lediglich das Verhältnis zwischen dem ANBIETER und TREKKSOFT. Das Verhältnis zwischen den KUNDEN und dem ANBIETER ist nicht Gegenstand dieser VERTRAGSBEDINGUNGEN, sondern allein Gegenstand der Verträge und der individuellen Allgemeinen Geschäftsbedingungen des jeweiligen ANBIETERS mit dem jeweiligen KUNDEN. Zwar ist der ANBIETER für die rechtsgültige Einbeziehung sowie den Inhalt seiner individuellen Allgemeinen Geschäftsbedingungen in den Vertrag mit dem KUNDEN verantwortlich, jedoch wird TREKKSOFT sicherstellen, dass die Allgemeinen Geschäftsbedingungen des jeweiligen Anbieters ordnungsgemäß in dem Buchungsprozess mit dem jeweiligen KUNDEN abgebildet werden und dem KUNDEN den Abschluss der Buchung nicht ermöglichen, ohne zuvor den Allgemeinen Geschäftsbedingungen des ANBIETERS zuzustimmen. Das Verhältnis zwischen TREKKSOFT und den KUNDEN ist Gegenstand der allgemeinen Geschäftsbedingungen der TREKKSOFT mit dem KUNDEN. Das Verhältnis zwischen TREKKSOFT und Untervermittlern, sofern solche beteiligt sind, ist Gegenstand gesonderter vertraglicher Vereinbarungen.</p>
            <h3>2. Registrierung des ANBIETERS</h3>
            <p>2.1. Nach der Registrierung auf der PLATTFORM ist der ANBIETER berechtigt, seinen SERVICE über die PLATTFORM anzubieten. Im Zuge der Registrierung auf der PLATTFORM nimmt der ANBIETER die VERTRAGSBEDINGUNGEN an. Indem der ANBIETER auf die Schaltfläche "Ich stimme den Vertragsbedingungen zu" klickt, bestätigt er, dass er die VERTRAGSBEDINGUNGEN gelesen und verstanden hat und verpflichtet sich, sich an die VERTRAGSBEDINGUNGEN zu halten, und erklärt und garantiert, dass die Person, die diesen Vertrag auf Seiten des ANBIETERS abschließt, hierzu befugt und ordnungsgemäß bevollmächtigt ist. Dem ANBIETER wird nach erfolgreicher Registrierung ein ANBIETER-Account erstellt.</p>
            <p>2.2. TREKKSOFT ist nicht dafür verantwortlich, die Identität, Echtheit oder Berechtigungsnachweise der Person zu überprüfen, die diesen Vertrag für den ANBIETER mit TREKKSOFT abschließt.</p>
            <p>2.3. Der ANBIETER ist dazu verpflichtet, seinen SERVICE als Unternehmer gemäß den für ihn einschlägigen Steuervorschriften und den Verfahren und Anforderungen der Steuerbehörden anzubieten und zu erbringen. Der ANBIETER ist verpflichtet, im Rahmen der Führung seiner Geschäfte bzw. seiner selbständigen beruflichen Tätigkeit die maßgeblichen Verträge mit den KUNDEN abzuschließen.</p>
            <h3>3. Das Angebot von TREKKSOFT, Fraud Prevention, Ablehnungsrecht und Entscheidungsspielraum</h3>
            <p>3.1. Die PLATTFORM ermöglicht es den ANBIETERN, ihre SERVICES in das Angebotsportfolio von TREKKSOFT einzustellen. TREKKSOFT wird die SERVICES des ANBIETERS KUNDEN gegenüber anbieten und Verträge zwischen ANBIETER und individuellen KUNDEN im Namen des ANBIETERS abschließen.</p>
            <p>3.2. TREKKSOFT prüft vor jedem Vertragsschluss durch ein Fraud Prevention Tool die Mittelherkunft in Bezug auf jeden individuellen KUNDEN. Dabei sind die von TREKKSOFT jeweils gültigen Ablaufprozesse, deren aktuellste Fassung TREKKSOFT dem ANBIETER jeweils rechtzeitig vor deren Anwendung übermittelt, für die Prüfung der Mittelherkunft maßgeblich. Der ANBIETER ist dazu verpflichtet, die von TREKKSOFT verlangten Dokumente (vor Vertragsschluss und während der Vertragslaufzeit) im Rahmen der Anti-Geldwäscherei- und Terrorismusfinanzierungs-bestimmungen gemäss europäischem Recht an TREKKSOFT zu übermitteln. Kommt TREKKSOFT zu dem Ergebnis, dass ein KUNDE nicht vertrauenswürdig ist oder die Herkunft seiner Mittel nicht festgestellt werden kann, behält sich TREKKSOFT vor, den Abschluss des Vertrags zwischen dem KUNDEN und dem ANBIETER nicht einzugehen.</p>
            <p>3.3. Beabsichtigt TREKKSOFT den Vertrag für den ANBIETER mit einem KUNDEN abzuschließen, wird TREKKSOFT dem KUNDEN eine Auftragsbestätigung per E-Mail übermitteln. Der Vertrag gilt mit der Versendung der Auftragsbestätigung als für den ANBIETER gegenüber dem jeweiligen KUNDEN von TREKKSOFT als angenommen.</p>
            <p>3.4. Die Abrechnung des Preises der SERVICES erfolgt durch TREKKSOFT gegenüber dem KUNDEN. Der KUNDE leistet den Preis mit schuldbefreiender Wirkung an die TREKKSOFT. Eine direkte Zahlung an den jeweiligen ANBIETER erfolgt nicht. Der KUNDE wird mit Zahlung des gesamten Preises an TREKKSOFT von seiner Pflicht zur Leistung des Preises frei.</p>
            <p>3.5. TREKKSOFT wird hiermit ausdrücklich ermächtigt, im Rahmen von strittigen Kreditkartenrückbuchungen (Chargebacks) die von KUNDEN übermittelten Vermögenswerte zurück zu halten, bis Klarheit über die strittige rechtliche Situation besteht. TREKKSOFT ist nicht verantwortlich und in keiner Weise haftbar für Verluste der Vermögenswerte während der Dauer der Zurückhaltung der Vermögenswerte.</p>
            <h3>4. Pflichten des ANBIETERS</h3>
            <p>4.1. Der ANBIETER sichert zu und garantiert, dass alle Informationen im Zusammenhang mit seinem Angebot jederzeit richtig, wahr und nicht irreführend sind. Der ANBIETER ist verpflichtet, die auf die PLATTFORM hochgeladenen Informationen zeitnah zu aktualisieren, sodass die Richtigkeit der bereitgestellten Informationen sichergestellt ist. Der ANBIETER kann für alle Forderungen der KUNDEN haftbar gemacht werden, die auf behaupteten Unrichtigkeiten der hochgeladenen, auf der PLATTFORM angezeigten Informationen (wie z.B. Informationen zu dem SERVICE und Preisen) beruhen. Der ANBIETER ist für die Richtigkeit der Informationen in der Sprache verantwortlich, in der sie über seinen ANBIETER-Account übermittelt wurden. Sobald dem ANBIETER eine Unrichtigkeit in den hochgeladenen Informationen in einer anderen Sprache bekannt wird oder hätte bekannt werden müssen, ist er verpflichtet, TREKKSOFT unverzüglich darüber in Kenntnis zu setzen.</p>
            <p>4.2. Der ANBIETER sichert zu und garantiert, dass der SERVICE ausschließlich im Einklang mit allen anwendbaren Gesetzen und Vorschriften erbracht wird, und zwar insbesondere im Einklang mit den Gesetzen des Landes, in dem der ANBIETER seinen Geschäftssitz hat, sowie im Einklang mit allen anwendbaren Gesetzen des Landes, in dem der SERVICE erbracht wird, und dass die Erbringung der SERVICES nicht gegen Rechte Dritter verstößt. Insbesondere garantiert der ANBIETER, dass er (a) sämtliche KUNDEN- bzw. Verbraucherschutz- und sonstige Schutz-, Informations- und Beratungspflichten und -vorschriften einhält und (b) über alle verwaltungsrechtlichen und sonstigen Genehmigungen und Lizenzen sowie über Versicherungen und ähnliches verfügt, die in dem Rechtssystem des betreffenden Landes, in dem die vertraglichen Verpflichtungen erfüllt werden, benötigt werden, und dass er seinen im Rahmen der Erfüllung des SERVICES eingesetzten Auftragnehmern Weisungen erteilt, die diesen Bedingungen entsprechen. Der ANBIETER ist verpflichtet, auf Nachfrage von TREKKSOFT entsprechende Nachweise und Unterlagen, wie z. B. Kopien von Genehmigungen und Lizenzen, vorzulegen. Für den Fall, dass der vom ANBIETER erbrachte SERVICE die genannten Vorgaben nicht einhält oder wenn ein entsprechender Verdacht besteht, ist der ANBIETER verpflichtet, unverzüglich auf eigene Kosten mit den Verwaltungsbehörden oder Wettbewerbs-/Verbraucherschutzverbänden zusammenzuarbeiten.</p>
            <p>4.3. Der ANBIETER sichert zu und garantiert, dass er den KUNDEN alle für die Ausführung der SERVICES erforderlichen Informationen (beispielsweise den Treffpunkt und die Uhrzeit, die erforderliche Kleidung und Ausrüstung) und Sicherheits- bzw. Gefahrenhinweise rechtzeitig im Voraus mitteilt. Der ANBIETER ist dafür verantwortlich, dass seine individuellen Allgemeinen Geschäftsbedingungen vom KUNDEN akzeptiert werden und somit rechtsgültig in den Vertrag mit dem ANBIETER (Leistungsvertrag) aufgenommen werden.</p>
            <p>4.4. Der ANBIETER sichert zu und garantiert, dass alle von ihm eingesetzten Mitarbeiter, Hilfspersonen und Beauftragten (z.B. Fremdenführer) die rechtlichen Zulassungen besitzen und alle rechtlichen Anforderungen, einschließlich beruflicher Qualifikationen und Zulassungen, erfüllen. Der ANBIETER ist alleine und in vollem Umfang für das Verhalten der von ihm eingesetzten Mitarbeiter, Hilfspersonen und Beauftragten gegenüber den KUNDEN oder anderen Personen, welche die SERVICES des ANBIETERS berechtigterweise tatsächlich in Anspruch nehmen, verantwortlich.</p>
            <p>4.5. Der ANBIETER ist nur berechtigt, seinen eigenen SERVICE über die PLATTFORM anzubieten. Der ANBIETER ist nicht berechtigt, einen SERVICE als Vermittler oder Makler anzubieten. Sofern der ANBIETER dennoch als Vermittler oder Makler auftritt, wird er wie eine Vertragspartei behandelt und kann für etwaige Schäden haftbar gemacht werden. Es ist ausdrücklich untersagt, einen vorgetäuschten SERVICE auf der PLATTFORM einzustellen.</p>
            <p>4.6. Der ANBIETER ist verpflichtet, eine umfassende allgemeine Haftpflichtversicherung für alle über die PLATTFORM angebotenen SERVICES abzuschließen. Der ANBIETER muss zwingend eine Versicherungssumme vereinbaren, die für die festgelegten Buchungsvolumina und -risiken angemessen ist. Darüber hinaus muss die allgemeine Haftpflichtversicherung potenzielle Regressansprüche von TREKKSOFT abdecken. Regressansprüche von TREKKSOFT oder seiner Versicherer sind nicht ausschließbar oder beschränkbar. Die genauen Angaben bezüglich der allgemeinen Haftpflichtversicherung müssen elektronisch dokumentiert werden und auf den ANBIETER-Account hochgeladen werden. Hierzu zählen die folgenden Angaben: Der Name und die Anschrift der Versicherungsgesellschaft, bei der der ANBIETER die Haftpflichtversicherung abgeschlossen hat, die Nummer der Versicherungspolice und die Versicherungssumme. TREKKSOFT behält sich das Recht vor, die Versicherungspolice auf Anforderung zu überprüfen und einen Nachweis für die Bezahlung der Versicherungsprämie zu verlangen. Der ANBIETER ist verpflichtet, die betreffenden hochgeladenen Informationen unverzüglich zu aktualisieren, damit die Richtigkeit dieser Informationen jederzeit gewährleistet ist.</p>
            <p>4.7. Die vom ANBIETER auf seinem ANBIETER-Account für seine Angebote und SERVICES angegebenen Preise sind einschließlich der Mehrwertsteuer und sonstiger geltender nationaler, gesetzlicher und/oder regionaler Bundes-, Gemeinde und/oder lokaler Steuern und Abgaben anzugeben, wobei diese separat auszuweisen sind.</p>
            <h3>5. Buchungsangebote des ANBIETERS</h3>
            <p>5.1. Der ANBIETER gibt die Angaben zum SERVICE in den ANBIETER-Account ein. Er ist dafür verantwortlich sicherzustellen, dass die gemachten Angaben stets richtig und auf dem aktuellen Stand sind. Überarbeitete, stornierte oder nicht mehr gültige SERVICES sind unverzüglich zu löschen.</p>
            <p>5.2. Aus der Servicebeschreibung muss klar ersichtlich sein, dass der ANBIETER den SERVICE in seinem eigenen Namen und in seiner eigenen Verantwortung anbietet. Der SERVICE muss unter dem allgemein verwendeten und/oder bekannten Firmennamen des ANBIETERS angeboten werden. Es darf weder mittelbar noch unmittelbar der Eindruck entstehen, dass TREKKSOFT ANBIETER des SERVICES ist oder, dass TREKKSOFT die Qualität des angebotenen SERVICES geprüft hat oder ihre Qualität anderweitig hervorhebt.</p>
            <h3>6. Verantwortung für den Inhalt</h3>
            <p>6.1. Der ANBIETER ist verpflichtet, seinen Geschäftszweck in seinem ANBIETER-Account zu hinterlegen. Beabsichtigt der ANBIETER seinen Geschäftszweck zu ändern, muss TREKKSOFT vorab informiert werden. Der neue Geschäftszweck muss von TREKKSOFT bewilligt werden. Wir der Geschäftszweck nicht bewilligt, kann TREKKSOFT den ANBIETER-Account sperren.</p>
            <p>6.2. Der ANBIETER ist für den Inhalt verantwortlich, der über seinen ANBIETER-Account übermittelt wird, wie z. B. Beschreibungen des SERVICES, Forumseinträge usw. TREKKSOFT behandelt diesen Inhalt des ANBIETERS wie externen Inhalt. Ferner behandelt TREKKSOFT Kundeninhalte wie etwa Forumseinträge, Berichte, usw. wie externen Inhalt und schließt die Haftung hierfür vollumfänglich aus.</p>
            <p>6.3. Wird TREKKSOFT über Inhalt informiert oder entdeckt Inhalt, der gegen diese Bedingungen oder sonstige rechtliche Bestimmungen verstößt, ist TREKKSOFT berechtigt, diesen Inhalt ganz oder teilweise zu löschen, sofern der Verstoß gegen die betreffenden Vorschriften nicht innerhalb von zwei Arbeitstagen nach Benachrichtigung durch TREKKSOFT hierüber vom ANBIETER behoben worden ist. Falls der missbräuchliche Inhalt von einem ANBIETER stammt, ist TREKKSOFT berechtigt, den Zugang zu seinem ANBIETER-Account zu sperren oder seinen ANBIETER-Account zu löschen. TREKKSOFT wird stets die jeweiligen beiderseitigen Interessen an dem betreffenden Fall berücksichtigen, bevor TREKKSOFT einen ANBIETER-Account ganz oder vorübergehend sperrt.</p>
            <p>6.4. Der ANBIETER ist nur berechtigt, Inhalte und Daten hochzuladen, zu denen er die entsprechenden (Urheber-)Rechte besitzt. Diese Informationen dürfen nicht gegen die Datenschutzrechte von KUNDEN, Dritten, usw. verstoßen und müssen den geltenden Wettbewerbsgesetzen und sonstigen rechtlichen Anforderungen entsprechen. Es dürfen keine Inhalte oder Daten hochgeladen werden, welche gegen rechtliche oder regulatorische Vorschriften verstossen.</p>
            <h3>7. Stornierungen</h3>
            <p>7.1. Der ANBIETER erklärt sich damit einverstanden, dass die KUNDEN, sofern die Parteien nicht etwas anderes vereinbart haben, berechtigt sind, ihre Buchung bis zu 24 (vierundzwanzig) Stunden vor dem Datum, für das der jeweilige SERVICE gebucht wurde, kostenfrei zu stornieren. Der ANBIETER erhält hierfür keine Zahlung. Falls KUNDEN den gebuchten SERVICE weniger als 24 (vierundzwanzig) Stunden vor dem Datum, für das der SERVICE gebucht wurde, oder nicht innerhalb einer anderen, im Voraus von den Parteien vereinbarten Frist stornieren, oder wenn die Person, für die der SERVICE gebucht wurde, an dem Datum, für das der SERVICE gebucht wurde, nicht erscheint, sind die KUNDEN verpflichtet, den vollständigen Preis des SERVICES zu bezahlen, der sodann abzüglich der Provision an den ANBIETER überwiesen wird.</p>
            <p>7.2. Storniert der ANBIETER eine Buchung, ganz gleich aus welchem Grund, ist er verpflichtet, den KUNDEN in vollem Umfang schadlos zu halten, es sei denn, zwischen dem ANBIETER und dem KUNDEN wurde etwas anderes vereinbart.</p>
            <p>7.3. Verhindert höhere Gewalt am Ort der Erbringung des SERVICES den SERVICE, kann jede Vertragspartei die Geschäftsbeziehung aussetzen oder beenden. "Höhere Gewalt" in diesem Sinne, sind Umstände, die außerhalb der vertretbaren Kontrolle der Parteien liegen, einschließlich Naturkatastrophen, Krieg, Terrorismus, dauerhafter Stromunterbrechung und Streiks.</p>
            <h3>8. Kundenbeschwerden</h3>
            <p>Um Beschwerden oder Forderungen (bezüglich der durch den ANBIETER angebotenen, erbrachten oder zur Verfügung gestellten SERVICES) oder spezielle vom KUNDEN vorgebrachte Anfragen hat sich der ANBIETER zu kümmern, da TREKKSOFT nicht für solche Anfragen von KUNDEN verantwortlich ist und für Forderungen nicht einsteht. Entsprechende Kontaktinformationen stellt der ANBIETER auf der PLATTFORM zur Verfügung.</p>
            <h3>9. Zahlungen</h3>
            <p>9.1. Die Kreditkartenzahlungen der KUNDEN werden sofort (soweit nicht anders geregelt) eingezogen.</p>
            <p>9.2. TREKKSOFT wickelt die Zahlungen der KUNDEN ab und schreibt die eingegangenen Zahlungen direkt dem jeweiligen ANBIETER-Account gut. Der ANBIETER muss seine Bankkontodaten in seinem ANBIETER-Account eingeben und der ANBIETER muss mit dem Bankkontoinhaber identisch sein. TREKKSOFT kann nicht für die Zahlungen der KUNDEN zur Verantwortung gezogen werden und haftet nicht, wenn falsche oder unvollständige Kontodaten des ANBIETERS in den ANBIETER-Account eingegeben werden.</p>
            <p>9.3. TREKKSOFT ist nicht verpflichtet, rechtliche Schritte, ganz gleich welcher Art, gegen KUNDEN einzuleiten, die einen bestimmten SERVICE oder eine Stornierung nicht bezahlen oder eine Kreditkartenzahlung widerrufen. TREKKSOFT wird diese Forderungen ggf., an den ANBIETER abtreten.</p>
            <p>9.4. Falls ANBIETER Stripe nutzt: Zahlungsabwicklungsservices für den ANBIETER auf PLATTFORM werden von STRIPE bereitgestellt und stehen unter den Allgemeinen Geschäftsbedingungen von STRIPE (Stripe Connected Account Agreement, dem sogenannten Stipe Service Agreement Bei Zustimmung dieser AGBs und der Weiterführung des ANBIETER-Accounts auf PLATTFORM, stimmen Sie zu, dass Sie an das Stripe Service Agreement gebunden werden, welches ebenso von Stripe jederzeit angepasst werden kann. Damit die PLATTFORM die Zahlungsabwicklungservices durch STRIPE aktivieren kann, stimmen Sie zu, korrekte Informationen zu Ihnen und Ihrem Geschäft bereitzustellen, und Sie stimmen zu, dass die PLATTFORM diese Informationen sowie transaktionsbezogene Informationen zu Ihrer Nutzung der Zahlungsabwicklungsservices mit STRIPE teilen darf.</p>
            <p>9.5. Kauf auf Rechnung wird von SwissBilling SA für TREKKSOFT bereitgestellt. TREKKSOFT wickelt nur Transaktionen für Kauf auf Rechnung bis maximal CHF 5’000 ab. Wenn der ANBIETER Kauf auf Rechnung dem KUNDEN bereitstellt, stimmt der ANBIETER hiermit zu, dass bei Ankunft eine Unterschrift des KUNDEN, sowie eine Kopie eines gültigen Ausweisdokuments (Reisepass, Ausweiskarte oder Führerschein), notwendig ist.  Zusätzlich muss der ANBIETER eine gültige Telefonnummer vorliegen haben, über die der KUNDE erreicht werden kann. Wenn der ANBIETER diese Informationen und Dokumente im Falle einer Rechtsstreitigkeit oder Rückerstattung mit dem KUNDEN nicht bereitstellen kann, haftet TREKKSOFT nicht für den Verlust des Buchungsbetrages. TREKKSOFT haftet ebenso nicht für etwaige Gebühren, die mit der Rechtsstreitigkeit oder der Stornierung über SwissBilling SA verbunden sind.</p>
            <p>9.6. iDeal, SEPA Direct Debit, eps, Giropay und Sofort/Klarna: Zahlungen des ANBIETERS werden von  TREKKSOFT AG, Hauptstrasse 15, 3800 Matten, Schweiz, ausgeführt. TREKKSOFT kann als das ausführende Unternehmen auf der Abrechnung des ANBIETERS oder des KUNDEN erscheinen. Die Domain, auf welcher die Zahlungen abgewickelt werden, ist Eigentum von TREKKSOFT. Falls Sie Fragen zu Zahlungen und Anfechtungen (Chargebacks) haben, senden Sie bitte eine Mail an support@payyo.ch.</p>
            <h3>10. Abrechnung</h3>
            <p>10.1. Die Kreditkarten- und Bankgebühren für die Zahlungen der KUNDEN an TREKKSOFT, trägt TREKKSOFT.</p>
            <p>10.2. Die Kreditkarten- und Bankgebühren für die Überweisungen an den ANBIETER werden wie folgt aufgeteilt: TREKKSOFT trägt die Kosten seiner eigenen Bank/seines eigenen Kreditkartenunternehmens und der ANBIETER trägt die Kosten seiner Bank/seines Kreditkartenunternehmens.</p>
            <p>10.3. Der ANBIETER wird per E-Mail benachrichtigt, sobald die Zahlung des KUNDEN für den SERVICE des ANBIETERS bestätigt wurde.</p>
            <p>10.4. TREKKSOFT erstellt unter Berücksichtigung aller Buchungen, die während der vordefinierten Buchungsperiode über die PLATTFORM ausgeführt wurden, eine Rechnung über die Kommissionshöhe für den ANBIETER und verrechnet diesen mit den Zahlungen der KUNDEN. Die Auszahlung des Restbetrages des von den KUNDEN gezahlten Geldes, der ebenfalls als Gutschrift auf der Rechnung vermerkt ist, erfolgt per Überweisung auf das Bankkonto des ANBIETERS.</p>
            <p>10.5. Die Rechnung ist die Grundlage einer jeden Zahlung. Der ANBIETER ist berechtigt, innerhalb einer Frist von 30 Tagen einen Anspruch hinsichtlich einer (vermeintlichen) Ungenauigkeit in der Rechnung oder Gutschrift zu erheben. Wird kein Anspruch erhoben gelten sie als angenommen.</p>
            <p>10.6. Der ANBIETER wird beim Hochladen seines Angebots von TREKKSOFT gebeten, seine bevorzugte Währung auszuwählen. Es ist nur eine Auszahlungswährung möglich. TREKKSOFT wird das Angebot des ANBIETERS in dieser bevorzugten Währung anzeigen und kann sich nach eigenem Ermessen dazu entschließen, die Angebote des ANBIETERS in weiteren Währungen anzuzeigen und/oder kostenlos ein anderes Zahlungsmittel hinzuzufügen. Zahlungen an den ANBIETER werden in der bevorzugten Währung ausgeführt, die in dem hochgeladenen Angebot angegeben ist. Für Gebühren für Wechselkursumrechnungen wird auf Ziffer 11 und das Gebührenreglement verwiesen.</p>
            <p>10.7. Die Transaktionskosten können nach einem Entgelt vollständig von TREKKSOFT übernommen werden. Der von TREKKSOFT an den ANBIETER überwiesene Betrag entspricht der Summe der Nettopreise aller berücksichtigten gebuchten SERVICES. Einige Banken berechnen jedoch zusätzliche Gebühren für Zahlungseingänge aus dem Ausland. Diese Gebühren gehen allein zu Lasten des ANBIETERS. Aufgrund der Überweisungskosten kann TREKKSOFT entscheiden, keine Beträge unter USD 50,00 bzw. unter entsprechenden Beträgen in anderen Währungen zu überweisen. Falls der Betrag von USD 50,00 in einem bestimmten Monat nicht erreicht wird, ist TREKKSOFT berechtigt, die Zahlung aufzuschieben, bis dieser Betrag erreicht ist.</p>
            <p>10.8. Ist TREKKSOFT, ganz gleich aus welchem Grund, im Land des ANBIETERS im Hinblick auf dessen SERVICE steuer- oder kostenpflichtig, kann TREKKSOFT die Zusammenarbeit mit dem ANBIETER innerhalb von 3 (drei) Tagen beenden. Solche Steuern oder Kosten können von den Zahlungen abgezogen werden, die an den ANBIETER zu leisten sind oder in Rechnung gestellt werden.</p>
            <h3>11. Gebühren</h3>
            <p>11.1. Gebühren für Buchungstransaktionen zwischen KUNDEN und dem ANBIETER sind in einem separaten Gebührenreglement festgehalten, welches der ANBIETER getrennt erhalten hat. Der ANBIETER stimmt hiermit dem aktuell gültigen Gebührenreglement zu.</p>
            <p>11.2. Für Transaktionen die eine Währungsumrechnung erfordern, kann TREKKSOFT dem ANBIETER Wechselkursgebühren bis zu 5% des Transaktionsbetrages belasten.</p>
            <p>11.3. TREKKSOFT kann zur Deckung von Kosten für die Abwicklung von Kreditkartenrückbuchungen eine Kreditkartenrückbuchungsgebühr (Chargeback-Gebühr) vom ANBIETER erheben. Die Chargeback-Gebühren können dem Gebührenreglement auf www.payyo.ch entnommen werden.</p>
            <h3>12. Steuern</h3>
            <p>Der ANBIETER nimmt zustimmend zur Kenntnis, dass er allein für die Genauigkeit der Angaben zum Steuersatz, für die Ermittlung der anwendbaren Steuern und etwaige Änderungen der Steuersätze verantwortlich ist, die auf der PLATTFORM eingegeben werden. Der ANBIETER ist dafür verantwortlich, den zuständigen Steuerbehörden seine Abrechnungen im Hinblick auf jegliche anwendbaren Steuern zu allen Beträgen einzureichen, die als Gegenleistung für den SERVICE bei ihm eingehen. Sofern TREKKSOFT es verlangt, ist der ANBIETER verpflichtet, TREKKSOFT in den Fällen, in denen nach anwendbarem Recht Steuern anfallen, unverzüglich gültige Steuerrechnungen im Hinblick auf alle im Rahmen dieser Bedingungen abgeschlossenen Transaktionen vorzulegen.</p>
            <h3>13. Datenschutz</h3>
            <p>Der ANBIETER versichert, dass er angemessene Sicherheitsverfahren und -kontrollen installiert hat und aufrechterhalten wird, bzw. dass er diese einrichten und aufrechterhalten wird, um die unbeabsichtigte Offenlegung von und den unbefugten Zugriff auf oder die widerrechtliche Verwendung von personenbezogenen Daten oder Informationen von KUNDEN zu verhindern. Weder der ANBIETER noch seine verbundenen Unternehmen beteiligen sich mittelbar oder unmittelbar an verlangt oder unverlangt zugesandten Marketing- oder Promotion-Maßnahmen für KUNDEN oder ähnlichen Mitteilungen an KUNDEN, die über die PLATTFORM gebucht haben, ohne dass hierzu die Zustimmung des betreffenden KUNDEN vorliegt. Der ANBIETER ist verpflichtet, sämtliche kundenbezogenen Informationen (wie z. B. Kreditkarten-, Debitkarten- oder Bankkontodaten) gemäß den auf den ANBIETER anwendbaren Gesetzen wie z. B. den Datenschutzvorschriften, nach dem Datensicherheitsstandard der Kreditkartenbranche zum Schutz von Kredit- und Debitkarteninformationen und gemäß der EU-Datenschutzrichtlinie (und allen in deren Rahmen erlassenen Gesetzen) in ihrer jeweils geänderten, aktualisierten, ersetzten oder erweiterten Form zu verarbeiten, zu speichern, zu übermitteln und darauf zuzugreifen. Der ANBIETER ist auf Anforderung von TREKKSOFT verpflichtet nachzuweisen, dass er technische und organisatorische Sicherheitsmaßnahmen, die die Verarbeitung personenbezogener Daten gemäß dem vorliegenden Abschnitt regeln, installiert hat und aufrechterhält.</p>
            <h3>14. Zusicherungen und Garantien</h3>
            <p>Zusätzlich zu etwaigen sonstigen Zusicherungen und Garantien des ANBIETERS in diesen Vertragsbedingungen sichert der ANBIETER hiermit zu und garantiert, dass (a) er die Person, die diese Vertragsbedingungen im Namen des ANBIETERS unterzeichnet, bevollmächtigt hat, in seinem Namen zu handeln, (b) dieser Vertrag eine gültige und bindende Verpflichtung darstellt, die gemäß seinen Bestimmungen gegen ihn durchgesetzt werden kann, (c) mit der Erfüllung seiner Verpflichtungen im Rahmen dieser Bedingungen nicht gegen Vereinbarungen oder Verpflichtungen zwischen ihm und einem Dritten verstoßen wird, (d) die Erfüllung seiner Verpflichtungen im Rahmen dieser Bedingungen den Bedingungen entspricht, (e) er sämtliche Lizenzen, Genehmigungen und Vollmachten besitzt, die er benötigt, um seinen SERVICE zur Buchung über die PLATTFORM anzubieten und seine Verpflichtungen im Rahmen dieser Bedingungen einzuhalten, (f) er Inhaber sämtlicher Rechte an geistigem Eigentum ist, die über den ANBIETER-Account hochgeladen wurden, bzw. dass der Inhaber dieser Rechte seine Einwilligung erteilt hat, dies auf der PLATTFORM hochzuladen und die Rechte über die PLATTFORM zu lizenzieren.</p>
            <h3>15. Haftung des ANBIETERS und Freistellung</h3>
            <p>Der ANBIETER verpflichtet sich, TREKKSOFT, jedes seiner verbundenen Unternehmen sowie alle leitenden Angestellten, Geschäftsführer, Mitarbeiter und Bevollmächtigten von TREKKSOFT oder seinen verbundenen Unternehmen oder seinen Vertriebspartnern in Bezug auf Ansprüche, Klagen, Schäden, Kosten oder sonstige Verbindlichkeiten (wie z.B. Anwaltshonorare und -kosten) Dritter, die aus oder in Verbindung mit (a) dem SERVICE des ANBIETERS, (b) der Erfüllung der Pflichten und Verpflichtungen aus diesem Vertrag oder einer Verletzung oder Leistungsstörung dieses Vertrags seitens des ANBIETERS wie z. B. aus einem Verstoß gegen eine Zusicherung, Garantie oder Vereinbarung, oder (c) der Behauptung, dass die im Rahmen dieses Vertrags zulässige Nutzung, Reproduktion, Verbreitung oder Abbildung der Informationen über den SERVICE durch TREKKSOFT oder eines seiner verbundenen Unternehmen gegen die Rechte am geistigen Eigentum Dritter verstoßen oder dass diese widerrechtlich verwendet werden, entstehen, auf seine Kosten und nach Abzug der Steuern freizustellen, zu verteidigen und schadlos zu halten. Der ANBIETER verpflichtet sich, sich einen Rechtsanwalt zur Abwehr von Schadensersatzansprüchen zu nehmen, der für TREKKSOFT nach vernünftigem Dafürhalten akzeptabel ist, und TREKKSOFT ist berechtigt, sich jederzeit unter Inanspruchnahme von ihm selbst ausgewählten Rechtsanwälten an der Abwehr oder der Beilegung von Ansprüchen zu beteiligen. Ferner verpflichtet sich der ANBIETER, keinem Vergleich oder keiner gerichtlichen Entscheidung ohne die vorherige schriftliche Zustimmung von TREKKSOFT zuzustimmen, wobei diese Zustimmung von TREKKSOFT nicht ohne vernünftigen Grund verweigert werden darf.</p>
            <h3>16. Beschränkung der Haftung von TREKKSOFT</h3>
            <p>16.1. TREKKSOFT macht keine Zusicherungen und übernimmt keine Garantien im Hinblick auf die PLATTFORM, wie z. B. für eine vorübergehende oder dauerhafte Unterbrechung des Betriebs der PLATTFORM.</p>
            <p>16.2. TREKKSOFT trägt kein Risiko und macht keine Zusicherungen hinsichtlich der Anzahl, der Häufigkeit oder der Art von Servicebuchungen über die PLATTFORM.</p>
            <p>16.3. Die Haftung von TREKKSOFT im Rahmen dieser Vertragsbedingungen ist wie folgt eingeschränkt:</p>
            <p>(a) TREKKSOFT haftet in vollem Umfang für Schäden, die vorsätzlich oder grob fahrlässig von TREKKSOFT, seinen gesetzlichen Vertretern oder Führungskräften verursacht werden, sowie für Schäden, die vorsätzlich von anderen Erfüllungsgehilfen verursacht werden; in Bezug auf grobe Fahrlässigkeit anderer Erfüllungsgehilfen entspricht die Haftung von TREKKSOFT der in den Bestimmungen zur leichten Fahrlässigkeit.</p>
            <p>(b) TREKKSOFT haftet in vollem Umfang für Tod, Körperverletzung und gesundheitliche Schäden, die vorsätzlich oder grobfahrlässig von TREKKSOFT, seinen gesetzlichen Vertretern oder Erfüllungsgehilfen verursacht werden.</p>
            <p>16.4. TREKKSOFT haftet für einen Datenverlust nur bis zur Höhe der typischen Wiederherstellungskosten, die entstehen würden, wenn ordnungsgemäß und regelmäßig Datensicherungsmaßnahmen ergriffen worden wären.</p>
            <p>16.5. Eine etwaige umfangreichere Haftung von TREKKSOFT ist ausgeschlossen. Insbesondere übernimmt TREKKSOFT gegenüber dem KUNDEN keine Haftung weder aus diesem Vertrag noch aus dem Vertrag zwischen dem KUNDEN und dem ANBIETER (Leistungsvertrag).</p>
            <h3>17. Mitteilungen</h3>
            <p>TREKKSOFT ist berechtigt, dem ANBIETER Mitteilungen auf elektronischem Wege zukommen zu lassen. Mitteilungen gelten als zugegangen und empfangen, (a) bei Übertragung per Fax, wenn die Faxübertragung erfolgreich war, (b) bei Übermittlung durch einen landesweit anerkannten Übernacht-Luftkurierdienst, wenn der betreffende landesweit anerkannte Übernacht-Luftkurierdienst sie TREKKSOFT übergeben hat, und (c) bei elektronischer Übertragung, wenn sie an eine E-Mail-Adresse gesendet wurden, die der ANBIETER in seinem ANBIETER-Account auf der PLATTFORM hinterlegt hat.</p>
            <h3>18. Laufzeit und Kündigung/Beendigung</h3>
            <p>18.1. Dieser Vertrag wird auf unbestimmte Zeit geschlossen.</p>
            <p>18.2. Der ANBIETER und TREKKSOFT sind berechtigt, diesen Vertrag jederzeit und ohne Angabe von Gründen zu kündigen. Eine Kündigung kann automatisch unter Verwendung der entsprechenden Funktion im ANBIETER-Account erfolgen. Die verbleibenden bestätigten Buchungen müssen wie geplant ausgeführt werden.</p>
            <p>18.3. Wenn konkrete Hinweise darauf vorliegen, dass ein ANBIETER schuldhaft gegen rechtliche oder regulatorische Bestimmungen, Rechte Dritter oder diese Bedingungen verstößt, oder wenn TREKKSOFT ansonsten ein legitimes Interesse hat, insbesondere im Hinblick auf den Schutz der KUNDEN und anderer ANBIETER vor betrügerischen Aktivitäten, zur Einhaltung von Compliance Vorschriften ist TREKKSOFT berechtigt, unter angemessener Berücksichtigung der jeweiligen Interessen des ANBIETERS in dem betreffenden Fall eine oder mehrere der folgenden Maßnahmen zu ergreifen: Den ANBIETER warnen; die Angebote des ANBIETERS oder sonstigen Inhalt löschen; die Nutzung der PLATTFORM durch den ANBIETER einschränken; den ANBIETER vorübergehend oder dauerhaft von der PLATTFORM ausschließen (sperren); den ANBIETER-Account des ANBIETERS ohne Information des Letzteren zu schliessen (z.B. aus Compliance Gründen); Bestehende Buchungen auf den Namen des ANBIETERS ungeachtet der geltenden Stornierungsrichtlinien anfechten und stornieren; Vermögenswerte blockieren und diese weiter in eigenem Namen halten (insbesondere auch bei strittigen Zahlungen), unabhängig davon, ob der ANBIETER-Account aktiv oder geschlossen ist und die Angebote der KUNDEN zum Abschluss eines Vertrags im Namen des ANBIETERS ablehnen.</p>
            <h3>19. Änderung</h3>
            <p>TREKKSOFT behält sich das Recht vor, die Bestimmungen dieser Bedingungen jederzeit zu ändern und neue oder zusätzliche Bestimmungen einzuführen. TREKKSOFT wird den ANBIETER mit einer Nachricht auf seinem ANBIETER-Account über etwaige Änderungen an diesem Vertrag informieren. Nimmt der ANBIETER diese Änderungen oder neuen oder zusätzlichen Bestimmungen nicht an, kann er diesen Vertrag TREKKSOFT gegenüber schriftlich kündigen. Übt der ANBIETER sein Recht zur Kündigung dieses Vertrags nicht innerhalb von dreißig (30) Tagen nach Mitteilung über eine Änderung oder neue oder zusätzliche Bestimmungen zu diesem Vertrag aus, bedeutet dies, dass er die betreffenden Änderungen akzeptiert hat.</p>
            <h3>20. Schlussbestimmung</h3>
            <p>20.1. Nebenabreden wurden nicht getroffen. Alle Änderungen und Ergänzungen zu diesem Vertrag benötigen die Textform, um rechtswirksam zu sein.</p>
            <p>20.2. Falls einzelne Bestimmungen dieses Vertrags ungültig oder nichtig sind oder werden, bleibt die Gültigkeit der übrigen Vertragsteile hiervon unberührt. Ungültige oder nichtige Bestimmungen sind durch solche Bestimmungen zu ersetzen, die geeignet sind, unter Berücksichtigung der Interessen beider Parteien denselben Zweck zu erzielen.</p>
            <p>20.3. Die Ansprüche des ANBIETERS aus diesem Vertrag dürfen nicht ohne vorherige schriftliche Genehmigung von TREKKSOFT an Dritte abgetreten oder übertragen werden.</p>
            <p>20.4. TREKKSOFT ist berechtigt, seine Rechte und Pflichten aus diesem Vertrag an verbundene Unternehmen abzutreten.</p>
            <p>20.5. Dieser Vertrag und alle Ansprüche aus oder im Zusammenhang mit diesem Vertrag unterliegen unter Ausschluss der Regeln des internationalen Privatrechts dem schweizerischen Recht und sind diesem Recht gemäß auszulegen. Erfüllungsort und alleiniger Gerichtsstand für etwaige Streitigkeiten, die aus oder in Verbindung mit den erbrachten SERVICES entstehen, ist Thun.</p>
        </div>
    </>;
};
