import React, {useEffect, useState} from "react";
import { CheckoutHeader } from "../CheckoutPage";
import { __ } from "../../../translations";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
    ensureCorrectDelivery,
    isProviderDelivery,
    selectedDeliveryProviderSelector,
    selectedSectorSelector,
} from "../../../service/common.slice";
import { CheckoutSteps } from "./CheckoutSteps";
import { useNavigate } from "react-router-dom";
import {
    cartSelector,
    CartStatus,
    CheckoutStep,
    getCartStatus, navigateByCartStatus,
    setOrderAdditionalData
} from "../../../service/checkout.slice";
import {
    dateToString,
    isPossibleReservationDate,
    isSectorActive,
    sectorHasAvailableReservations,
    withLoading,
    isSectorSameDayPreorderEnabled,
    useLg, isGeoLocationDeliverySelected, isGeoLocationDeliverySet
} from "../../../service/utils";
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { DownStick } from "../../../common/DownStick";
import { CheckoutIconPoint } from "../Icons";
import { ReservationsTimeDownPopup } from "../ReservationsTimeDownPopup";
import { CheckInput } from "../../../common/contols/CheckInput";
import { SameDayPreOrderTimeSelector } from "../SameDayPreOrderTimeSelector";
import {CartSummary} from "./CartSummary";
import {SeatNumberInput} from "./SeatNumberInput";
import {useIsInputFocus} from "./useIsInputFocus";
import {GeoLocationSetter, NotMyLocationButton} from "./GeoLocationSetter";
import {GeoLocationView} from "../../geo-location/GeoLocationView";


export type ReservationOrderType = "immediate" | "reservation";

export const CheckoutStep2 = () => {
    const deliveryProvider = useAppSelector(selectedDeliveryProviderSelector);
    const isDelivery = deliveryProvider ? isProviderDelivery(deliveryProvider) : false;
    const dispatch = useAppDispatch();
    const cart = useAppSelector(cartSelector)!;
    const isGeoLocationDelivery = isGeoLocationDeliverySelected(cart);
    const isGeoLocationSet = isGeoLocationDeliverySet(cart);

    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);
    const isInputFocus = useIsInputFocus();

    const _ = useLg();
    const sector = useAppSelector(selectedSectorSelector);
    const navigate = useNavigate();

    const [seatNumber, setSeatNumber] = useState("");

    const [orderType, setOrderType] = useState<ReservationOrderType>(isSectorActive(sector!) ? "immediate" : "reservation");
    const [reservationDate, setReservationDate] = useState<Date | null>(null);

    const [phone, setPhoneValue] = useState<string | undefined>(undefined);

    const [phoneError, setPhoneError] = useState<string>("");
    const [seatError, setSeatError] = useState<string>("");
    const [reservationError, setReservationError] = useState<string>("");

    const [reservationTimesOpen, setReservationTimesOpen] = useState(isSectorActive(sector!) ? false : true);
    const sectorHasReservations = sector && sectorHasAvailableReservations(sector);
    const sectorHasSameDayPreOrderEnabled = sector && isSectorSameDayPreorderEnabled(sector);

    const isTableServiceSelected = Boolean(cart.orderlyIsTableservice);

    useEffect(() => {
        if (!cart) return;
        const seatNumber = cart.orderlySeatNumber;
        setSeatNumber(seatNumber||"");
        setPhoneValue(cart.contact?.telNumber ?? "");
    }, [cart, isDelivery]);

    useEffect(() => {
        setReservationTimesOpen(!isSectorActive(sector!));

        if (sectorHasSameDayPreOrderEnabled) {
            setReservationDate(null);
            if (isSectorActive(sector!)) {
                setOrderType("immediate");
            } else {
                setOrderType("reservation");
            }
        } else {
            if (cart.orderlyReservationDate && isPossibleReservationDate(cart.orderlyReservationDate, sector!)) {
                if (!isSectorActive(sector!)) {
                    setReservationDate(null);
                    setOrderType("reservation");
                } else {
                    setReservationDate(null);
                    setOrderType("immediate");
                }
            } else {
                setReservationDate(null);
                if (!isSectorActive(sector!)) {
                    setOrderType("reservation")
                } else {
                    setOrderType("immediate");
                }

            }
        }
    }, [isSectorActive(sector!)])

    useEffect(() => {
        setPhoneError("");
    }, [phone]);

    useEffect(() => {
        setSeatError("");
    }, [seatNumber]);

    useEffect(() => {
        setReservationError("");
    }, [reservationDate]);

    const isPhoneCorrect = (phone: string) => {
        return isValidPhoneNumber(phone);
    };

    const onOrderTypeChange = (type: ReservationOrderType) => {
        setOrderType(type);
        if (type === "reservation") {
            openReservationTimes();
        } else {
            setReservationTimesOpen(false)
        }
    };

    const openReservationTimes = () => {
        setReservationTimesOpen(true);
    };

    const next = async () => {
        let error = false;
        if (isDelivery) {
            if (isGeoLocationDelivery) {
                if (!isGeoLocationSet) {
                    error = true;
                }
            } else {
                if (!seatNumber) {
                    setSeatError(_("Sitzplatz erforderlich"));
                    error = true;
                }
            }
        } else if(cart.orderlyIsTableservice && !seatNumber) {
            setSeatError(_("Tischnummer erforderlich"));
            error = true;
        }
        if (!phone || !isPhoneCorrect(phone)) {
            setPhoneError(_("Diese Telefonnumer ist ungültig"));
            error = true;
        }
        if (error) {
            return;
        }

        const rDate = (orderType === "reservation") ? reservationDate : null;

        await wl.load(dispatch(setOrderAdditionalData({
            seat: (isDelivery||isTableServiceSelected) ? seatNumber : "",
            phone: phone ?? "",
            reservationDate: rDate || undefined
        })).unwrap());

        await wl.load(dispatch(ensureCorrectDelivery()));
        const cartStatus = await wl.load(dispatch(getCartStatus()).unwrap());
        if (cartStatus === CartStatus.UnavailableSectorWithReservations
            && !rDate
        ) {
            setReservationError(_("Sie müssen das Reservierungsdatum festlegen"));
            return;
        }

        navigateByCartStatus(navigate, cartStatus, "/checkout/step3");
    };

    return <div className="bb-container">
        <CheckoutHeader
            header={__("Kontaktdaten")}
            back={() => { navigate("/checkout") }}
        />
        <CheckoutSteps step={CheckoutStep.Form} />
        <div className="checkout-contents">
            <CartSummary />
            <div className="box checkout-form">
                {isDelivery && !isGeoLocationDelivery && <>
                    <h3>
                        {_("Wohin sollen wir deine Bestellung bringen?")}
                    </h3>
                    <div className="place-selector">
                        <label className="form-label">
                            {_("Reihe / Platz")}
                        </label>
                        <div className="input-line">
                            <SeatNumberInput
                                value={seatNumber}
                                onChange={setSeatNumber}
                                error={seatError}
                                isTableService={false}
                            />
                            <div className="sector-name">
                                {sector?.title}
                            </div>
                        </div>
                        {seatError && <div className="input-error">{seatError}</div>}
                    </div>
                </>}
                <h3>{_("Kontaktdaten")}</h3>
                <div className="comment">
                    {_("Nach der Bestellung erhälst du eine Bestellbestätigung und eine Status Meldung als SMS")}
                </div>
                <div className="mobile-phone-input">
                    <label className="form-label">
                        {_("Mobiltelefon")}
                    </label>
                    <div className={"input-container " + (phoneError ? "error" : "")}>
                        {/* @ts-ignore-next-line */}
                        <PhoneInput
                            defaultCountry={"CH"}
                            placeholder="Enter phone number"
                            value={phone}
                            onChange={setPhoneValue}
                        />
                    </div>
                    {phoneError && <div className="input-error">{phoneError}</div>}
                </div>

                {isDelivery && isGeoLocationDelivery && <>
                    <div className="sector-container">
                        <h3 className="sector-box-title">
                            Standort Lieferung
                        </h3>
                        <div className="sector" style={{flexDirection: "column"}}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div className="checkout-icon">
                                    <CheckoutIconPoint />
                                </div>
                                <div className="texts">
                                    <strong>Direkt zu dir (GPS)</strong>
                                </div>
                            </div>
                            {cart.orderlyGeoLocation&&isGeoLocationSet&&<GeoLocationView location={cart.orderlyGeoLocation} />}
                            <div style={{display:"flex", justifyItems: "flex-end"}}>
                                <NotMyLocationButton/>
                            </div>
                        </div>
                    </div>

                    <GeoLocationSetter />
                </>}
                {!isDelivery && (
                    <div className="sector-container">
                        <h3 className="sector-box-title">
                            {
                                isTableServiceSelected
                                    ? _("Tischservice")
                                    : _("Abholung")
                            }
                        </h3>
                        <div className="sector">
                            <div className="checkout-icon">
                                <CheckoutIconPoint />
                            </div>
                            <div className="texts">
                                <strong>{sector?.title}</strong>
                            </div>
                        </div>
                        {isTableServiceSelected&&<>
                            <br/>
                            <div className="place-selector">
                                <label className="form-label">
                                    {_("Platz")}
                                </label>
                                <div className="input-line">
                                    <SeatNumberInput
                                        value={seatNumber}
                                        onChange={setSeatNumber}
                                        error={seatError}
                                        isTableService={true}
                                    />
                                </div>
                                {seatError && <div className="input-error">{seatError}</div>}
                            </div>
                        </>}
                    </div>
                )}
                {sectorHasReservations && <div className="reservation-container">
                    <h3>
                        {isDelivery
                            ? _("Voraussichtl. Lieferzeitpunkt")
                            : isTableServiceSelected
                                ? _("Tisch ab")
                                : _("Abholzeitpunkt")}
                    </h3>
                    <div className="form-check primary" style={{ paddingTop: '20px' }} key={1}>
                        <CheckInput
                            className="form-check-input"
                            type="radio"
                            name="eat_now_enabled"
                            id={"eat_now"}
                            value={"immediate"}
                            onChange={(e: { target: { value: any; }; }) => onOrderTypeChange(e.target.value as any)}
                            checked={orderType === "immediate" && isSectorActive(sector!)}
                            disabled={!isSectorActive(sector!)}
                        />
                        <label className="form-check-label"
                            htmlFor={"eat_now"}
                        >
                            {_("Sobald wie möglich") + " "}
                            {!isSectorActive(sector!) ? `(${_("nicht verfügbar")})` : ""}&nbsp;
                        </label>
                    </div>
                    <div className="form-check primary" key={2}>
                        <CheckInput
                            className="form-check-input"
                            type="radio"
                            name="eat_now_disabled"
                            id={"eat_later"}
                            value={"reservation"}
                            onChange={(e: { target: { value: any; }; }) => onOrderTypeChange(e.target.value as any)}
                            checked={orderType === "reservation"}
                            disabled={false}
                        />
                        <label className="form-check-label"
                            htmlFor={"eat_later"}
                            onClick={()=>onOrderTypeChange("reservation")}
                        >
                            {_("Andere Uhrzeit / nächster Tag")}&nbsp;
                        </label>
                    </div>
                    {reservationError && <div className="input-error">{reservationError}</div>}
                    {orderType === "reservation" && reservationDate && !sectorHasSameDayPreOrderEnabled && <div className="selected-reservation-container">
                        <div className="date">
                            <span>
                                {isDelivery
                                    ? _("Voraussichtl. Lieferzeitpunkt") :
                                    isTableServiceSelected
                                        ? _("Tisch ab")
                                        : _("Abholzeitpunkt")
                                }
                            </span>
                            <span>{dateToString(reservationDate)}</span>
                        </div>

                        <div className="change-button-container">
                            <span
                                onClick={openReservationTimes}
                            >
                                {isDelivery
                                    ? _("Voraussichtl. Lieferzeitpunkt ändern")
                                    : isTableServiceSelected
                                        ? _("Tischzeit ändern")
                                        : _("Abholzeit ändern")}
                            </span>
                        </div>
                    </div>}
                    {(sectorHasSameDayPreOrderEnabled && (reservationDate != null || !isSectorActive(sector!)))
                        ? <SameDayPreOrderTimeSelector currentDay={reservationDate} sector={sector} onDateChange={setReservationDate} />
                        : null
                    }
                </div>}
            </div>
        </div>
        {!isInputFocus && <div className="cart-sticky-container no-transition">
            <DownStick>
                <button className="btn btn-primary big-primary"
                    onClick={next}
                    disabled={loading || (!isSectorActive(sector!) && orderType !== "reservation")}
                >
                    {loading?"Lädt...":_("Weiter")}
                </button>
            </DownStick>
        </div>}
        {isInputFocus && <div className="bb-button-container down-stick">
            <button className="btn btn-primary big-primary"
                onClick={next}
                disabled={loading || (!isSectorActive(sector!) && orderType !== "reservation")}
            >
                {loading?"Lädt...":_("Weiter")}
            </button>
        </div>}
        {sector && <ReservationsTimeDownPopup
            show={reservationTimesOpen}
            onClose={() => {
                setReservationTimesOpen(false)
            }}
            orderType={!isSectorActive(sector!) ? "reservation" : orderType}
            sameDayPreorderEnabled={sectorHasSameDayPreOrderEnabled ?? false}
            isTableService={isTableServiceSelected ?? false}
            isDelivery={isDelivery}
            onOrderTypeChange={setOrderType}
            date={reservationDate}
            onDateChange={setReservationDate}
            sector={sector}
        />}
    </div>;
};
