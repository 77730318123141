import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import {useAppSelector} from "../store/store";
import {defaultSliderSelector, sliderSelector} from "../service/common.slice";
import React, {useEffect, useState} from "react";
import "./MainPageSlider.sass";
import {useLg} from "../service/utils";


export const MainPageSlider = (
    {showDefault}: {showDefault?: boolean}
) => {
    const _ = useLg();
    const [show, setShow] = useState<boolean>(false);
    const slider = useAppSelector(showDefault?defaultSliderSelector:sliderSelector);

    const renderSliderIndicator = (
        clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
        isSelected: boolean,
        index: number,
        label: string
    ): React.ReactNode => {
        if (!isSelected) {
            return <svg width="23" height="4" viewBox="0 0 23 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="23" height="4" rx="2" fill="white" fillOpacity="0.5"/>
            </svg>
        }
        return <svg width="23" height="4" viewBox="0 0 23 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="23" height="4" rx="2" fill="currentColor"/>
        </svg>;
    };

    useEffect(()=>{
        const to = setTimeout(()=>setShow(true), 1000);
        return () => clearTimeout(to);
    }, [slider]);

    return <div className="slider-carousel">
        {show&&<Carousel showArrows={false}
             showThumbs={false}
             showIndicators={true}
             renderIndicator={renderSliderIndicator}
             swipeScrollTolerance={5}
             preventMovementUntilSwipeScrollTolerance={true}
             emulateTouch={true}
             key={slider?.length}
        >
            {slider?.map((s, i) => <div className="main-slider"
                                       style={{backgroundImage: `url("${s.imageSrc}")`}}
                                       key={i}
            >
                <div className="main-slider-overlay"/>
                <div className="slider-contents">
                    <div className="sub-header" />
                    <div className="header">
                        {_(s.title)}
                    </div>
                </div>
            </div>)}
        </Carousel>}
    </div>;
};
