import * as React from "react";
import {useNavigate} from "react-router-dom";
import "./PageUnavailable.sass";
import {__, TranslatedString} from "../translations";
import {useAppSelector} from "../store/store";
import {selectedSectorSelector} from "../service/common.slice";
import packetWarning from "../assets/common/images/packet-warning.svg";
import {useLg} from "../service/utils";


export const DeliveryIsUnavailablePage = () => {
    const _ = useLg();
    const navigate = useNavigate();
    const back = () => {
        navigate("/");
    };
    const selectedSector = useAppSelector(selectedSectorSelector);

    return <div className="page-unavailable bb-container">
        <div className="img">
            <img src={packetWarning} alt="Not found"/>
        </div>
        <h2 className="smaller">
            {_("Die Lieferung ist momentan nicht möglich")}
        </h2>
        <div className="comment">
            <TranslatedString str={__("Es tut uns leid! \n\n[sector] kann gerade keine Bestellungen entgegennehmen. \n\nBitte versuchen Sie es später noch einmal",
                {"[sector]":selectedSector?.title??"Kiosk"})}/>
        </div>
        <div className="bb-button-container">
            <button className="btn btn-primary big-primary"
                    onClick={back}
            >
                {_("Zu orderly")}
            </button>
        </div>
    </div>;
};
