import "./KitchenNotification.sass"
import {useAppSelector} from "../../store/store";
import {selectedSectorSelector} from "../../service/common.slice";
import {useEffect, useState} from "react";
import {useLg} from "../../service/utils";

export const KitchenNotification = () => {
    const _ = useLg();
    const sector = useAppSelector(selectedSectorSelector);
    const closedTime = sector?.kitchenClosureTime;
    const [minsLeft, setMinsLeft] = useState<number|undefined>(getMinutesLeftTillClose(closedTime));

    useEffect(()=>{
        if (!sector) return;
        const closedTime = sector.kitchenClosureTime;
        const to = setInterval(()=>{
            setMinsLeft(getMinutesLeftTillClose(closedTime));
        }, 30*1000);
        return () => clearInterval(to);
    }, [sector]);

    if (minsLeft === undefined) return <div/>;

    const isOpen = minsLeft > 0;

    return <div className={"kitchen-notification " + (!isOpen?"closed":"")}>
        <div className="icon icon-megaphone"/>
        {isOpen&&<div className="text">
            <div className="header">
                {_("Jetzt noch bestellen!!")}
            </div>
            <div className="comment">
                {_("Küchenschluss in [mins]min!", {
                    "[mins]": minsLeft.toString()
                })}
            </div>
        </div>}
        {!isOpen&&<div className="text">
            <div className="header">
                {_("Küche geschlossen")}
            </div>
        </div>}
    </div>;
};


const getMinutesLeftTillClose = (closedTimeString?: string): number|undefined => {
    if (!closedTimeString) return;
    const showWhileLessThanLeft = 15; // 15 minutes

    const [hoursStr, minutesStr] = closedTimeString.split(":");
    const hours = Number(hoursStr), minutes = Number(minutesStr);
    if (isNaN(hours) || isNaN(minutes)) return;

    const closedTimeMinutesUtc = hours*60 + minutes;
    const now = new Date();
    const minutesNowUtc = now.getHours()*60 + now.getMinutes() + now.getTimezoneOffset();
    const leftMinutes = closedTimeMinutesUtc - minutesNowUtc;

    if (leftMinutes > showWhileLessThanLeft) return;
    return leftMinutes;
};

