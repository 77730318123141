import {PayloadAction} from "@reduxjs/toolkit";
import { RootState } from "./store";


export function createSetter<S>() {
    return <K extends keyof S>(propName: K): (state: S, action: PayloadAction<S[K]>) => void => {
        return (state: S, action: PayloadAction<S[K]>) => {
            state[propName] = action.payload;
        }
    }
}

export function createGetters<ST>(sliceSelector: (state: RootState)=>ST) {
    const sel = <R>(selectorFn: (sliceState: ST) => R) => {
        return (state: RootState) => selectorFn(sliceSelector(state));
    }
    const getter = <K extends keyof ST>(propName: K) => {
        return sel(state => state[propName]);
    }

    return {
        sel,
        getter
    };
}
