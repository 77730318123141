import "./Search.sass";
import {useAppDispatch, useAppSelector} from "../store/store";
import {
    commonSlice,
    isSearchOpenSelector,
} from "../service/common.slice";
import {useEffect, useRef, useState} from "react";
import {popularProductsSelector, productsAssortmentsSelector} from "../service/products.slice";
import {Product} from "../service/products.service";
import {useLg} from "../service/utils";


export const Search = () => {
    const [searchTerm, setSearchTerm] = useState<string>("");

    const dispatch = useAppDispatch();
    const _ = useLg();
    const popularProducts = useAppSelector(popularProductsSelector);
    const isSearchOpen = useAppSelector(isSearchOpenSelector);

    const productsAssortments = useAppSelector(productsAssortmentsSelector);

    const [results, setResults] = useState<Product[]>([]);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const searchInput = useRef<HTMLInputElement>(null);

    useEffect(()=>{
        setIsOpen(isSearchOpen);
        if (!isSearchOpen) return;
        const to = setTimeout(()=>{
            searchInput.current?.focus();
        }, 300);
        return () => {
            clearTimeout(to);
        }
    }, [isSearchOpen]);

    const close = () => {
        dispatch(commonSlice.actions.closeSearch());
    };

    const onSearchChange = async (e: any) => {
        let term = e.target.value;
        setSearchTerm(term);
        if (!term) { setResults([]); return; }
        term = term.toLowerCase();

        const results =
            (Object.values(
                productsAssortments
                .map(c=>c.products)
                .reduce((acc, v)=>[...acc,...v], [] as Product[])
                .reduce((acc, v) => ({...acc, [v.id]: v}), {})
            ) as Product[])
            .filter((v: Product) => v.title.toLowerCase().includes(term));

        //const results = await wl(dispatch(searchProducts(term)).unwrap());
        setResults(results);
    };

    const gotoProduct = (p: Product) => {
        dispatch(commonSlice.actions.setSearchFocusProduct(p));
        close();
    };

    return <div className="search-container">
        {isOpen&&<>
            <div className="search-input-container">
                <div className="search-input-inner">
                    <div className="input-group">
                        <div
                            className="icon icon-left-black"
                            onClick={close}
                        />
                        <input
                            type="text"
                            className="form-control"
                            placeholder={_("Essen & Getränke suchen")}
                            ref={searchInput}
                            onChange={onSearchChange}
                        />
                    </div>
                </div>
            </div>
            <div className={"search-result-container show"}>
                {searchTerm === "" && <div className="popular-list">
                    <div className="header">{_("Popular")}</div>
                    <ul className="results-list">
                        {popularProducts.map(r =>
                        <li key={r.id} onClick={()=>gotoProduct(r)}>
                            <div className="icon icon-refresh-time" />
                            <div className="term">{r.title}</div>
                        </li>
                        )}
                    </ul>
                </div>}
                {searchTerm !== "" && <>
                    <ul className="results-list">
                        {results.map(r =>
                            <li key={r.id} onClick={()=>gotoProduct(r)}>
                                <div className="term">{r.title}</div>
                            </li>
                        )}
                    </ul>
                </>}
            </div>
        </>}
    </div>;
};
