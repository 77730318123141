import "./LeftMenu.sass";
import {useAppDispatch, useAppSelector} from "../store/store";
import {commonSlice, languageSelector, isLeftMenuOpenSelector, themeSelector} from "../service/common.slice";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import orderlyLogo from "../assets/common/images/logo.svg";
import orderlyLogoColored from "../assets/common/images/logo-colored.svg";
import {useLg} from "../service/utils";

export const LeftMenu = () => {
    const theme = useAppSelector(themeSelector);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(isLeftMenuOpenSelector);
    const openClass = isOpen?"open":"";
    const language = useAppSelector(languageSelector);
    const _ = useLg();

    useEffect(()=>{
        setOpenClass(isOpen);
    }, [isOpen]);

    const setOpenClass = (isOpen: boolean) => {
        const body = document.body;
        let className = body.className;
        const leftMenuClassName = "left-menu-open";
        className = className.replace(leftMenuClassName, "");
        className = className.replace(/\s\s+/g," ");
        if (isOpen) {
            className += " "+leftMenuClassName;
        }
        body.className = className;
    };

    const toggleMenu = () => {
        dispatch(commonSlice.actions.toggleLeftMenu());
    };

    const openLanguageSelector = () => {
        dispatch(commonSlice.actions.openLanguageSelector());
    };

    const goto = (link: string) => () => {
        toggleMenu();
        setOpenClass(false);
        navigate(link);
    };

    const share = () => {
        navigator.share({
            url: window.location.href,
            text: "1-2-eat site"
        })
    };

    const logo = theme?.logoSrc?theme?.logoSrc:orderlyLogo;

    if (!theme) return <div/>;

    return <div className={"drawer-container " + openClass}>
        <div className="drawer-header">
            <div className="icons">
                <div
                    className="icon icon-left-white"
                    onClick={toggleMenu}
                />
            </div>
            <div className="orderly-main-label">
                <img src={logo} alt="logo" />
            </div>
            <div className="link-label-container" onClick={share}>
                <div className="icon icon-share" />
                <div className="label">
                    {_("1-2-eat link teilen")}
                </div>
            </div>
        </div>
        <div className="drawer-menu">
            <ul>
                <li onClick={goto("/orders")}>
                    <div className="title-container">
                        <div className="icon icon-cart" />
                        <div className="title">
                            <span>{_("Meine Bestellungen")}</span>
                        </div>
                        <div className="icon icon-right-black" />
                    </div>
                </li>
                {/*<li onClick={goto("/help")}>*/}
                {/*    <div className="title-container">*/}
                {/*        <div className="icon icon-question" />*/}
                {/*        <div className="title">*/}
                {/*            <span>{_("Hilfe & FAQs")}</span>*/}
                {/*        </div>*/}
                {/*        <div className="icon icon-right-black" />*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*<li onClick={goto("/")}>*/}
                {/*    <div className="title-container">*/}
                {/*        <div className="icon icon-info" />*/}
                {/*        <div className="title">*/}
                {/*            <span>{_("Über Orderly")}</span>*/}
                {/*        </div>*/}
                {/*        <div className="icon icon-right-black" />*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*<li onClick={goto("/help/agbs")}>*/}
                {/*    <div className="title-container">*/}
                {/*        <div className="icon icon-empty" />*/}
                {/*        <div className="title">*/}
                {/*            <span>{_("AGBs")}</span>*/}
                {/*        </div>*/}
                {/*        <div className="icon icon-right-black" />*/}
                {/*    </div>*/}
                {/*</li>*/}
                <li onClick={openLanguageSelector}>
                    <div className="title-container">
                        <div className="icon icon-language" />
                        <div className="title">
                            <span>{_(language)}</span>
                        </div>
                        <div className="icon icon-right-black" />
                    </div>
                </li>
            </ul>
        </div>
        <div className="bottom">
            <div className="address">
                {theme?.address}
            </div>
            <div className="phone">
                <a href={"tel:"+theme?.telNumber}>{theme?.telNumber}</a>
            </div>
            <div className="email">
                <a href={"mailto:"+theme?.emailAddress}>{theme?.emailAddress}</a>
            </div>
            <div className="logo">
                <img src={orderlyLogoColored} alt="logo" />
            </div>
        </div>
        <div className="drawer-menu">
            <ul>
                <li onClick={goto("/terms")}>
                    <div className="title-container">
                        <div className="icon icon-info" />
                        <div className="title">
                        <a href="/terms" onClick={(e) => { navigate("/terms"); e.preventDefault(); }}>AGBs</a> 
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
};
