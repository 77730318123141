import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../common/PageHeader";
import { __ } from "../../translations";
import * as React from "react";

export const Terms = () => {
    const navigate = useNavigate();
    const [isSeebadRestaurant, setIsSeebadRestaurant] = React.useState(false);

    React.useEffect(() => {
        const url = window.location.href;
        if (url.includes("seebad-restaurant-romanshorn.12eat.ch")) {
            setIsSeebadRestaurant(true);
        }
    }, []);

    const back = () => {
        navigate(-1);
    };

    return (
        <>
            <PageHeader onBack={back} isBackAvailable={true} header={__("Terms")} />
            <div className="help-content">
              
                {isSeebadRestaurant ? (
                    <>
                        <h3>Allgemeine Geschäftsbedingungen (AGB)</h3>
                        <p>Es gelten die Bedingungen der jeweiligen Location: <a href="https://drive.google.com/file/d/1xROhb33KLfq8pN5k7N6rE_ZzwymoahvY/view?usp=sharing">AGB Restaurant Arena</a></p>
                        <p>AGB des Zahlungsanbieters Wordline: <a href="https://drive.google.com/file/d/1mw6UoLaebiXb3cskTfjWyC8de7Qtzkh_/view?usp=sharing">Wordline AGBs</a></p>
                    </>
                ) : (
                    <>
                      <h2>Allgemeine Geschäftsbedingungen (AGB) für 1-2-eat.ch</h2>

                        <h3>1. Geltungsbereich</h3>
                        <p>Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Bestellungen und Lieferungen sowie Take Away Bestellungen von Speisen und Getränken über die Website 1-2-eat.ch oder 12eat.ch, die von ArtEmotions (nachfolgend "wir" oder "1-2-eat") betrieben wird.</p>

                        <h3>2. Bestellung und Vertragsabschluss</h3>
                        <p>2.1. Durch das Absenden einer Bestellung auf unserer Website gibt der Kunde ein verbindliches Angebot zum Abschluss eines Kaufvertrages ab.</p>
                        <p>2.2. Der Kaufvertrag kommt durch unsere Bestätigung der Bestellung per SMS zustande.</p>

                        <h3>3. Lieferung und Abholung</h3>
                        <p>3.1. Die bestellten Speisen und Getränke werden, je nach zur Verfügung stehenden Modi, an den vom Kunden angegebenen Standort (GPS), die Tischnummer oder die Sektor/Reihe/Sitznummer geliefert oder können an der angegebenen Ausgabestelle abgeholt werden.</p>
                        <p>3.2. Wird im GPS Modus der Besteller nicht gefunden, gilt die Bestellung dennoch als abgeschlossen und bezahlt. Reklamationen sind mit dem jeweiligen Veranstalter zu klären.</p>
                        <p>3.3. Lieferzeiten können je nach Veranstaltung und Standort variieren. Wir bemühen uns um eine pünktliche Lieferung, übernehmen jedoch keine Haftung für Verzögerungen.</p>

                        <h3>4. Verantwortlichkeit und Reklamationen</h3>
                        <p>4.1. Der Kunde ist verpflichtet, die Bestellung bei der Lieferung oder Abholung zu überprüfen.</p>
                        <p>4.2. Sollte der Kunde seine Bestellung nicht erhalten, muss er sich umgehend an die Ausgabestelle wenden. 1-2-Eat übernimmt keine Verantwortung für nicht erhaltene Bestellungen.</p>
                        <p>4.3. Es gelten die Bedingungen der jeweiligen Location</p>

                        <h3>5. Zahlung</h3>
                        <p>5.1. Die Bezahlung erfolgt online über den Zahlungsanbieter Wordline oder des Zahlungsanbieters Payyo by Trekksoft.</p>
                        <p>AGB des Zahlungsanbieters Wordline: <a href="https://drive.google.com/file/d/1mw6UoLaebiXb3cskTfjWyC8de7Qtzkh_/view?usp=sharing">Wordline AGBs</a></p>
                        <p>AGB des Zahlungsanbieters Trekksoft: <a href="/payo_terms" onClick={(e) => { navigate("/payo_terms"); e.preventDefault(); }}>AGB Payyo by Trekksoft</a></p>
                        <p>5.2. Der Kaufpreis ist bei Bestätigung der Bestellung sofort fällig und wird belastet.</p>

                        <h3>6. Stornierung und Rücktritt</h3>
                        <p>6.1. Eine Stornierung der Bestellung nach Bestellabschluss ist grundsätzlich nicht möglich.</p>
                        <p>6.2. Sollte eine Stornierung erforderlich sein, muss sich der Kunde an die entsprechende Ausgabestelle wenden.</p>

                        <h3>7. Haftung</h3>
                        <p>7.1. 1-2-Eat haftet nur für Schäden, die auf vorsätzlichem oder grob fahrlässigem Verhalten beruhen.</p>
                        <p>7.2. Für leichte Fahrlässigkeit haften wir nur bei Verletzung wesentlicher Vertragspflichten.</p>

                        <h3>8. Datenschutz</h3>
                        <p>8.1. Die Verarbeitung personenbezogener Daten erfolgt gemäß unserer Datenschutzerklärung, die auf unserer Website einsehbar ist.</p>
                        <p>8.2. Mit der Bestellung stimmt der Kunde der Erhebung, Verarbeitung und Nutzung seiner personenbezogenen Daten zu. <a href="https://1-2-eat.ch/datenschutz/">Datenschutz 12eat</a></p>

                        <h3>9. Änderungen der AGB</h3>
                        <p>9.1. Wir behalten uns das Recht vor, diese AGB jederzeit zu ändern. Die jeweils aktuelle Version ist auf unserer Website einsehbar.</p>
                        <p>9.2. Änderungen der AGB gelten ab dem Zeitpunkt der Veröffentlichung auf unserer Website.</p>

                        <h3>10. Schlussbestimmungen</h3>
                        <p>10.1. Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.</p>
                        <p>10.2. Es gilt schweizerisches Recht. Der Gerichtsstand ist St.Gallen.</p>
                    </>
                )}
            </div>
        </>
    );
};