import {useAppDispatch, useAppSelector} from "../store/store";
import {commonSlice, languageSelector, isLanguageSelectorOpenSelector, setLanguage} from "../service/common.slice";
import "./LanguageSelector.sass";
import {ChangeEvent, useEffect, useState} from "react";
import {Language, Languages} from "../translations";
import {DownPopup} from "./DownPopup";
import { CheckInput } from "./contols/CheckInput";
import {useLg} from "../service/utils";

export const LanguageSelector = () => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(isLanguageSelectorOpenSelector);

    const language = useAppSelector(languageSelector);
    const _ = useLg();
    const [confirmedLanguage, setConfirmedLanguage] = useState<Language|undefined>(undefined);

    useEffect(()=>{
        if (isOpen) {
            setConfirmedLanguage(language);
        } else {
            if (confirmedLanguage) {
                dispatch(setLanguage(confirmedLanguage));
            }
        }
    }, [isOpen, confirmedLanguage, language, dispatch]);

    const select = (language: Language) => {
        dispatch(setLanguage(language));
    };

    const onLangChange = (e: ChangeEvent) => {
        const selectedLanguage = (e.currentTarget as HTMLInputElement).value as Language;
        select(selectedLanguage);
    };

    const confirmLanguage = () => {
        setConfirmedLanguage(language);
        close();
    };

    const onClose = () => {
        close();
    };

    const close = () => {
        dispatch(commonSlice.actions.closeLanguageSelector());
    };

    return <div className="language-selector-container">
        <DownPopup
            show={isOpen}
            onClose={onClose}
        >
            <div className="header">{_("Sprache auswählen")}</div>
            <form>
                {Languages.map(lang =>
                    <div className="form-check primary" key={lang}>
                        <CheckInput
                            className="form-check-input"
                            type="radio"
                            name="language-selector"
                            id={"language_"+lang}
                            onChange={onLangChange}
                            value={lang}
                            checked={language===lang}
                        />
                        <label className="form-check-label"
                               htmlFor={"language_"+lang}
                        >
                            {_(lang)}
                        </label>
                    </div>
                )}
            </form>
            <div>
                <button
                    type="button"
                    className="btn btn-primary big-primary"
                    onClick={confirmLanguage}
                >{_("Speichern")}</button>
            </div>
        </DownPopup>
    </div>
};
