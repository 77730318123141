import "./DownPopup.sass";
import { ReactNode, useEffect, useState } from "react";

interface DownPagePopupProps {
    children: ReactNode;
    show: boolean;
    onClose: () => void;
    showCloseIcon?: boolean;  // new prop
    zIndex?: number;
}

export const DownPopup = (props: DownPagePopupProps = {
    children: null,
    show: false,
    onClose: () => {},
    showCloseIcon: true,  // default value set to true
    zIndex: 110
}) => {
    const zIndex = props.zIndex || 110;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showClass, setShowClass] = useState<string>("");
    
    const showCloseIcon = props.showCloseIcon===undefined?true:props.showCloseIcon;

    const close = () => {
        if (showCloseIcon) {
            props.onClose();
        }
    };

    useEffect(() => {
        let to: NodeJS.Timeout;
        if (props.show) {
            setIsOpen(true);
            to = setTimeout(() => setShowClass("show"), 50);
        } else {
            setShowClass("");
            to = setTimeout(() => setIsOpen(false), 300);
        }
        return () => clearTimeout(to);
    }, [props.show]);

    return (
        <div className="down-popup-container">
            {isOpen && (
                <>
                    <div
                        className={"down-popup-background " + showClass}
                        onClick={close}
                        style={{zIndex}}
                    />
                    <div className={"down-popup-content " + showClass}
                         style={{zIndex: zIndex+1}}
                    >
                        <div className="down-popup-content-inner">
                            {/* Only show close button if showCloseIcon prop is true */}
                            {showCloseIcon && (
                                <div className="button-close" onClick={close}>
                                    <div className="icon icon-close" />
                                </div>
                            )}
                            {props.children}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
