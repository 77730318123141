import * as React from "react";
import {OrderItem} from "./OrderItem";
import {OrderItemFragment} from "../../service/__generated__/OrderItemFragment";

export const OrderItems = (
    {
        orderItems, readOnly, includeDepot
    }: {
        orderItems: OrderItemFragment[],
        readOnly: boolean,
        includeDepot?: boolean
    },

) => {
    return <div className="items">
        {orderItems!.map((item, i)=><OrderItem
            orderItem={item}
            key={i}
            readOnly={readOnly}
        />)}
    </div>
};
