import {gql} from "@apollo/client";
import {ProductFragmentQL} from "./products.service";

export const DeliveryProviderFragmentQL = gql`
fragment DeliveryProviderFragment on DeliveryProvider {
    _id
    isActive
    type
    interface {
        _id
        label
    }
}
`;

export const PickUpLocationFragmentQL = gql`
fragment PickUpLocationFragment on OrderPickUpLocation {
    _id
    name
    orderlyIsAvailable
}
`;

export const OrderDeliveryFragmentQL = gql`
fragment OrderDeliveryFragment on OrderDelivery {
    _id
    ... on OrderDeliveryPickUp {
        pickUpLocations {
            ...PickUpLocationFragment
        }
        activePickUpLocation {
            ...PickUpLocationFragment
        }
    }
    provider {
        ...DeliveryProviderFragment
    }
}
${DeliveryProviderFragmentQL}
${PickUpLocationFragmentQL}
`;

export const OrderlySectorFragmentQL = gql`
fragment OrderlySectorFragment on OrderlySector {
    _id
    title
    subtitle
    isActive
    isSameDayPreOrderEnabled
    isTableserviceAllowed
    isGeoLocationDeliveryEnabled
    isGeoLocationDeliveryOnly
    isTableServiceOnly
    isThirdSeatFieldEnabled
    kitchenClosureTime
    timeToAutoCloseSeconds
    timeToDeliverySeconds
    supportedDeliveryProviders {
        ...DeliveryProviderFragment
    }
    reservationHours: preOrderOpeningHours
}
${DeliveryProviderFragmentQL}
`;

export const PaymentProviderFragmentQL = gql`
fragment PaymentProviderFragment on PaymentProvider {
    _id
    type
    interface{_id label}
    isActive
}
`;

export const OrderItemFragmentQL = gql`
fragment OrderItemFragment on OrderItem {
    _id
    quantity
    product {
        ...ProductFragment
    }
    orderlyOptions {
        ...ProductFragment 
    }
    discount: total(category: DISCOUNT) {
        amount
        currency
    }
    total {
        amount
        currency    
    }
}
${ProductFragmentQL}
`;


export const OrderlyGeoLocationFragmentQL = gql`
fragment OrderlyGeoLocationFragment on OrderlyGeoLocation {
    accuracy 
    latitude
    longitude
}
`;


export const CartFragmentQL = gql`
fragment CartFragment on Order {
    _id
    orderlySeatNumber
    contact {telNumber}
    orderlyReservationDate
    orderlyIsTableservice
    orderlyGeoLocation {
        ...OrderlyGeoLocationFragment
    }
    items {
        ...OrderItemFragment
    }
    discounts {
        interface { 
            _id
            label
        }
        total {
            amount 
            currency        
        }    
    }
    delivery: total(category: DELIVERY) {
        amount
        currency
    }
    goods: total(category: ITEMS) {
        amount
        currency
    }
    paymentTotal: total(category: PAYMENT) { 
        amount 
        currency
    }
    total {
        amount
        currency
    }
    deliveryObject: delivery {
        ...OrderDeliveryFragment
    }
}
${OrderItemFragmentQL}
${OrderDeliveryFragmentQL}
${OrderlyGeoLocationFragmentQL}
`;


export const CreatedOrderFragmentQL = gql`
fragment CreatedOrderFragment on Order {
    ...CartFragment    
    orderNumber
    status
    orderlySeatNumber
    orderlyRejectReason
    orderlyReservationDate
    orderlyIsTableservice
    orderlyIsReservationConfirmed
    orderlyGeoLocation {
        ...OrderlyGeoLocationFragment
    }
    ordered
    deliveryProvider: delivery {
        ... on OrderDeliveryPickUp {
            activePickUpLocation {
                name
            }        
        }
        provider {
            ...DeliveryProviderFragment
        }
    }
    payment {
        status 
        paid        
    }
}
${CartFragmentQL}
${DeliveryProviderFragmentQL}
${OrderlyGeoLocationFragmentQL}
`;

