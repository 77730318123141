import {gql} from "@apollo/client";
import {ApiService} from "./service";
import {
    getAssortmentsAndPopularProductsQuery_assortments,
    getAssortmentsAndPopularProductsQuery_assortments_searchProducts_products_SimpleProduct
} from "./__generated__/getAssortmentsAndPopularProductsQuery";
import {ProductStatus} from "../globalTypes";


export const ProductFragmentQL = gql`
fragment ProductFragment on Product {
    _id
    status
    texts { title description subtitle}
    media {
        file {
            url(version: "medium")
            original_url: url(version: "original") 
        }
    }
    tags
    ... on SimpleProduct {
        baseUnit
        simulatedPrice {
            amount
            currency
        }
        orderlyAvailability
        orderlyOptions {
            _id
            status
            texts { title }
            media { file { url } }
            ... on SimpleProduct {
                simulatedPrice {
                    amount currency                
                }
                orderlyAvailability                        
            }
        }
    }
}
`;

const ASSORTMENTS_AND_POPULAR_QUERY = gql`
query getAssortmentsAndPopularProductsQuery {
    assortments(limit: 0) {
        _id
        texts {
            title
            subtitle        
        }    
        media {
            file {
                name
                url
            }
        }
        searchProducts(includeInactive: true) {
            products(limit: 0) {
                ...ProductFragment
            }
        }
    }
    products (tags: "popular") {
        ...ProductFragment
    }
}
${ProductFragmentQL}
`;

const SEARCH_PRODUCTS_QUERY = gql`
query search($term: String) {
    searchProducts(includeInactive: true, queryString: $term) {
        products {
            ...ProductFragment
        }
    }
}
${ProductFragmentQL}
`;

export interface ProductsAssortment {
    name: string;
    image: ProductImage;
    products: Product[];
}

export type ProductAssortments = ProductsAssortment[];

export interface Price {
    amount: number;
    currency: string;
}

export enum ProductAvailability {
    AVAILABLE = "AVAILABLE",
    OUT_OF_STOCK = "OUT_OF_STOCK",
    DISABLED = "DISABLED",
}

export interface ProductData {
    id: string;
    isActive: boolean;
    title: string;
    description?: string;
    subtitle?: string;
    image?: ProductImage;
    price: Price;
    availability: ProductAvailability;
    tags: string[];
}

export interface Product extends ProductData {
    orderlyOptions: ProductData[];
}

export interface ProductImage {
    small_src: string;
    medium_src: string;
    src: string;
}

export type SimpleProduct = getAssortmentsAndPopularProductsQuery_assortments_searchProducts_products_SimpleProduct;

const makeProductData = (data: SimpleProduct, isOption?: boolean) => {
    isOption = isOption || false;

    if (!data.simulatedPrice) {
        throw new Error('Product has no simulatedPrice');
    }
    if (!isOption && !(data as unknown as any).orderlyAvailability) {
        throw new Error('Product has no orderlyAvailability');
    }
    let image: ProductImage|undefined;
    if (data.media && data.media.length > 0 && data.media[0].file) {
        image = {
            src: data.media[0].file?.original_url,
            medium_src: data.media[0].file?.url,
            small_src: data.media[0].file?.url,
        }
    }
    return {
        id: data._id,
        isActive: data.status === "ACTIVE" && ((data as unknown as any).orderlyAvailability === ProductAvailability.AVAILABLE),
        title: data.texts!.title,
        description: data.texts!.description,
        tags: data.tags || [],
        image,
        price: data.simulatedPrice,
        subtitle: data.texts?.subtitle,
        availability: (data as unknown as any).orderlyAvailability
    } as ProductData;
};

const makeProduct = (data: SimpleProduct): Product|undefined => {
    if (data.status !== ProductStatus.ACTIVE) {
        return undefined;
    }
    try {
        return {
            ...makeProductData(data),
            orderlyOptions: data.orderlyOptions.map(d=>makeProductData(d as SimpleProduct, true))
        } as Product;
    } catch (e) {
        console.log('product excluded', data);
        console.error(e);
        return undefined;
    }
};


export const searchProductsQ = async (term: string): Promise<Product[]> => {
    const resp = await ApiService.getClient()
        .query({query:SEARCH_PRODUCTS_QUERY, variables: {term}});
    return resp.data.searchProducts.products.map(makeProduct).filter((p: Product|undefined)=>!!p);
};


export const getProducts = async (): Promise<{
    popularProducts: Product[],
    assortments: ProductAssortments
}> => {
    const resp = await ApiService.getClient()
        .query({query: ASSORTMENTS_AND_POPULAR_QUERY});

    const popularProducts = resp.data.products.map(makeProduct).filter((p: Product|undefined)=>!!p);
    const assortments: ProductAssortments = resp.data.assortments.map(
        (data: getAssortmentsAndPopularProductsQuery_assortments) => ({
            image: (data.media&&data.media.length>0)?{
                src: data.media[0].file?.url,
                medium_src: data.media[0].file?.url,
                small_src: data.media[0].file?.url,
            }:{src:null, medium_src:null, small_src:null},
            name: data.texts!.title,
            products: data.searchProducts.products.map(d=>makeProduct(d as SimpleProduct)).filter((p: Product|undefined)=>!!p)
    }));

    return {
        popularProducts, assortments
    }
};
