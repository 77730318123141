import "./PageHeader.sass";
import {LanguageTranslationFunc} from "../translations";
import {useAppSelector} from "../store/store";
import {languageSelector} from "../service/common.slice";


interface PageHeaderProps {
    onBack: () => void;
    isBackAvailable: boolean;
    header: LanguageTranslationFunc;
}


export const PageHeader = (props: PageHeaderProps) => {
    const lang = useAppSelector(languageSelector);
    const back = () => {
        props.onBack();
    };
    return <div className="page-header">
        <div className="header">
            <div
                className="icon icon-left-white"
                onClick={back}
            />
            {props.header(lang)}
        </div>
    </div>;
};
