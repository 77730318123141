export enum DeliveryProviderType {
    PICKUP = "PICKUP",
    SHIPPING = "SHIPPING",
}

export enum OrderPaymentStatus {
    OPEN = "OPEN",
    PAID = "PAID",
    REFUNDED = "REFUNDED",
}

export enum OrderStatus {
    CONFIRMED = "CONFIRMED",
    FULLFILLED = "FULLFILLED",
    OPEN = "OPEN",
    PENDING = "PENDING",
    REJECTED = "REJECTED",
}

export enum OrderlyAvailability {
    AVAILABLE = "AVAILABLE",
    DISABLED = "DISABLED",
    OUT_OF_STOCK = "OUT_OF_STOCK",
}

export enum PaymentProviderType {
    CARD = "CARD",
    GENERIC = "GENERIC",
    INVOICE = "INVOICE",
}

export enum ProductStatus {
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
    DRAFT = "DRAFT",
}