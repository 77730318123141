import "./App.sass";
import React, {useEffect, useState} from "react";
import {SplashScreen} from "./components/SplashScreen";
import {useAppDispatch, useAppSelector} from "./store/store";
import {doInitialization, isCommonInitializedSelector, isOfflineSelector, themeSelector} from "./service/common.slice";
import {ServiceUnavailableScreen} from "./components/ServiceUnavailableScreen";
import {Route} from "react-router-dom";
import {ApmRoutes as Routes} from "@elastic/apm-rum-react";
import {HelpPage} from "./components/help/HelpPage";
import {MainPage} from "./components/MainPage";
import {CheckoutPage} from "./components/checkout/CheckoutPage";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import {OrderView} from "./components/checkout/OrderView";
import {PageNotFound} from "./components/PageNotFound";
import {MyOrdersPage} from "./components/checkout/MyOrdersPage";
import {PaymentError} from "./components/checkout/PaymentError";
import {SectorIsUnavailablePage} from "./components/SectorIsUnavailablePage";
import {DeliveryIsUnavailablePage} from "./components/DeliveryIsUnavailablePage";
import {ConnectionPopup} from "./common/ConnectionPopup";
import {hexToRgb} from "./service/utils";
import {createGlobalStyle, GlobalStyleComponent} from "styled-components";
import {PayoTerms} from "./components/help/PayoTerms";
import Color from "color";
import { WayFinderPage } from "./components/wayfinder/WayFinderPage";
import { useQRCodeSectorInitialize } from "./service/qrcode-initialize";
import { MySeatPage } from "./components/help/MySeatPage";
import { Terms } from "./components/help/Terms";


const App = () => {
    const theme = useAppSelector(themeSelector);
    const dispatch = useAppDispatch();
    const isCommonInitialized = useAppSelector(isCommonInitializedSelector);

    useEffect(()=>{
        dispatch(doInitialization());
    }, [dispatch]);

    const qrInitializeDone = useQRCodeSectorInitialize();

    const showSplashScreen = !(isCommonInitialized && qrInitializeDone);

    const [GlobalStyle, setGlobalStyle] = useState<GlobalStyleComponent<any, any>|undefined>(undefined);

    useEffect(()=>{
        if (!theme) return;

        const lighten = (hex: string, coef: number) => {
            let color = Color(hex);
            color = color.lighten(coef);
            return color.hex();
        }

        const colors = {
            'header-primary-color-rgb': theme.headerPrimaryColor,
            'header-secondary-color-rgb': theme.headerSecondaryColor,
            'header-tertiary-color-rgb': theme.headerTertiaryColor,
            'background-color-rgb': theme.backgroundColor,
            'primary-color-rgb': theme.primaryColor,
            'secondary-color-rgb': theme.secondaryColor,
            'tertiary-color-rgb': theme.tertiaryColor,
            'tertiary-color-lighten-rgb': lighten(theme.tertiaryColor, 1.3),
            'text-1-color-rgb': theme.textColor1,
            'text-2-color-rgb': theme.textColor2,
            'text-3-color-rgb': theme.textColor3,
        }

        const styles = Object.keys(colors)
            .filter(k => Boolean((colors as any)[k]))
            .map(k => `--${k}: ${hexToRgb((colors as any)[k])};`)
            .join('\n');

        setGlobalStyle(createGlobalStyle`
          html {
            ${styles}
          }
        `);
    }, [theme]);

    const isServiceUnavailable = useAppSelector(isOfflineSelector);
    return <>
        {GlobalStyle&&<GlobalStyle/>}
        <ToastContainer />
        <SplashScreen show={showSplashScreen}/>
        <ConnectionPopup/>
        {isServiceUnavailable&&<ServiceUnavailableScreen/>}
        <div className="page-container">
            <Routes>
                <Route path="/" element={<Main/>} />
                <Route path="help/*" element={<HelpPage/>}/>
                <Route path="checkout/*" element={<CheckoutPage/>}/>
                <Route path="order" element={<OrderView/>}/>
                <Route path="orders" element={<MyOrdersPage/>}/>
                <Route path="payment_error" element={<PaymentError/>}/>
                <Route path="my_seat" element={<MySeatPage/>}/>
                <Route path="sector_unavailable" element={<SectorIsUnavailablePage/>}/>
                <Route path="delivery_unavailable" element={<DeliveryIsUnavailablePage/>}/>
                <Route path="payo_terms" element={<PayoTerms/>}/>
                <Route path="terms" element={<Terms/>}/>
                <Route path="wayfinder" element={<WayFinderPage />} />
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
        </div>
    </>;
};

const Main = () => {
    
    return <>
        <MainPage />
    </>;
};
/* 
interface Props {
    children?: ReactNode;
}
  
interface State {
    hasError: boolean;
}
  
  class ErrorBoundary extends Component<Props, State> {
    public state: State = {
      hasError: false
    };
  
    public static getDerivedStateFromError(_: Error): State {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      console.error("Uncaught error:", error, errorInfo);
    }
  
    public render() {
      if (this.state.hasError) {
        return <h1>Sorry.. there was an error</h1>;
      }
  
      return this.props.children;
    }
  } */
  
export default App;
