import {OrderlyGeoLocationFragment} from "../../service/__generated__/OrderlyGeoLocationFragment";
import {Circle, MapContainer, Marker, TileLayer} from "react-leaflet";
import {Icon, LatLngExpression} from "leaflet";
import "./GeoLocationView.sass"
import "leaflet/dist/leaflet.css";
import {useAppSelector} from "../../store/store";
import {themeSelector} from "../../service/common.slice";
import MarkerIconSrc from "../../assets/geo/marker-icon.png";
import MarkerIconRedSrc from "../../assets/geo/marker-icon-red.png";
import {useWatchCurrentLocation} from "./geoLocationUtils";

type GeoLocationViewProps = {
    location: OrderlyGeoLocationFragment;
    showMyLocation?: boolean;
}

export const GeoLocationView = ({location, showMyLocation}: GeoLocationViewProps) => {
    const theme = useAppSelector(themeSelector);
    const [currentLocation] = useWatchCurrentLocation();

    if (!theme || !theme.geoFenceCircle) return null;

    const center: LatLngExpression = [theme.geoFenceCircle.latitude, theme.geoFenceCircle.longitude]
    const point: LatLngExpression = [location.latitude!, location.longitude!];

    const currentLocationPoint: LatLngExpression|undefined = showMyLocation&&currentLocation
        ?[currentLocation.coords.latitude!, currentLocation.coords.longitude!]:undefined;

    return (
        <MapContainer
            center={point}
            zoom={25}
            scrollWheelZoom={false}
            dragging={true}
            zoomControl={true}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Circle
                center={center}
                pathOptions={{ fillColor: 'red' }}
                radius={theme.geoFenceCircle.radiusInMeters}
            />
            <Marker position={point} icon={new Icon({
                iconUrl: MarkerIconSrc,
                iconSize: [25,45]
            })} />
            {currentLocationPoint&&
                <>
                    <Marker position={currentLocationPoint} icon={new Icon({
                        iconUrl: MarkerIconRedSrc,
                        iconSize: [25,45]
                    })} />
                    <Circle
                        center={point}
                        pathOptions={{ fillColor: 'blue' }}
                        radius={location.accuracy||15}
                    />
                </>
            }
        </MapContainer>
    )
}
