import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../store/store";
import {cartSelector, getCartQty} from "../../../service/checkout.slice";
import {displayAmount, useLg} from "../../../service/utils";
import React from "react";

export const CartSummary = () => {
    const navigate = useNavigate();
    const cart = useAppSelector(cartSelector);
    const _ = useLg();

    if (!cart || cart.items!.length === 0) return null;

    const productsCount = getCartQty(cart);
    const productsLabel = _(
        (productsCount > 1) ? "products_plural" : "products",
        { "[count]": productsCount.toString() }
    );
    const goToCart = () => {
        navigate("/checkout");
    };

    return <div className="cart-summary" onClick={goToCart}>
        <div className="checkout-icon checkout-icon-cart" />
        <div className="label">
            {productsLabel}
        </div>
        <div className="price">
            {cart?.total?.currency}&nbsp;{displayAmount(cart.total?.amount)}
        </div>
    </div>;
};
