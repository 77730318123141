import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getProducts, Product, ProductAssortments, searchProductsQ} from "./products.service";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {RootState} from "../store/store";


export interface ProductsSlice {
    productsAssortments: ProductAssortments;
    popularProducts: Product[];
}

const initialState: ProductsSlice = {
    productsAssortments: [],
    popularProducts: [],
};

export const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        setProductsAssortments: (state, action: PayloadAction<ProductAssortments>) => {
            state.productsAssortments = action.payload.filter(a=>a.products.length>0 && a.products.some(product => product.isActive));
        },
        setPopularProducts: (state, action: PayloadAction<Product[]>) => {
            state.popularProducts = action.payload;
        },
    }
});

export const loadProducts = createAsyncThunk(
    "loadProducts",
    async (_: void, {dispatch}) => {
        const {assortments, popularProducts} = await getProducts();
        dispatch(productsSlice.actions.setProductsAssortments(assortments));
        dispatch(productsSlice.actions.setPopularProducts(popularProducts));
    }
);

export const productsAssortmentsSelector = (state: RootState): ProductAssortments => state.products.productsAssortments;
export const popularProductsSelector = (state: RootState) => state.products.popularProducts;

export const searchProducts = createAsyncThunk(
    "searchProducts",
    async (term: string) => {
        return await searchProductsQ(term);
    }
);
