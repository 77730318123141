import { cartSelector } from "../../service/checkout.slice";
import { selectedSectorSelector } from "../../service/common.slice";
import { useAppSelector } from "../../store/store";
import "./MySeatPage.sass";
import { useNavigate } from "react-router-dom";
import { useLg } from "../../service/utils";
import orderlyLogo from "../../assets/common/images/logo.svg";

export const MySeatPage = () => {
    const sector = useAppSelector(selectedSectorSelector);
    const cart = useAppSelector(cartSelector);
    const seatNumber = cart?.orderlySeatNumber;
    const navigate = useNavigate();
    const _ = useLg();
    const next = () => {
        navigate("/");
    };
    const isTableService = cart?.orderlyIsTableservice;

    return <div className="bb-container my-seat-page">
        <div className="welcome-header">
            <div className="line-1">
                {_("Hallo!")}
            </div>
            <div className="line-2">
                {_("Willkommen bei")}
            </div>
            <div className="line-3">
                <img src={orderlyLogo} alt="logo" />
            </div>
        </div>
        {sector&&<div className="items">
            {
                (isTableService && seatNumber)
                ? <>
                    <div className="item">
                        <div className="label">{_("Deine Tischnummer")}</div>
                        <div className="name">
                            {seatNumber}
                        </div>
                    </div>
                </>
                : <>
                    <div className="item">
                        <div className="label">{_("Dein Sektor")}</div>
                        <div className="name">
                            {sector?.title}
                        </div>
                    </div>
                    {seatNumber&&<div className="item">
                    <div className="label">{_("Deine Reihe & Sitzplatz")}</div>
                        <div className="name">
                            {seatNumber}
                        </div>
                    </div>}
                </>
            }
        </div>}
        <div className="bb-button-container">
            <button className="btn btn-primary big-primary"
                    onClick={next}
            >
                {_("Weiter und Bestellen")}
            </button>
        </div>
    </div>
}
