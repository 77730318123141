import "./CheckoutPage.sass";
import React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {CheckoutStep1} from "./checkout-steps/CheckoutStep1";
import {PageHeader} from "../../common/PageHeader";
import {__, LanguageTranslationFunc} from "../../translations";
import {CheckoutStep2} from "./checkout-steps/CheckoutStep2";
import {CheckoutStep3} from "./checkout-steps/CheckoutStep3";
import {PageNotFound} from "../PageNotFound";
import {isCartEmpty, useLg} from "../../service/utils";
import {cartSelector} from "../../service/checkout.slice";
import {OrderIsEmptyBox} from "./OrderIsEmptyBox";
import {useAppSelector} from "../../store/store";

export const CheckoutPage = () => {
    const _ = useLg();
    const navigate = useNavigate();
    const cart = useAppSelector(cartSelector);
    const back = () => {
        navigate("/");
    };

    if (isCartEmpty(cart)) {
        return <div className="bb-container">
            <CheckoutHeader
                header={__("Warenkorb")}
                back={()=>{navigate("/")}}
            />
            <div className="checkout-contents">
                <OrderIsEmptyBox label={__("Dein Warenkorb ist leer")}/>
            </div>
            <div className="bb-button-container">
                <button className="btn btn-primary big-primary"
                        onClick={back}
                >
                    {_("Zu 1-2-eat")}
                </button>
            </div>
        </div>;
    }

    return <Routes>
        <Route path="/" element={<CheckoutStep1/>}/>
        <Route path="/step2" element={<CheckoutStep2/>}/>
        <Route path="/step3" element={<CheckoutStep3/>}/>
        <Route
            path="*"
            element={<PageNotFound />}
        />
    </Routes>;
};

interface CheckoutHeaderProps {
    header: LanguageTranslationFunc;
    back: () => void;
}

export const CheckoutHeader = (props: CheckoutHeaderProps) => {
    return <PageHeader
        onBack={props.back}
        isBackAvailable={true}
        header={props.header}
    />
};
