import React from 'react';
import App from './App';
import "./service/apm.service";
import "bootstrap/dist/css/bootstrap.min.css";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {BrowserRouter} from "react-router-dom";
import {ApiService} from "./service/service";
import { createRoot } from 'react-dom/client';

ApiService.init({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || "https://orderly.xn--nd-fka.live/graphql"
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
);

