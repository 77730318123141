import {ApolloClient, ApolloLink, from, createHttpLink, InMemoryCache} from "@apollo/client";
import {getStoredLanguage} from "./common.slice";
import { onError } from "@apollo/client/link/error";
import apm from "./apm.service";

const errorLink = onError(({ graphQLErrors, networkError, operation, }) => {
  const { headers } = operation.getContext();
  if (graphQLErrors) {
    graphQLErrors.forEach(({ originalError, message, locations, path }) => {
      const error = new Error(`Message: ${message}, Location: ${locations?.toString()}, Path: ${path}, Headers: ${JSON.stringify(headers)}, Variables: ${JSON.stringify(operation.variables)}`);
      error.name = 'GraphQL Error';
      error.stack = originalError?.stack;
      console.error(error);
      apm?.captureError(error);
    });
  }
      
  if (networkError) { 
    const error = new Error(`${networkError.message}, Headers: ${JSON.stringify(headers)}, Variables: ${JSON.stringify(operation.variables)}`);
    error.name = 'Network Error';
    error.stack = networkError.stack;
    console.error(error);
    apm?.captureError(error);
  }
});

const apmStartLink = new ApolloLink((operation, forward) => {
  const apmTransaction = apm?.startTransaction(operation.operationName, 'graphql');
  operation.setContext({ apmTransaction });
  return forward(operation);
});
  
const apmEndLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const apmTransaction = operation.getContext().apmTransaction;
    apmTransaction?.end();
    return data;
  })
});
  
export interface ApolloServiceOptions {
    uri: string;
}

export class ApiService {
    static client: ApolloClient<any>;

    static init(options: ApolloServiceOptions) {
        const uri = (options.uri.indexOf('http://') === 0 || options.uri.indexOf('https://') === 0) ? options.uri : `${document.location.origin}${options.uri}`;

        const httpLink = createHttpLink({
            uri,
            credentials:"include"//"omit"  //TODO uncomment
        });

        const setAuthLink = new ApolloLink((operation, forward) => {
            operation.setContext(({ headers = {} }) => ({
                headers: {
                    ...headers,
                    "Accept-Language": getStoredLanguage(),
                    //"Authorization": `Bearer ${this.getToken()}`//TODO comment
                }
            }));
            return forward(operation);
        });

        const link = from([
          setAuthLink,
          apmStartLink,
          errorLink,
          apmEndLink,
          httpLink
        ]);
        
        this.client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
            defaultOptions: {
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            }
        });
    }

    static getClient(): ApolloClient<any> {
        return this.client;
    }

    static getToken() {
        return localStorage.getItem("authtoken");
    }

    static updateToken(token: string) {
        localStorage.setItem("authtoken", token);
    }

    static removeToken() {
        localStorage.removeItem("authtoken");
    }
}

