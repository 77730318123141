import React, { useEffect, useRef } from "react";
import "./MainPageHeader.sass";
import { useAppDispatch, useAppSelector } from "../store/store";
import { commonSlice, selectedSectorSelector, themeSelector } from "../service/common.slice";
import { LeftMenu } from "./LeftMenu";
import { LanguageSelector } from "./LanguageSelector";
import { Search } from "./Search";
import { hexToRgb } from "../service/utils";
import orderlyLogo from "../assets/common/images/logo.svg";

export const MainPageHeader = ({ onSectorSelectorOpen }: { onSectorSelectorOpen?: () => void }) => {
    const theme = useAppSelector(themeSelector);

    const dispatch = useAppDispatch();

    const topContainerRef = useRef<HTMLDivElement>(null),
        wrapperContainerRef = useRef<HTMLDivElement>(null);

    const sector = useAppSelector(selectedSectorSelector);

    const toggleMenu = () => {
        dispatch(commonSlice.actions.toggleLeftMenu());
    };
    const openSearch = () => {
        dispatch(commonSlice.actions.openSearch());
    };

    useEffect(() => {
        const headerPrimaryColor = theme?.headerPrimaryColor ? hexToRgb(theme?.headerPrimaryColor) : "255,255,255";

        const scrollHandler = () => {
            if (!topContainerRef.current) return;
            const topContainer = topContainerRef.current!,
                wrapperContainer = wrapperContainerRef.current!;
            const scrollY = window.scrollY,
                threshold = 300,
                opacity = Math.max(0, threshold - scrollY) / threshold,
                bgOpacity = 1 - opacity;
            topContainer.style.background = `rgba(${headerPrimaryColor}, ${bgOpacity})`;
            wrapperContainer.style.opacity = opacity.toString();
            wrapperContainer.style.display = (opacity === 0) ? "none" : "block";
        };
        scrollHandler();
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [theme]);

    return (
        <>
            <LeftMenu />
            <LanguageSelector />
            <Search />
            <div className="main-page-header-wrapper">
                <div className="main-page-header">
                    <div className="top-container" ref={topContainerRef}>
                        <div className="container">
                            <div className="title row">
                                <div className="col-2 left icons">
                                    <div
                                        className="icon icon-menu"
                                        onClick={toggleMenu}
                                    />
                                </div>
                                <div className="col-8 orderly-main-label">
                                    <img src={orderlyLogo} alt="logo" />
                                </div>
                                <div className="col-2 right icons">
                                    {sector && <div
                                        className="icon icon-search"
                                        onClick={openSearch}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper-container" ref={wrapperContainerRef}>

                        <>
                            <div className="main-label">
                                {theme?.title}
                            </div>
                            <div className="sub-label">
                                {theme?.info}
                            </div>
                        </>

                    </div>
                </div>
            </div>
        </>
    )
};
