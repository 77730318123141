import * as React from "react";
import "./HelpPage.sass";
import {Route, Routes, useNavigate} from "react-router-dom";
import {PageHeader} from "../../common/PageHeader";
import {LanguageTranslationFunc} from "../../translations";
import {HelpRoot} from "./HelpRoot";
import {AGBsPage} from "./AGBsPage";
import {FaqPage} from "./FaqPage";
import {ProblemPage} from "./ProblemPage";
import { ActivateGPSPage } from "./ActivateGPSPage";

interface HelpPageHeaderProps {
    header: LanguageTranslationFunc;
    isRoot: boolean;
}

export const HelpPageHeader = (props: HelpPageHeaderProps) => {
    const navigate = useNavigate();
    const back = () => {
        if (props.isRoot) {
            navigate("/");
        } else {
            navigate("/help");
        }
    };
    return <PageHeader
        header={props.header}
        onBack={back}
        isBackAvailable={!props.isRoot}
    />
};

export const HelpPage = () => {
    return <Routes>
        <Route path="/" element={<HelpRoot/>}/>
        <Route path="/agbs" element={<AGBsPage/>}/>
        <Route path="/faq" element={<FaqPage/>}/>
        <Route path="/problem" element={<ProblemPage/>}/>
        <Route path="/activate_gps" element={<ActivateGPSPage/>}/>
    </Routes>;
};
