import {useAppSelector} from "../../store/store";
import {languageSelector, themeSelector, useTimeToDeliverySeconds} from "../../service/common.slice";
import React, {useEffect, useState} from "react";
import {
    dateToString, getOrderColorAndStatus, getOrderedTime,
    getOrderSector,
    getQueryParams,
    getShortOrderNumber, isGeoLocationDeliverySet,
    isOrderDelivery,
    useLg,
    useLoading
} from "../../service/utils";
import {CreatedOrderFragment} from "../../service/__generated__/CreatedOrderFragment";
import {getOrderById, markOrderAsDelivered, sendNotFoundNotification} from "../../service/checkout.service";
import {OrderIsEmptyBox} from "../checkout/OrderIsEmptyBox";
import {__} from "../../translations";
import {PageNotFound} from "../PageNotFound";
import "./WayFinderPage.sass";
import {CheckoutIconPoint} from "../checkout/Icons";
import {GeoLocationView} from "../geo-location/GeoLocationView";
import {OrderStatus} from "../../globalTypes";

export const WayFinderPage = () => {
    const theme = useAppSelector(themeSelector);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [loading, wl] = useLoading();
    const _ = useLg();
    const lang = useAppSelector(languageSelector);

    const [order, setOrder] = useState<CreatedOrderFragment|undefined>(undefined);
    const timeToDelivery = useTimeToDeliverySeconds();

    const getOrder = async () => {
        const params = getQueryParams();

        const orderId = params["order_id"] || "";
        if (!orderId) {
            return null;
        }

        return await getOrderById(orderId);
    };
    const loadOrder = async () => {
        setOrder(undefined);
        const order = await wl.load(getOrder());
        if (!order) {
            setNotFound(true);
            return;
        }
        setOrder(order);
    };
    useEffect(() => {
        loadOrder();
    }, []); // eslint-disable-line

    if (notFound) {
        return <PageNotFound/>;
    }

    if (loading || !order) {
        return <>
            <div className="page-header">
                <div className="header">
                </div>
            </div>
            <div className="checkout-contents opacity-blink">
                <OrderIsEmptyBox label={__("Loading")}/>
            </div>
        </>;
    }

    const isDelivery = isOrderDelivery(order);
    const sector = getOrderSector(order);
    const isReservation = Boolean(order.orderlyReservationDate);

    const actionsAllowed = (
        order.status === OrderStatus.CONFIRMED
        && isDelivery
    )

    const markDelivered = async () => {
        if (!actionsAllowed)return;
        const upd = await wl.load(markOrderAsDelivered(order._id));
        if (upd) setOrder(upd);
    }
    const cannotFindClient = async () => {
        if (!actionsAllowed)return;
        const upd = await wl.load(sendNotFoundNotification(order._id));
        if (upd) setOrder(upd);
    }
    const {status} = getOrderColorAndStatus(order);


    return <div className={"checkout-contents"}>
        <div className="order-number-container">
            <div className="number-comment">{_("Bestellnr.")}</div>
            <div className="number">
                {(isReservation?"VB-":"")+getShortOrderNumber(order?.orderNumber??"")}
            </div>
            {isReservation?<div className="number">
                    {dateToString(new Date(order.orderlyReservationDate))}
                </div> :
                <div className="number">
                    {getOrderedTime(order,timeToDelivery )}
                </div>}
            <div className="info-comment">
                {isDelivery?_("Lieferung an:"):order?.orderlyIsTableservice ?_("Tischservice bei:"): _("Abholung bei:")}
            </div>
            <div className="info">
                <div className="checkout-icon">
                    <CheckoutIconPoint />
                </div>
                <div><strong>{theme?.title??""}</strong></div>
                <div><strong>{sector}</strong></div>
                {isDelivery&&!isGeoLocationDeliverySet(order)&&
                    (<div>
                        {_("Platz")}&nbsp;
                        {order.orderlySeatNumber}
                    </div>)
                }
            </div>
            <div>
                Status {status(lang)}
            </div>
            <div>
                {isDelivery
                    &&isGeoLocationDeliverySet(order)
                    &&actionsAllowed
                    &&<GeoLocationView location={order.orderlyGeoLocation!} showMyLocation={true} />}
            </div>
        </div>

        {actionsAllowed&&<div className={`wayfinder buttons with-loading-overlay ${loading?'loading':''}`}>
            <div className="loading-overlay">
                <div className="icon icon-refresh" />
            </div>

            <button className="btn btn-primary big-primary"
                onClick={markDelivered}
            >
                Als geliefert markieren
            </button>
            <button className="btn btn-primary big-primary"
                onClick={cannotFindClient}
            >
                Ich kann den Kunden nicht finden
            </button>
            <div className="contact-user-section">
                <div className="label">
                    {_("Mobiltelefon")}
                </div>
                <div className="phone-number">
                    <a href={`tel:${order.contact?.telNumber}`}>
                        {order.contact?.telNumber}
                    </a>
                </div>
            </div>
        </div>}
    </div>
}