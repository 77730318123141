import * as React from "react";
import {useNavigate} from "react-router-dom";
import "./PageUnavailable.sass";
import {__, TranslatedString} from "../translations";
import {useAppDispatch, useAppSelector} from "../store/store";
import {refreshSectors, sectorsSelector, selectedSectorSelector} from "../service/common.slice";
import notFound from "../assets/common/images/not-found.svg";
import {SectorSelectorDownPopup} from "./SectorSelectorDownPopup";
import {useEffect, useState} from "react";
import {isSectorActive, useLg, withLoading} from "../service/utils";


export const SectorIsUnavailablePage = () => {
    const [showSelectSector, setShowSelectSector] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const _ = useLg();
    const sector = useAppSelector(selectedSectorSelector);
    const sectors = useAppSelector(sectorsSelector);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);
    const atLeastOneSectorAvailable = (sectors?.length || 0) > 0 && (sectors||[]).some(isSectorActive);

    const openSectorSelector = async () => {
        await wl.load(dispatch(refreshSectors()));
        setShowSelectSector(true);
    };

    const closeSectorSelector = () => {
        setShowSelectSector(false);
    };

    const gotoHomePage = () => {
        navigate("/");
    };

    useEffect(()=>{
        if (sector && isSectorActive(sector)) {
            navigate("/");
        }
    }, [sector]); // eslint-disable-line

    return <>
        <div className="page-unavailable bb-container">
            <div className="img">
                <img src={notFound} alt="Not found"/>
            </div>
            <h2 className="smaller">
                {_("[sector] ist momentan leider nicht verfügbar", {
                    "[sector]": sector?.title??""
                })}
            </h2>
            <div className="comment">
                <TranslatedString str={__("Es tut uns leid! \n\n[sector] kann gerade keine Bestellungen entgegennehmen. \n\nBitte versuchen Sie es später noch einmal",
                    {"[sector]":sector?.title??"Kiosk"})}/>
            </div>
            <div className="bb-button-container">
                {atLeastOneSectorAvailable &&
                <button className="btn btn-primary big-primary"
                        disabled={loading}
                        onClick={openSectorSelector}
                >
                    {_("Ort auswählen")}
                </button>
                }
                {!atLeastOneSectorAvailable&&
                <button className="btn btn-primary big-primary"
                        disabled={loading}
                        onClick={gotoHomePage}
                >
                    {_("Zur Homeseite")}
                </button>
                }
            </div>
        </div>
        <SectorSelectorDownPopup
            showSelectSector={showSelectSector}
            onClose={closeSectorSelector}
        />
    </>;
};