import * as React from "react";
import {PageHeader} from "../../common/PageHeader";
import {useNavigate} from "react-router-dom";
import {__} from "../../translations";


export const AGBsPage = () => {
    const navigate = useNavigate();
    const back = () => {
        navigate("/");
    };

    return <>
        <PageHeader onBack={back} isBackAvailable={true} header={__("AGBs")}/>
        <div className="help-content">
            <h3>1. Angebot</h3>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis odio sed pharetra elementum. Consequat amet sed laoreet odio malesuada id.
            </p>
            <p>
                Aliquet malesuada nisl quis faucibus sit duis phasellus sit nisi. Massa mattis amet, auctor morbi aenean dui, volutpat. Netus ante pharetra sed diam donec lectus.
            </p>
            <h3>1. Angebot</h3>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis odio sed pharetra elementum. Consequat amet sed laoreet odio malesuada id.
            </p>
            <p>
                Aliquet malesuada nisl quis faucibus sit duis phasellus sit nisi. Massa mattis amet, auctor morbi aenean dui, volutpat. Netus ante pharetra sed diam donec lectus.
            </p>
            <h3>1. Angebot</h3>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis odio sed pharetra elementum. Consequat amet sed laoreet odio malesuada id.
            </p>
            <p>
                Aliquet malesuada nisl quis faucibus sit duis phasellus sit nisi. Massa mattis amet, auctor morbi aenean dui, volutpat. Netus ante pharetra sed diam donec lectus.
            </p>
        </div>
    </>
};
