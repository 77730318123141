import {useEffect, useState} from "react";
import "./SplashScreen.sass";


export const SplashScreen = ({show}: {show:boolean}) => {
    const [_show, _setShow] = useState<boolean>(true);
    const [hiding, setHiding] = useState<boolean>(false);

    useEffect(()=>{
        if (show) return;
        setHiding(true);
        setTimeout(()=>{
            _setShow(false);
        }, 400);
    }, [show]);

    if (!_show) {
        return null;
    }
    return <div className={"splash-screen " + (!hiding?"show":"")}>
        <div className="upper-background"/>
        <div className="label">1-2-eat</div>
    </div>;
};
