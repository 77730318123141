import translations from './translations.json';
import {useSelector} from "react-redux";
import {languageSelector} from "./service/common.slice";
import { OrderlyTheme } from './service/common.service';
import {useLg} from "./service/utils";

export enum Language {
    De = 'de',
    En = 'en',
    Fr = 'fr'
}

export const Languages: Language[] = [
    Language.De, Language.En, Language.Fr
];

type TranslationFunc = (key: string, replacements?: Replacements) => string;
export type LanguageTranslationFunc = (lang: Language) => string;

export type TranslatedStringT = string|LanguageTranslationFunc;

export function lg(lang: Language): TranslationFunc {
    return (key, replacements?: Replacements) => {
        return translate(lang, key, replacements);
    };
}

export interface Replacements {
    [key: string]: string;
}

export function __(key: string, replacements?: Replacements): LanguageTranslationFunc {
    return lang => {
        return translate(lang, key, replacements);
    };
}

function translate(lang: Language, key: string, replacements?: Replacements): string {
    let str = key;
    if (key in translations) {
        // @ts-ignore
        if (lang in translations[key]) {
            // @ts-ignore
            str = translations[key][lang];
        }
    }
    if (replacements) {
        for (const k in replacements) {
            str = str.replace(k, replacements[k]);
        }
    }
    return str;
}

export function applyLocalizations(localizations?: OrderlyTheme['localizations']) {
    if (!localizations) return;
    for (const key in localizations) {
        // @ts-ignore
        translations[key] = localizations[key];
    }
}

export const Translate = (props: {lgKey: string, replacements?: Replacements}) => {
    const _ = useLg();
    return <span dangerouslySetInnerHTML={{__html: _(props.lgKey, props.replacements)}}/>;
};

export const TranslatedString = (props: {str: TranslatedStringT}) => {
    const lang = useSelector(languageSelector);
    return <span dangerouslySetInnerHTML={{__html: translateString(lang, props.str)}}/>;
};

export function translateString(lang: Language, str: TranslatedStringT): string {
    if (typeof str == "function") {
        return str(lang);
    }
    return str;
}
