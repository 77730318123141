import { CheckoutHeader } from "../CheckoutPage";
import { __ } from "../../../translations";
import { CheckoutSteps } from "./CheckoutSteps";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
    ensureCorrectDelivery,
    isProviderDelivery,
    selectedDeliveryProviderSelector
} from "../../../service/common.slice";
import { DownStick } from "../../../common/DownStick";
import {
    cartSelector,
    CheckoutStep, getCartStatus, navigateByCartStatus, refreshCart,
} from "../../../service/checkout.slice";
import { useNavigate } from "react-router-dom";
import { OrderTotal } from "../OrderTotal";
import { DeliveryModeSelector } from "../DeliveryModeSelector";
import { OrderItems } from "../OrderItems";
import {hasUnavailableProductsInCart, isGeoLocationDeliverySelected, useLg, useLoading} from "../../../service/utils";
import { useEffect } from "react";
import { toast } from "react-toastify";


export const CheckoutStep1 = () => {
    const _ = useLg();
    const dispatch = useAppDispatch();
    const cart = useAppSelector(cartSelector)!;
    const navigate = useNavigate();
    const selectedDeliveryProvider = useAppSelector(selectedDeliveryProviderSelector);
    const isDelivery = selectedDeliveryProvider ? isProviderDelivery(selectedDeliveryProvider) : false;
    const isGeoLocationDelivery = isDelivery && isGeoLocationDeliverySelected(cart);
    const [loading, wl] = useLoading();

    const hasUnavailableProducts = hasUnavailableProductsInCart(cart);

    useEffect(() => {
        dispatch(ensureCorrectDelivery());
        dispatch(refreshCart());
    }, []); // eslint-disable-line

    const next = async () => {
        if (hasUnavailableProducts) return;

        if (!selectedDeliveryProvider) {
            await showNothingSelectedToast();
            return;
        }

        await wl.load(dispatch(ensureCorrectDelivery()));
        const cartStatus = await wl.load(dispatch(getCartStatus()).unwrap());
        navigateByCartStatus(navigate, cartStatus, "/checkout/step2");
    };

    const showNothingSelectedToast = async () => {
        toast.error(_('Du musst noch auswählen, ob du dein Essen abholen oder liefern lassen möchtest'), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    return <>
        <CheckoutHeader
            header={__("Warenkorb")}
            back={() => { navigate("/") }}
        />
        <CheckoutSteps step={CheckoutStep.Cart} />

        <DeliveryModeSelector />

        <div className="checkout-contents">
            <div className="box cart">
                {hasUnavailableProducts && <div className="product-danger-container">
                    <div className="checkout-icon checkout-icon-danger-circle-red" />
                    <div>
                        {_("Wir mussten einige Produkte aus deinem Warenkorb entfernen. Sie sind leider nicht mehr verfügbar. ")}
                    </div>
                </div>}
                <OrderItems
                    orderItems={cart.items!}
                    readOnly={false}
                />
                <OrderTotal
                    cart={cart}
                    displayTipSection={false}
                />
            </div>
        </div>
        <div className="cart-sticky-container">
            <DownStick>
                <button
                    className="btn btn-primary big-primary"
                    onClick={next}
                    disabled={hasUnavailableProducts || loading}
                >
                    {isDelivery
                        ? isGeoLocationDelivery
                            ? _("Weiter")
                            : _("Sitzplatz")
                        : _("Kontaktdaten")
                    }
                </button>
            </DownStick>
        </div>
    </>;
};
