import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../store/store";
import { isCommonInitializedSelector, sectorsSelector, setDeliveryProvider, setSector } from "./common.slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getQueryParams, isSectorAvailable } from "./utils";
import { useNavigate } from "react-router-dom";
import { setOrderDeliveryMode, setOrderSeatNumber } from "./checkout.slice";


export const useQRCodeSectorInitialize = () => {
    const [initDone, setInitDone] = useState(false);
    const dispatch = useAppDispatch();
    const appInitialized = useAppSelector(isCommonInitializedSelector);
    const navigate = useNavigate();

    useEffect(()=>{
        if (!appInitialized) return
        (async ()=>{
            try {
                const queryParams = getQueryParams();
                if (queryParams['sector']) {
                    const sector = await dispatch(findActiveSectorByName(queryParams['sector'])).unwrap();
                    
                    if (!sector) {
                        navigate("/sector_unavailable");
                        console.log('god')
                        return;
                    }
                    await dispatch(setSector(sector));

                    const deliveryProviders = sector.supportedDeliveryProviders.filter(p=>p.isActive)
                        
                    if (queryParams['seat']) {
                        const seat = queryParams['seat'];
                        const seatAndRow = seat.indexOf("/") >= 0;
                        await dispatch(setOrderSeatNumber({seat}));
                        if (seatAndRow) { // stadium delivery
                            const provider = deliveryProviders.find(dp=>dp._id==="runner");
                            if (provider) {
                                await dispatch(setDeliveryProvider(provider));
                                await dispatch(setOrderDeliveryMode({isTableservice:false, geoLocationEnabled:false}));
                            }
                        } else { // tableservice
                            const provider = deliveryProviders.find(dp=>dp._id!=="runner");
                            if (provider) {
                                await dispatch(setDeliveryProvider(provider));
                                await dispatch(setOrderDeliveryMode({isTableservice:true, geoLocationEnabled:false}));
                            }
                        }
                    } else {
                        await dispatch(setOrderSeatNumber({seat: ""}));
                    }
                    navigate("/my_seat");
                }
            } finally {
                setInitDone(true)
            }
        })();
    }, [appInitialized, dispatch, navigate]);

    return initDone;
}


const findActiveSectorByName = createAsyncThunk(
    "findActiveSectorByName",
    async (name: string, {getState, dispatch}) => {
        name = name.trim().toLocaleLowerCase();
        const state = getState() as RootState;
        const sectors = sectorsSelector(state)?.filter(isSectorAvailable);
        return sectors?.find(s=>s.title.trim().toLocaleLowerCase()===name);
    }
)
