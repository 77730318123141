import {DownPopup} from "./DownPopup";
import "./ConnectionPopup.sass";
import {__, TranslatedString} from "../translations";
import React from "react";
import {Detector} from "react-detect-offline";
import {useLg} from "../service/utils";


export const ConnectionPopup = () => {
    const _ = useLg();

    const close = () => {
        reload();
    };

    const reload = () => {
        window.location.reload();
    };

    // @ts-ignore-next-line
    return <Detector polling={false} render={({online}) => (
        <DownPopup show={!online} onClose={close}>
            <div className="no-connection">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 5L26 27" stroke="#5A636A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.3877 7.18757C14.2528 7.06162 15.126 6.99895 16.0002 7.00007C20.8168 6.9871 25.4421 8.88398 28.8627 12.2751" stroke="#5A636A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.1377 12.2748C4.8332 10.5959 6.8421 9.26657 9.0502 8.3623" stroke="#5A636A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M19.0127 13.375C21.1307 13.8996 23.0689 14.9831 24.6252 16.5125" stroke="#5A636A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.375 16.5125C9.06246 14.8614 11.1882 13.7291 13.5 13.25" stroke="#5A636A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.6123 20.7626C12.7396 19.6863 14.2227 19.0613 15.7803 19.006C17.3378 18.9507 18.8615 19.469 20.0623 20.4626" stroke="#5A636A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 26.5C16.8284 26.5 17.5 25.8284 17.5 25C17.5 24.1716 16.8284 23.5 16 23.5C15.1716 23.5 14.5 24.1716 14.5 25C14.5 25.8284 15.1716 26.5 16 26.5Z" fill="#5A636A"/>
                </svg>
                <h3>{_("Keine Verbindung")}</h3>
                <p>
                    <TranslatedString str={__("Es sieht so aus als hättest du gerade")} />
                </p>
                <p>
                    <button className="btn btn-primary big-primary"
                            onClick={reload}
                    >
                        {_("Neu laden")}
                    </button>
                </p>
            </div>
        </DownPopup>
    )}/>
};

