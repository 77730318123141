import { Translate } from "../../translations";
import { CartFragment } from "../../service/__generated__/CartFragment";
import { dateToString, displayAmount, getOrderDiscounts, getOrderSubTotal, useLg } from "../../service/utils";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import "./CheckoutPage.sass";
import { dispatchCartFragment, setTipPercentage } from "../../service/checkout.slice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { themeSelector } from "../../service/common.slice";

// Utility function to round to nearest value divisible by 0.05
const roundToNearestFiveCents = (amount: number) => {
    const rounded = (Math.round((amount / 100) / 0.05) * 0.05).toFixed(2);
    return rounded;
};

const shouldDisplayTipSection = (tipPercentages: number[] | null, displayTipSection: boolean) => {
    if (!displayTipSection) {
        return false;
    }
    if (!tipPercentages) {
        return false;
    }
    if (tipPercentages.some(tip => tip === null || tip === undefined)) {
        return false;
    }
    if (tipPercentages.length === 0 || (tipPercentages.length === 1 && tipPercentages.includes(0))) {
        return false;
    }
    return true;
};


export const OrderTotal = (
    { cart, displayTipSection }: { cart: CartFragment, displayTipSection: boolean }
) => {
    const _ = useLg();
    const theme = useAppSelector(themeSelector);
    const discounts = getOrderDiscounts(cart);
    const surcharges = discounts.filter(ds => ds.amount > 0);
    const deductions = discounts.filter(ds => ds.amount < 0);
    const dispatch = useAppDispatch();
    const hasDepotFee = discounts.filter(ds => ds.id === "ch.orderly.discount.depot").length > 0;
    const isTableServiceSelected = cart.orderlyIsTableservice;

    // Create a copy of the tipPercentages array before sorting and remove any 0 values
    const tipPercentages = (theme?.tipPercentages ? [...theme.tipPercentages].filter(tip => tip !== 0).sort((a, b) => a - b) : []);

    const handleTipChange = async (tip: number) => {
        const calculatedTipPercentage = tip * 100;
        await dispatch(setTipPercentage({ tipPercentage: calculatedTipPercentage }));
    };

    const [alignment, setAlignment] = React.useState<string | null>('left');

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        // Prevent deselecting by checking if newAlignment is null
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            if (newAlignment === 'left') {
                handleTipChange(0);
            } else {
                const percentage = parseFloat(newAlignment || '0');
                handleTipChange(percentage / 100);
            }
        }
    };


    return <>
        <div className="price-extra">
            <div className="item">
                <div className="label">
                    {_("Zwischensumme")}
                </div>
                <div className="value">
                    {cart?.goods?.currency}&nbsp;
                    {displayAmount(getOrderSubTotal(cart))}
                </div>
            </div>

            {/* Horizontal divider */}
            <div style={{ borderTop: '2px solid lightgrey', margin: '10px 0' }}></div>

            {shouldDisplayTipSection(tipPercentages, displayTipSection) && (
                <>
                    {/* Tip section */}
                    <div className="tip-section" style={{ marginBottom: '10px' }}>
                        <div className="label">
                            {_("Möchtest du dem Service ein Trinkgeld geben?")}
                        </div>
                    </div>

                    <ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        color="primary"
                        aria-label="text alignment"
                        style={{ height: '55px', width: '100%', marginBottom: "10px" }}
                        className="buttons-tip"
                        defaultValue="left"
                    >
                        <ToggleButton
                            value="left"
                            aria-label="left aligned"
                            style={{ flex: 1, borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px', borderColor: '#F3F3F3' }}
                        >
                            <div style={{ textAlign: 'center', margin: '10px 0' }}>
                                <div style={{ fontSize: '16px', textTransform: 'none' }}>Kein Trinkgeld</div>
                            </div>
                        </ToggleButton>

                        {tipPercentages.map((percentage, index) => {
                            const isLast = index === tipPercentages.length - 1;
                            return (
                                <ToggleButton
                                    key={index}
                                    value={percentage.toString()}
                                    aria-label={`${percentage}%`}
                                    style={{
                                        flex: 1,
                                        borderTopRightRadius: isLast ? '6px' : '0px',
                                        borderBottomRightRadius: isLast ? '6px' : '0px',
                                        borderColor: '#F3F3F3',
                                        fontFamily: 'Arial'
                                    }}
                                >
                                    <div className="tip-options" style={{ textAlign: 'center', margin: '10px 0' }}>
                                        <div style={{ fontSize: '16px', marginBottom: '-4px', fontFamily: 'Arial'}}>{percentage}%</div> {/* Decrease the margin-bottom by 4px */}
                                        <div style={{ fontSize: '12px', fontFamily: 'Arial' }}>CHF {roundToNearestFiveCents(getOrderSubTotal(cart) * (percentage / 100))}</div>
                                    </div>
                                </ToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>

                    {/* Horizontal divider */}
                    <div style={{ borderTop: '2px solid lightgrey', margin: '10px 0' }}></div>
                </>
            )}

            {surcharges.map((ds, i) => <div className="item" key={i}>
                <div className="label">
                    {ds.label}
                </div>
                <div className={"value " + (ds.id === "ch.orderly.discount.depot" ? "focus" : "")}>
                    {ds.currency}&nbsp;{displayAmount(ds.amount)}
                </div>
            </div>)}
            {(cart?.delivery?.amount || 0) > 0 && <div className="item">
                <div className="label">
                    {_("Lieferung am Platz")}
                </div>
                <div className="value">
                    {cart?.delivery?.currency}&nbsp;
                    {displayAmount(cart?.delivery?.amount)}
                </div>
            </div>}
            {(cart?.paymentTotal?.amount || 0) > 0 && <div className="item">
                <div className="label">
                    {_("Mobil Service")}
                </div>
                <div className="value">
                    {cart?.paymentTotal?.currency}&nbsp;
                    {displayAmount(cart?.paymentTotal?.amount)}
                </div>
            </div>}
            {deductions.map((ds, i) => <div className="item" key={i}>
                <div className="label">
                    {ds.label}
                </div>
                <div className={"value " + (ds.id === "ch.orderly.discount.depot" ? "focus" : "")}>
                    {ds.currency}&nbsp;{displayAmount(ds.amount)}
                </div>
            </div>)}
            {cart?.orderlyReservationDate && <div className="item">
                <div className="label">
                    {!isTableServiceSelected ? _("Abholzeitpunkt") : _("Tisch ab")}
                </div>
                <div className="value">
                    {dateToString(new Date(cart?.orderlyReservationDate))}
                </div>
            </div>}
            {hasDepotFee && <div className="item-saq">
                <div className="icon icon-leaf" />
                <div className="comment">
                    <Translate
                        lgKey={"Wir kümmern uns um die Umweltschutz."}
                    />
                </div>
            </div>}
        </div>
        <div className="total">
            <div className="label">
                {_("Total")}
                <div className="comment">{_("(Inkl. MwSt.)")}</div>
            </div>
            <div className="value">
                {cart?.total?.currency}&nbsp;
                {displayAmount(cart?.total?.amount)}
            </div>
        </div>
    </>;
};