import * as React from "react";
import {__} from "../../translations";
import {useAppSelector} from "../../store/store";
import {themeSelector} from "../../service/common.slice";
import "./ProblemPage.sass";
import {PageHeader} from "../../common/PageHeader";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getOrderById, reportProblem} from "../../service/checkout.service";
import "../checkout/CheckoutPage.sass";
import {getOrderSector, getQueryParams, useLg, withLoading} from "../../service/utils";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import { CheckInput } from "../../common/contols/CheckInput";


export const ProblemPage = () => {
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);
    const theme = useAppSelector(themeSelector);
    const _ = useLg();

    const [orderId, setOrderId] = useState<string>("");
    const [orderNumber, setOrderNumber] = useState<string>("");
    const [telNumber, setTelNumber] = useState<string|undefined>("");
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [problem, setProblem] = useState<string>("");

    const [phoneError, setPhoneError] = useState<string>("");
    const [orderNumberError, setOrderNumberError] = useState<string>("");
    const [problemError, setProblemError] = useState<string>("");

    const [sector, setSector] = useState<string>("");

    useEffect(()=>{
        const params = getQueryParams();
        const orderId = params["order_id"];
        if (!orderId) return;
        getOrderById(orderId).then(order => {
            if (!order) return;
            setSector(order?getOrderSector(order):"");
            setOrderId(order._id);
            if (order.orderNumber) {
                setOrderNumber(order.orderNumber);
            } else {
                setOrderNumber(order._id);
            }
            if (order.contact?.telNumber) setTelNumber(order.contact?.telNumber);
        });
    }, []);  // eslint-disable-line

    useEffect(() => {
        setPhoneError("");
        setOrderNumberError("");
        setProblemError("");
    }, [telNumber, orderNumber, problem]);

    const next = async () => {
        let error = false;
        if (!telNumber || !isValidPhoneNumber(telNumber)) {
            setPhoneError(_("Diese Telefonnumer ist ungültig"));
            error = true;
        }
        if (!orderNumber) {
            setOrderNumberError(_("Auftragsnummer angeben"));
            error = true;
        }
        if (!problem) {
            setProblemError(_("Wähle ein Problem aus"));
            error = true;
        }
        if (error) return;

        await wl.load(reportProblem(
            orderNumber,
            problem,
            telNumber||"",
            emailAddress,
            orderId
        ));
        if (orderId) {
            const url = "/order?order_id="+orderId;
            navigate(url);
        } else {
            navigate("/");
        }
    };

    const problems = [
        "Ich habe meine Bestellung nicht erhalten",
        "Bestellung unvollständig oder fehlerhaft",
        "Anderes Problem"
    ];

    if (!theme) return <div/>;

    return <>
        <PageHeader onBack={back} isBackAvailable={true} header={__("Problem melden")}/>
        <div className="help-content">
            <p>{_("Es tut uns leid zu hören, dass es ein Problem mit Deiner Bestellung gab.")}</p>
            <ul>
                <li>{_("Sende untenstehende Formular ab")}</li>
                <li className="error-text fw-bold">{_("Bitte wende dich an [Kiosk_Name]/[Sector]", {
                    "[Kiosk_Name]": theme?.title??"",
                    "[Sector]": sector
                })}</li>
            </ul>
            <div className="problem-form">
                <label className="form-label">
                    <strong>{_("Bestellnummer")}</strong>
                </label>
                <div className="input-container">
                    <input
                        value={orderNumber}
                        onChange={e=>setOrderNumber(e.target.value)}
                        type="text"
                        disabled={!!orderId}
                    />
                </div>
                {orderNumberError&&<div className="input-error">{orderNumberError}</div>}
                <div className="checkbox-list">
                    {problems.map((p, i)=>
                        <div className="form-check primary" key={i}>
                            <CheckInput
                                className="form-check-input"
                                type="radio"
                                name="sector-selector"
                                id={"problem_"+i}
                                onChange={(e: React.ChangeEvent)=>setProblem((e.currentTarget as any).value)}
                                value={p}
                                checked={p===problem}
                            />
                            <label className="form-check-label"
                                   htmlFor={"problem_"+i}
                            >
                                {_(p)}
                            </label>
                        </div>
                    )}
                    {problemError&&<div className="input-error">{problemError}</div>}
                </div>
                <label className="form-label">
                    {_("Mobiltelefon")}
                </label>
                <div className="input-container">
                    {/* @ts-ignore-next-line */}
                    <PhoneInput
                        defaultCountry={"CH"}
                        placeholder="Enter phone number"
                        value={telNumber}
                        onChange={setTelNumber}/>
                </div>
                {phoneError&&<div className="input-error">{phoneError}</div>}
                <label className="form-label">
                    {_("Emailadresse (optional)")}
                </label>
                <div className="input-container">
                    <input
                        value={emailAddress}
                        onChange={e=>setEmailAddress(e.target.value)}
                        type="text"
                    />
                </div>

                <button className="btn btn-primary big-primary"
                    onClick={next}
                    disabled={loading}
                    type="button"
                >
                    {_("Zusenden")}
                </button>
            </div>
        </div>
    </>;
};

