import "./MainPage.sass";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ProductsHorizontalList } from "./products/ProductsHorizontalList";

import { ProductsVerticalList } from "./products/ProductsVerticalList";
import { useAppDispatch, useAppSelector } from "../store/store";
import {
    commonSlice,
    languageSelector, searchFocusProductSelector,
    selectedDeliveryProviderSelector,
    selectedSectorSelector,
} from "../service/common.slice";
import { loadProducts, popularProductsSelector, productsAssortmentsSelector } from "../service/products.slice";
import { ProductAssortments } from "../service/products.service";
import { isSectorAvailable, withLoading } from "../service/utils";
import { ProductsLoading } from "./products/ProductsLoading";
import { CartSticky } from "./checkout/CartSticky";
import { SectorSelectorDownPopup } from "./SectorSelectorDownPopup";
import { MainPageHeader } from "../common/MainPageHeader";
import { MainPageSlider } from "./MainPageSlider";
import { CurrentOrdersShort } from "./checkout/CurrentOrdersShort";
import { KitchenNotification } from "./checkout/KitchenNotification";
import { SectorSelector } from "./SectorSelector";


export function MainPage() {
    const language = useAppSelector(languageSelector);
    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);
    const searchFocusedProduct = useAppSelector(searchFocusProductSelector);

    const [showSelectSector, setShowSelectSector] = useState<boolean>(false);
    const openSectorSelector = useCallback(() => {
        setShowSelectSector(true);
    }, []);
    const closeSectorSelector = useCallback(() => {
        setShowSelectSector(false);
    }, []);


    const deliveryProvider = useAppSelector(selectedDeliveryProviderSelector);

    const productsAssortments = useAppSelector(productsAssortmentsSelector);
    const popularProducts = useAppSelector(popularProductsSelector);

    const menuScrollMargin = 95;
    const staticListRef = useRef<HTMLDivElement>(null),
        fixedListRef = useRef<HTMLDivElement>(null);
    const createCatRefs = <T,>(categories: ProductAssortments) => categories.map(() => React.createRef<T>());

    const categoryRefs = useMemo(
        () => createCatRefs<HTMLDivElement>(productsAssortments),
        [productsAssortments]
    );
    const menuRefs = useMemo(
        () => createCatRefs<HTMLLIElement>(productsAssortments),
        [productsAssortments]
    );

    const [activeCategory, setActiveCategory] = useState<string>("");
    const [anchorList, setAnchorList] = useState<boolean>(false);
    const selectCat = (catNum: number) => {
        const scrollTo = categoryRefs[catNum].current!.offsetTop;
        setActiveCategory(productsAssortments[catNum].name);
        const listHeight = fixedListRef.current?.offsetHeight || 0;
        window.scroll({
            top: scrollTo - menuScrollMargin - listHeight,
            behavior: "smooth"
        });
    };

    const dispatch = useAppDispatch();
    const sector = useAppSelector(selectedSectorSelector);
    
    useEffect(() => {
        if (searchFocusedProduct) {
            const product = Array.from(
                document.querySelectorAll(`[data-product-id="${searchFocusedProduct.id}"]`)
            ).pop();
            if (!product) return;

            const listHeight = fixedListRef.current?.offsetHeight || 0;
            const top = product.getBoundingClientRect().top + document.documentElement.scrollTop - menuScrollMargin - listHeight;

            setTimeout(() => {
                window.scroll({
                    top: top,
                    behavior: "smooth"
                });
            }, 100);

            dispatch(commonSlice.actions.setSearchFocusProduct(undefined));
        }
    }, [searchFocusedProduct]); // eslint-disable-line

    useEffect(() => {
        if (!staticListRef.current
            || !fixedListRef.current
        ) return;

        let listTopPos = staticListRef.current!.offsetTop;
        let category = -1;
        const onScroll = () => {
            if (staticListRef.current) {
                listTopPos = staticListRef.current!.offsetTop
            }
            const top = window.scrollY;

            const list = staticListRef.current!,
                anchorList = fixedListRef.current!;

            setAnchorList(top + menuScrollMargin > listTopPos);

            let i;
            for (i = 0; i < categoryRefs.length; i++) {
                if (!categoryRefs[i].current) return;
                if (top < categoryRefs[i].current!.offsetTop + 30) break;
            }
            productsAssortments[i] && setActiveCategory(productsAssortments[i].name);

            if (category !== i && menuRefs[i] && menuRefs[i].current) {
                category = i;
                let menuItemRef = menuRefs[category].current,
                    scrollLeft = menuItemRef!.offsetLeft - 16;
                anchorList.scroll({
                    left: scrollLeft,
                    behavior: "smooth"
                });
                list.scroll({
                    left: scrollLeft,
                    behavior: "smooth"
                });
            }
        };

        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        }
    }, [loading, menuRefs, categoryRefs, productsAssortments]);

    useEffect(() => {
        wl.load(dispatch(loadProducts()));
        return () => wl.cancelLoad();
    }, [language, sector?._id, deliveryProvider?._id]); // eslint-disable-line

    return <>
        <MainPageHeader onSectorSelectorOpen={openSectorSelector} />
        <MainPageSlider />
        <div className="main-page">
            <SectorSelectorDownPopup
                showSelectSector={showSelectSector || !sector}
                onClose={closeSectorSelector}
                showCloseIcon={sector&&isSectorAvailable(sector)}
            />
            <SectorSelector onContainerClick={openSectorSelector} />
            {loading && <ProductsLoading />}
            {!loading && <>
                <div className="after-selector">
                    <CurrentOrdersShort showCartIcon={true} />
                    <KitchenNotification />
                </div>
                <ProductsHorizontalList products={popularProducts} />
                
                <div ref={staticListRef} className={"product-category-list " + (!anchorList ? "show" : "")}>
                    <ul>
                        {productsAssortments.map((c, i) =>
                            <li key={c.name}
                                className={c.name === activeCategory ? "active" : "none"}
                                onClick={() => selectCat(i)}
                            >{c.name}</li>
                        )}
                    </ul>
                </div> 

                <div ref={fixedListRef} className={"product-category-list anchor " + (anchorList ? "show" : "")}>
                    <ul>
                        {productsAssortments.map((c, i) =>
                            <li className={c.name === activeCategory ? "active" : "none"}
                                key={i}
                                ref={menuRefs[i]}
                                onClick={() => selectCat(i)}
                            >{c.name}</li>
                        )}
                    </ul>
                </div>

                {productsAssortments.map((c, i) =>
                    <div ref={categoryRefs[i]}
                        key={c.name}>
                        <ProductsVerticalList
                            categoryName={c.name}
                            categoryImage={c.image.medium_src}
                            products={c.products}
                        />
                    </div>
                )}
            </>}
            <CartSticky />
        </div></>
}
