import {__, TranslatedString} from "../../translations";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {refreshOrders, themeSelector, useTimeToDeliverySeconds} from "../../service/common.slice";
import {Link, useNavigate} from "react-router-dom";
import "./CheckoutPage.sass";
import React, {useEffect, useState} from "react";
import {getOrderById, getOrderProblemByOrderId, OrderProblem} from "../../service/checkout.service";
import {CreatedOrderFragment} from "../../service/__generated__/CreatedOrderFragment";
import {
    dateToString,
    getOrderedTime,
    getOrderSector,
    getQueryParams,
    getShortOrderNumber,
    isOrderDelivery, WithLoadingWrapper,
    useLg,
    withLoading, isGeoLocationDeliverySet
} from "../../service/utils";
import {OrderIsEmptyBox} from "./OrderIsEmptyBox";
import {OrderItems} from "./OrderItems";
import {OrderSummaryRows} from "./OrderSummaryRows";
import {refreshCart} from "../../service/checkout.slice";
import {PageNotFound} from "../PageNotFound";
import {OrderStatus} from "../../globalTypes";
import { CheckoutIconPoint } from "./Icons";
import {GeoLocationView} from "../geo-location/GeoLocationView";
import { checkoutCart } from "../../service/common.service";
import paymentError from "../../assets/common/images/payment-error.svg";


const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const OrderView = () => {
    const dispatch = useAppDispatch();
    const theme = useAppSelector(themeSelector);
    const [notFound, setNotFound] = useState<boolean>(false);
    const timeToDelivery = useTimeToDeliverySeconds();

    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);

    const [backButtonLoading, setBackButtonLoading] = useState<boolean>(false);
    const wlBackButton = withLoading(setBackButtonLoading);

    const _ = useLg();
    const navigate = useNavigate();

    const [order, setOrder] = useState<CreatedOrderFragment|undefined>(undefined);
    const [orderProblem, setOrderProblem] = useState<OrderProblem|undefined>(undefined);

    const [checkoutCartProblem, setCheckoutCartProblem] = useState<string|undefined>(undefined);

    const isDelivery = order?isOrderDelivery(order):false;
    const sector = order?getOrderSector(order):"";

    const back = async () => {
        await refreshCartAndOrders(wlBackButton.load);
        const params = getQueryParams();
        navigate(params["from"] || "/");
    };

    const goToMainPage = async () => {
        await refreshCartAndOrders(wlBackButton.load);
        navigate("/");
    };

    const refreshCartAndOrders = async (loader?: WithLoadingWrapper["load"]) => {
        loader = loader || wl.load;
        await loader(dispatch(refreshCart()));
        await wlBackButton.load(dispatch(refreshOrders()));
    };

    const getOrder = async () => {
        const params = getQueryParams();

        const orderId = params["order_id"] || "";
        if (!orderId) {
            return null;
        }

        return await getOrderById(orderId);
    };

    useEffect(()=>{
        (async () => {
            setOrder(undefined);
            const params = getQueryParams();

            let order = await wl.load(getOrder());
            if (!order) {
                setNotFound(true);
                return;
            }
            if (order.status === OrderStatus.OPEN) {
                let transactionId = "";
                const possibleTransactionIdsKeys = ["datatransTrxId","transactionId"];
                for (const key of possibleTransactionIdsKeys) {
                    if (params[key]) {
                        transactionId = params[key];
                        break;
                    }
                }
                const isSaferPay = !!params["transactionId"];

                const checkout = async () => {
                    console.log(`checking out cart with transactionId ${transactionId}`);
                    const result = await wl.load(checkoutCart(params["order_id"], transactionId));
                    if (result.success) {
                        await wl.load(dispatch(refreshCart()));
                        order = await wl.load(getOrder());
                        if (!order) {
                            setNotFound(true);
                            return;
                        }
                    } else {
                        order = await wl.load(getOrder());
                        if (!order) {
                            setNotFound(true);
                            return;
                        }
                        if (order?.status === OrderStatus.OPEN) {
                            setCheckoutCartProblem(`${result.error?_(result.error):""}<br/>
                                Order id: ${order?._id} <br/>
                                Transaction id: ${transactionId}`
                            );
                            return;
                        }
                        await wl.load(dispatch(refreshCart()));
                    }
                }
                const checkForOpen = async (retries: number) => {
                    if (retries === 0) {
                        await checkout();
                        return;
                    }
                    console.log(`checking if order payment is still open (retries left: ${retries-1})`);
                    order = await wl.load(getOrder());
                    if (!order) {
                        setNotFound(true);
                        return;
                    }
                    if (order?.status === OrderStatus.OPEN) {
                        console.log("order payment is still open, delay for 2 sec and retry");
                        await wl.load(delay(2000));
                        await checkForOpen(retries-1);
                    }
                }

                try {
                    if (isSaferPay) {
                        await checkForOpen(5);
                    } else {
                        await checkout();
                    }
                } catch (e) {
                    setCheckoutCartProblem(`Error: ${(e as any).toString()}<br/>
                        Order id: ${order?._id} <br/>
                        Transaction id: ${transactionId}`
                    );
                }
                
            }

            setOrderProblem(await getOrderProblemByOrderId(order._id));
            setOrder(order);
        })();
    }, []);  // eslint-disable-line

    useEffect(()=>{
        const to = setInterval(()=>{
            getOrder().then(order => {
                if (!order) return;
                setOrder(order);
            });
        }, 5000);
        return () => clearInterval(to);
    }, []);

    if (notFound) {
        return <PageNotFound/>;
    }

    if (loading || !order) {
        return <>
            <div className="page-header">
                <div className="header">
                    <div
                        className="icon icon-left-white"
                        onClick={back}
                    />
                </div>
            </div>
            <div className="checkout-contents opacity-blink">
                <OrderIsEmptyBox label={__("Loading")}/>
            </div>
        </>;
    }

    const refresh = () => {
        window.location.reload();
    };

    if (checkoutCartProblem) {
        return (
            <div className="page-unavailable bb-container">
                <div className="img">
                    <img src={paymentError} alt="Payment error"/>
                </div>
                <h3>
                    {_("Zahlungsabbruch")}
                </h3>
                <div className="comment">
                    <TranslatedString str={__("Während des Zahlungsvorganges gab es einen Fehler oder einen Abbruch. Gehe zum Warenkorb zurück und versuche es erneut.")}/>
                    <br/>
                    <h3 dangerouslySetInnerHTML={{__html:checkoutCartProblem}}/>
                </div>
                <div className="bb-button-container">
                    <button className="btn btn-primary big-primary"
                            onClick={back}
                    >
                        {_("Zum Warenkorb")}
                    </button>
                    <br/>
                    <br/>
                    <button className="btn btn-primary big-primary"
                            onClick={refresh}
                    >
                        {_("Refresh")}
                    </button>
                </div>
            </div>
        )
    }

    const isRejected = order.status === OrderStatus.REJECTED;

    const isReservation = Boolean(order.orderlyReservationDate);

    return <div className="bb-container">
        <div className="page-header">
            <div className="header">
                <div
                    className="icon checkout-icon checkout-icon-close-white"
                    onClick={back}
                />
                {!(!isRejected&&!orderProblem)&&_("Meine Bestellung")}
                {!isRejected&&!orderProblem&&<div className="order-success" />}
            </div>
        </div>
        {orderProblem&&<div className="help-content">
            <p>
                {_("Vielen Dank für deine Meldung.")}
            </p>
            <p>
                {_("Um eine Rückerstattung oder einen Ersatz für deine Bestellung zu erhalten: ")}
            </p>
            <ul>
                <li className="error-text fw-bold">{_("Bitte wende dich an [Kiosk_Name]/[Sector]", {
                    "[Kiosk_Name]": theme?.title??"",
                    "[Sector]": sector
                })}</li>
                <li>{_("Nenne dein Problem und die Bestellnummer")}</li>
            </ul>
        </div>}
        <div className={"checkout-contents " + ((!isRejected&&!orderProblem)?"order-success":"")}>
            {!orderProblem&&!isRejected&&
                <h2 className="text-center">{_("Vielen Dank für deine Bestellung!")}</h2>
            }
            {isRejected&&<>
                <h2 className="text-center">{_("Deine Bestellung wurde storniert")}</h2>
                <div className="header-comment">
                    {_("Es tut uns leid. Momentan [reason]", {
                        "[reason]": _(order.orderlyRejectReason!)
                    })}
                    <br/>
                    {_("Der Betrag wurde nich abgebucht.")}
                </div>
            </>}

            {orderProblem&&<>
                <OrderSummaryRows
                    order={order}
                    error={__(orderProblem?.reason)}
                />
            </>}
            {!orderProblem&&<>
                {(order?.status === OrderStatus.PENDING || order?.status === OrderStatus.CONFIRMED) && (
                    <div className="summary-row-comment">
                        {order.orderlyIsTableservice 
                            ? _("Deine Bestellung wird bald zum Platz gebracht.") 
                            : _("Deine Bestellung wird zubereitet.")
                        }
                    </div>
                )}

                <OrderSummaryRows order={order} />
                <div className="order-number-container">
                    <div className="number-comment">{_("Bestellnr.")}</div>
                    <div className="number">
                        {(isReservation?"VB-":"")+getShortOrderNumber(order?.orderNumber??"")}
                    </div>
                    {isReservation?<div className="number">
                        {dateToString(new Date(order.orderlyReservationDate))}
                    </div> : 
                    <div className="number">
                        {getOrderedTime(order,timeToDelivery )}
                    </div>}
                    <div className="info-comment">
                        {isDelivery?_("Lieferung an:"):order?.orderlyIsTableservice ?_("Tischservice bei:"): _("Abholung bei:")}
                    </div>
                    <div className="info">
                        <div className="checkout-icon">
                            <CheckoutIconPoint />
                        </div>
                        <div><strong>{theme?.title??""}</strong></div>
                        <div><strong>{sector}</strong></div>
                        {((isDelivery&&!isGeoLocationDeliverySet(order))||(!isDelivery&&order.orderlyIsTableservice&&order.orderlySeatNumber))&&
                            (<div>
                                {_("Platz")}&nbsp;
                                {order.orderlySeatNumber}
                            </div>)
                        }
                    </div>
                    <div>
                        {isDelivery
                            &&isGeoLocationDeliverySet(order)
                            &&<GeoLocationView location={order.orderlyGeoLocation!} />}
                    </div>
                </div>
            </>}

            <div className="box cart">
                <OrderItems
                    orderItems={order.items!}
                    readOnly={true}
                    includeDepot={true}
                />
            </div>
        </div>
        <div className="bb-button-container order-bottom-buttons">
            {!orderProblem&&
                <Link to={"/help?order_id="+order?._id}>
                    <button className="btn btn-primary big-primary outline">
                        {_("Fragen zur Lieferung?")}
                    </button>
                </Link>
            }
            <button className="btn btn-primary big-primary"
                    disabled={backButtonLoading}
                    onClick={goToMainPage}
            >
                {_("Zur Homeseite")}
            </button>
        </div>
    </div>;
};

