import "./ServiceUnavailableScreen.sass";
import React from "react";
import { Translate} from "../translations";
import {useLg} from "../service/utils";


export const ServiceUnavailableScreen = () => {
    const _ = useLg();

    return <div className="service-unavailable-screen">
        <div className="label">1-2-eat</div>
        <div className="icon icon-warning" />
        <div className="header">
            {_("1-2-eat ist momentan leider nicht verfügbar")}
        </div>
        <div className="sub-header">
            <Translate lgKey="Es gibt ein technisches Problem"/>
        </div>
    </div>;
};
