import { useRef } from "react";
import { IconCheckboxPrimary, IconCheckboxPrimaryActive, IconCheckboxPrimaryDisabled, IconRadioPrimary, IconRadioPrimaryActive, IconRadioPrimaryDisabled } from "../Icons"


export const CheckInput = (props: any) => {
    const ref = useRef<HTMLInputElement>(null);
    const click = () => {
        ref.current?.click();
    }
    return <>
        <input
            {...props}
            ref={ref}
        />
        <span className="icon" onClick={click}>
            {props.type==="radio" ?
                props.disabled ?
                    <IconRadioPrimaryDisabled/>
                : !props.checked ?
                    <IconRadioPrimary />
                    : <IconRadioPrimaryActive/>
            : props.type==="checkbox" ?
                props.disabled ?
                    <IconCheckboxPrimaryDisabled/>
                : !props.checked ?
                    <IconCheckboxPrimary />
                    : <IconCheckboxPrimaryActive/>
            : null
            }
        </span>
    </>
}
