import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
    deliveryProvidersSelector,
    ensureCorrectDelivery,
    isProviderDelivery,
    paymentProvidersSelector,
    selectedDeliveryProviderSelector,
    selectedSectorSelector, stadiumAddressDeliverySelector
} from "../../../service/common.slice";
import {
    cartSelector,
    CartStatus,
    CheckoutStep,
    getCartStatus,
    getPaymentLink,
    navigateByCartStatus,
} from "../../../service/checkout.slice";
import React, { useEffect, useState } from "react";
import { isGeoLocationDeliverySelected, isGeoLocationDeliverySet, useLg, withLoading } from "../../../service/utils";
import { __ } from "../../../translations";
import { CheckoutHeader } from "../CheckoutPage";
import { CheckoutSteps } from "./CheckoutSteps";
import { useNavigate } from "react-router-dom";
import { PaymentProviderFragment } from "../../../service/__generated__/PaymentProviderFragment";
import postfinanceImg from "../../../assets/checkout/postfinance.png";
import { DownStick } from "../../../common/DownStick";
import { OrderTotal } from "../OrderTotal";
import { OrderItems } from "../OrderItems";
import { CheckInput } from "../../../common/contols/CheckInput";
import { CheckoutIconPoint } from "../Icons";
import "../CheckoutPage.sass"
import { CartSummary } from "./CartSummary";
import { GeoLocationView } from "../../geo-location/GeoLocationView";


export const CheckoutStep3 = () => {
    const deliveryProvider = useAppSelector(selectedDeliveryProviderSelector);
    const isDelivery = deliveryProvider ? isProviderDelivery(deliveryProvider) : false;
    const [paymentProvider, setPaymentProvider] = useState<PaymentProviderFragment | null>(null);
    const [agree, setAgree] = useState<boolean>(false);
    const [agreeError, setAgreeError] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const cart = useAppSelector(cartSelector)!;
    const [loading, setLoading] = useState<boolean>(false);
    const wl = withLoading(setLoading);

    const _ = useLg();
    const sector = useAppSelector(selectedSectorSelector);
    const navigate = useNavigate();

    const telNumber = cart?.contact?.telNumber;
    const seatNumber = cart?.orderlySeatNumber;
    const stadiumAddress = useAppSelector(stadiumAddressDeliverySelector);

    const paymentProviders = useAppSelector(paymentProvidersSelector);
    const deliveryProviders = useAppSelector(deliveryProvidersSelector);
    const multipleActiveDeliveryProviders = deliveryProviders.filter(d => d.isActive).length > 1;

    useEffect(() => {
        if (!paymentProviders || paymentProviders.length === 0) return;
        setPaymentProvider(paymentProviders[0]);
    }, [paymentProviders]);

    useEffect(() => {
        if (agree) setAgreeError(false);
    }, [agree]);

    const next = async () => {
        if (!agree) {
            setAgreeError(true);
            setTimeout(() => {
                window.scroll({
                    top: window.outerHeight,
                    behavior: "smooth"
                });
            }, 100);
            return;
        }
        if (!paymentProvider) return;

        await wl.load(dispatch(ensureCorrectDelivery()));
        const cartStatus = await wl.load(dispatch(getCartStatus()).unwrap());
        if (cartStatus === CartStatus.UnavailableSectorWithReservations
            && !cart.orderlyReservationDate
        ) {
            gotoStep2();
            return;
        }
        if (navigateByCartStatus(navigate, cartStatus)) {
            const forwardLink = await wl.load(dispatch(getPaymentLink(paymentProvider)).unwrap());
            setLoading(true);
            window.location.href = forwardLink;
        }
    };

    const gotoStep1 = () => {
        navigate("/checkout");
    }

    const gotoStep2 = () => {
        navigate("/checkout/step2");
    };

    return <>
        <CheckoutHeader
            header={__("Bezahlen")}
            back={() => { navigate("/checkout/step2") }}
        />
        <CheckoutSteps step={CheckoutStep.Payment} />
        <div className="checkout-contents">
            <CartSummary />

            <>
                <div className="summary-rows"
                    onClick={gotoStep1}
                    style={{ marginBottom: '16px' }}
                >
                    {isDelivery
                        ? (
                            (isGeoLocationDeliverySelected(cart) && isGeoLocationDeliverySet(cart))
                                ? (
                                    <>
                                        <div className="summary-row">
                                            <span style={{ fontWeight: 'bold' }}>{_("Bestellung direkt zu dir (GPS)")}</span>
                                            <span className="change-option">{_("Auf Abholung umschalten")}</span>
                                        </div>
                                        <div className="summary-row">
                                            <GeoLocationView location={cart.orderlyGeoLocation!} />
                                        </div>
                                    </>
                                )
                                : (
                                    <>
                                        <div className="summary-row">
                                            <span style={{ fontWeight: 'bold' }}>{_("Lieferung an deinen Platz")}</span>
                                            {multipleActiveDeliveryProviders
                                                && <span className="change-option">{_("Auf Abholung umschalten")}</span>
                                            }
                                        </div>
                                        <div className="summary-row">
                                            <div className="checkout-icon checkout-icon-point-white" />  <span>{_("Sektor")} {sector?.title}{", " + _("Platz") + " " + seatNumber}</span>
                                        </div>
                                    </>
                                )
                        )
                        : (
                            <>
                                <div className="summary-row">
                                    <span style={{ fontWeight: 'bold' }}>
                                        {cart.orderlyIsTableservice ? _("Tischservice") : _("Abholung")}
                                    </span>
                                    {multipleActiveDeliveryProviders
                                        && <span className="change-option">{_("Auf Lieferung umschalten")}</span>
                                    }
                                </div>
                                <div className="summary-row">
                                    <div className="checkout-icon checkout-icon-point-white" />
                                    <span>{_("Sektor")} {sector?.title}</span>
                                    {cart.orderlyIsTableservice && cart.orderlySeatNumber && <span className="right">
                                        {_("Platz")}&nbsp;
                                        {cart.orderlySeatNumber}
                                    </span>}
                                </div>
                            </>
                        )
                    }
                </div>
            </>
            {isDelivery && <div className="summary-rows" onClick={gotoStep2}>
                {stadiumAddress && <div className="summary-row">
                    <div className="checkout-icon">
                        <CheckoutIconPoint />
                    </div>
                    <div>{stadiumAddress}</div>
                </div>}
                <div className="summary-row">
                    <div className="checkout-icon checkout-icon-phone-white" />
                    <div>{telNumber}</div>
                </div>
            </div>}

            {!isDelivery && <>
                <div className="summary-rows" onClick={gotoStep2}>
                    <div className="summary-row">
                        <div className="checkout-icon checkout-icon-phone-white" />
                        <div>{telNumber}</div>
                    </div>
                </div>
            </>}
            {(!paymentProviders || paymentProviders.length === 0) && <div className="box payment-form">
                <h3>{_("Sorry, payments are temporary unavailable")}</h3>
            </div>}
            <div className="box cart">
                <OrderItems
                    readOnly={true}
                    orderItems={cart.items!}
                />
                <OrderTotal
                    cart={cart}
                    displayTipSection={true}
                />
            </div>
            {(paymentProviders && paymentProviders.length > 1) &&
                <div className="box payment-form">
                    <h3>{_("Wie möchtest du bezahlen?")}</h3>
                    <form>
                        {paymentProviders?.map(provider =>
                            <div className="form-check primary" key={provider._id}>
                                <CheckInput
                                    className="form-check-input"
                                    type="radio"
                                    name="language-selector"
                                    id={"provider_" + provider._id}
                                    onChange={() => setPaymentProvider(provider)}
                                    value={provider._id}
                                    checked={!!(paymentProvider && paymentProvider._id === provider._id)}
                                />
                                <label className="form-check-label"
                                    htmlFor={"provider_" + provider._id}
                                >
                                    {provider.interface?.label}
                                </label>

                                <div className="image">
                                    {provider.interface?._id === "shop.unchained.postfinance" &&
                                        <img src={postfinanceImg} alt="Postfinance" />
                                    }
                                </div>
                            </div>
                        )}
                    </form>
                </div>}
            <div className="box payment-form">
                <form>
                    <div className="form-check primary">
                        <CheckInput
                            className="form-check-input"
                            type="checkbox"
                            name="language-selector"
                            id="agree"
                            onChange={() => setAgree(v => !v)}
                            checked={agree}
                        />
                        <label className="form-check-label agree-label"
                            htmlFor="agree"
                        >
                           Mit der Bezahlung akzeptiere ich die <a href="/terms" onClick={(e) => { navigate("/terms"); e.preventDefault(); }}>AGBs</a> {_("des Zahlungsanbieters")}
                        </label>
                    </div>
                    {agreeError && <div className="error">Bitte bestätige zuerst die AGB</div>}
                </form>
            </div>
        </div>
        <div className="cart-sticky-container">
            <DownStick>
                <button className="btn btn-primary big-primary"
                    onClick={next}
                    disabled={loading}
                >
                    {loading ? "Lädt..." : _("Bezahlen")}
                </button>
            </DownStick>
        </div>
    </>
};
