import "./ImageBlock.sass";
import {LazyLoadImage} from "react-lazy-load-image-component";

interface ImageProps {
    src?: string;
    squared?: boolean;
}

export const ImageBlock = (props: ImageProps) => {
    return <div className={"image-block " + (props.squared?"squared":"")}>
        {props.src&&<LazyLoadImage
            alt="Product"
            src={props.src}
            delayTime={5600}
            placeholder={
                <div className="image-placeholder">
                    <div className="icon icon-image-placeholder" />
                </div>
            }
        />}
    </div>;
};
