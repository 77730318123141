import {toast} from "react-toastify";
import {
    CreatedOrderFragment,
    CreatedOrderFragment_deliveryProvider_OrderDeliveryPickUp
} from "./__generated__/CreatedOrderFragment";
import {isProviderDelivery, languageSelector} from "./common.slice";
import {__, LanguageTranslationFunc, lg} from "../translations";
import {CartFragment} from "./__generated__/CartFragment";
import {OrderStatus} from "../globalTypes";
import {ProductAvailability} from "./products.service";
import {OrderlySectorFragment} from "./__generated__/OrderlySectorFragment";
import {OrderItemFragment} from "./__generated__/OrderItemFragment";
import {useAppSelector} from "../store/store";
import { useCallback, useMemo, useState } from "react";

export type SetBooleanCallback = (value: boolean) => void;

export class WithLoadingWrapper {
    loading = false;
    private active = true;
    
    constructor(private setLoading: SetBooleanCallback) {
        this.load = this.load.bind(this);
        this.cancelLoad = this.cancelLoad.bind(this);
    }

    load <T>(promise: Promise<T>): Promise<T> {
        this.active = true;
        this.setLoading(true);
        this.loading = true;
        return new Promise((resolve, reject) => {
            promise
                .then(p => {
                    this.loading = false;
                    if (!this.active) return;
                    this.setLoading(false);
                    resolve(p as any);
                })
                .catch(e => {
                    if (!this.active) {
                        console.error(e);
                        return;
                    }
                    this.loading = false;
                    this.setLoading(false);
                    reject(e);
                });
        });
    }
    cancelLoad() {
        this.loading = false;
        this.setLoading(false);
        this.active = false;
    }
}

export const withLoading = (setLoadingCB: SetBooleanCallback): WithLoadingWrapper => {
    return new WithLoadingWrapper(setLoadingCB);
};


export type LoadingWrapper = [boolean, WithLoadingWrapper];

export const useLoading = (): LoadingWrapper => {
    const [loading, setLoading] = useState(false);
    const wrapper = useMemo(()=>withLoading(setLoading), []);
    return [loading, wrapper];
};

const localLogs: string[] = []
export const addLog = (l: string) => localLogs.push(l);

export const showError = (errorText: string) => {

    toast.error(
        <>
            {errorText}
            <br/>
            Details: {localLogs.join(', ')}
        </>,
        {
            hideProgressBar: true,
            autoClose: false
        }
    );
};

export const getOrderedTime = (order: CreatedOrderFragment, shiftSeconds = 0): string => {
    let dt = new Date(order.ordered);
    dt = new Date(dt.getTime() + shiftSeconds*1000);
    if (order.orderlyReservationDate) {
        dt = new Date(order.orderlyReservationDate);
    }
    return dateToString(dt);
};

const pad10 = (n: number): string => {
    if (n >= 10) return n.toString();
    return "0"+n.toString();
};

export const isOrderDelivery = (order: CreatedOrderFragment) => {
    const deliveryProvider = order.deliveryProvider?.provider;
    return deliveryProvider?isProviderDelivery(deliveryProvider):false;
};

export const getOrderSector = (order: CreatedOrderFragment) => {
    return (order.deliveryProvider as CreatedOrderFragment_deliveryProvider_OrderDeliveryPickUp)
        ?.activePickUpLocation?.name || "";
};

export const getOrderColorAndStatus = (order: CreatedOrderFragment): {status: LanguageTranslationFunc, color: string} => {
    let status = __(""), color = "";
    const isDelivery = order?isOrderDelivery(order):false;
    const isTableService = order?order.orderlyIsTableservice:false;
    if (order.status === OrderStatus.PENDING) {
        status = order.orderlyReservationDate
            ?__("Vorbestellt")
            :__("Bestätigt");
        color = "#0758D2";
    }
    if (order.status === OrderStatus.CONFIRMED) {
        status = (isDelivery||isTableService)?__("Unterwegs"):__("Abholbereit");
        color = "#00A944";
    }
    if (order.status === OrderStatus.FULLFILLED) {
        status = __("Geliefert");
        color = "#004E1F";
    }
    if (order.status === OrderStatus.REJECTED) {
        status = __("Storniert");
        color = "#EA7000";
    }
    return {status, color};
};

export const isCartEmpty = (cart?: CartFragment): boolean => {
    return !cart || cart.items?.length===0;
};

export const hasUnavailableProductsInCart = (cart?: CartFragment): boolean => {
    if (!cart) return false;
    return cart.items!.filter(item=>!isOrderItemAvailable(item)).length > 0;
};

export const isOrderItemAvailable = (orderItem: OrderItemFragment): boolean => {
    let optionsAvailable = (orderItem.orderlyOptions || [])
        .filter(option=>(option as any).orderlyAvailability!==ProductAvailability.AVAILABLE)
        .length===0;
    return (orderItem.product as unknown as any).orderlyAvailability===ProductAvailability.AVAILABLE
        && optionsAvailable;
};

export const isSectorActive = (sector: OrderlySectorFragment): boolean => {
    return sector.isActive && sector.supportedDeliveryProviders.length > 0;
};

export const sectorHasAvailableReservations = (sector: OrderlySectorFragment) => {
    return sector.reservationHours && (sector.reservationHours as any[]).some(s=>s.active);
}

export const isSectorHasTableService = (sector: OrderlySectorFragment) => {
    return sector.isTableserviceAllowed;
}

export const isSectorSameDayPreorderEnabled = (sector: OrderlySectorFragment) => {
    return sector.isSameDayPreOrderEnabled;
}

export const isPossibleReservationDate = (date: Date, sector: OrderlySectorFragment) => {
    return true
}

export const dateToString = (date: Date) => {
    return `${dateOnlyToString(date) }, ${pad10(date.getHours())}:${pad10(date.getMinutes())}`;
}

export const dateOnlyToString = (date: Date) => {
    return `${date.getDate()}.${date.getMonth()+1}`;
}

export const weekdayTranslationKeys = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag',
]

export const getTodayWeekday = () => {
    let nowDayLocal = new Date().getDay() - 1;
    if (nowDayLocal < 0) nowDayLocal = 6;
    return nowDayLocal;
};

export const getDateOfWeekday = (weekday: number) => {
    const nowDayLocal = getTodayWeekday();
    const nextWeek = weekday < nowDayLocal;
    const addDays = Number(nextWeek) * 7 + weekday - nowDayLocal;
    let todayStartOfDayLocal = new Date();
    todayStartOfDayLocal.setHours(0);
    todayStartOfDayLocal.setMinutes(0);
    todayStartOfDayLocal.setSeconds(0);
    todayStartOfDayLocal.setMilliseconds(0);
    return new Date(todayStartOfDayLocal.getTime() + addDays * 1000 * 60 * 60 * 24);
}

export const displayAmount = (amount?: number) => {
    amount = amount || 0;
    return (amount/100).toFixed(2);
};

export const getQueryParams = (): {[key: string]: string} => {
    return (window.location.search||'?')
        .substring(1)
        .split('&')
        .map(s=>s.split('='))
        .reduce((acc, v) => ({...acc,...{[v[0]]:decodeURIComponent(v[1])}}), {});
};

export interface OrderDiscount {
    id: string;
    label: string;
    amount: number;
    currency: string;
}

export const getOrderDiscounts = (cart: CartFragment, removeNegative = true) => {
    return cart.discounts!.map(value => ({
        id: value.interface?._id,
        label: value.interface?.label,
        amount: value.total.amount,
        currency: value.total.currency
    } as OrderDiscount)).filter(d=>(d.amount>0 || (removeNegative && d.amount < 0)));
};

export const getOrderSubTotal = (cart: CartFragment) => {
    const tips = cart.discounts?.find(d=>d?.interface?._id === "ch.orderly.discount.tip");
    return cart.goods!.amount - getOrderDiscounts(cart, false).reduce((acc, v) => acc+v.amount, 0) + (tips?.total.amount || 0);
};

export const getTextHtml = (comment: string): string => {
    return comment.replace(/\n/g, "<br/>");
};

export const hexToRgb = (hex: string): string => {
    hex = hex.substring(1);
    return Array.from(Array(3))
        .map((_, i) =>
            parseInt(hex.substring(i*2, i*2+2), 16)
                .toString()
        ).join(',');
};

export const useLg = () => {
    const lang = useAppSelector(languageSelector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(lg(lang), [lang]);
};

export const getShortOrderNumber = (orderNumber: string) => {
    return orderNumber.split("-").pop();
};

export const timeStringToMinutes = (timeString: string): number => {
    const [hoursStr, minutesStr] = timeString.split(":");
    const hours = Number(hoursStr), minutes = Number(minutesStr);
    if (isNaN(hours) || isNaN(minutes)) throw new Error(`Incorrect time string: ${timeString.toString()}`);
    return hours*60 + minutes
}

export const minutesToTimeString = (minutes: number): string => {
    return `${pad10(Math.floor(minutes/60))}:${pad10(minutes%60)}`
}

export const utcTimeStringToLocalTime = (timeString: string): string => {
    const minutesNow = timeStringToMinutes(timeString) - new Date().getTimezoneOffset();
    return minutesToTimeString(minutesNow);
}

export const utcDateToUtcMinutes = (date: Date): number => {
    return date.getUTCHours()*60 + date.getUTCMinutes();
}

export const utcDateToUtcTimeString = (date: Date): string => {
    return minutesToTimeString(utcDateToUtcMinutes(date));
}

export const dateToLocalMinutes = (date: Date): number => {
    return date.getHours()*60 + date.getMinutes();
}
export const getCurrentUTCDate = () =>  {
    const now = new Date();
    const utcString = now.toISOString(); // ISO string is in UTC
    return new Date(utcString);
}

export const getTimesRange = (startTime: string, endTime: string): string[] => {
    const startMinutes = timeStringToMinutes(startTime),
        endMinutes = timeStringToMinutes(endTime);

    const times = [];
    const dtOfs = new Date().getTimezoneOffset();
    const tStart = Math.max(dtOfs, startMinutes);
    for (let i = Math.ceil(tStart/30)*30; i < Math.min(24 * 60 + dtOfs, endMinutes); i+=30) {
        times.push(minutesToTimeString(i));
    }
    return times;
}

export const isGeoLocationDeliveryEnabled = (sector: OrderlySectorFragment) => {
    return Boolean(sector.isGeoLocationDeliveryEnabled);
}

export const isGeoLocationDeliveryOnly = (sector: OrderlySectorFragment) => {
    return Boolean(sector.isGeoLocationDeliveryOnly);
}

export const isTableServiceOnly = (sector: OrderlySectorFragment) => {
    return Boolean(sector.isTableServiceOnly);
}

export const isGeoLocationDeliverySelected = (cart: CartFragment|CreatedOrderFragment) => {
    return Boolean(cart.orderlyGeoLocation);
}
export const isGeoLocationDeliverySet = (cart: CartFragment) => {
    return isGeoLocationDeliverySelected(cart) && (cart.orderlyGeoLocation?.accuracy || 0) > 0
}

export const isSectorAvailable = (sector: OrderlySectorFragment) => {
    return isSectorActive(sector) || sectorHasAvailableReservations(sector);
}
