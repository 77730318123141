import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../common/PageHeader";
import { TranslatedString, __ } from "../../translations";
import { useLg } from "../../service/utils";
import SettingsIconSrc from "../../assets/help/settings.png";
import NextIconSrc from "../../assets/help/next.png";

export const ActivateGPSPage = () => {
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    const _ = useLg();
    
    return <>
        <PageHeader 
            onBack={back} 
            isBackAvailable={true} 
            header={__("GPS aktivieren")}
        />
        <div className="help-content">
            <h6>{_("Ändern Sie Ihre Standardstandorteinstellungen")}</h6>

            <b>Android</b>
            <ol>
                <li>
                    {_("Tippen Sie rechts neben der Adressleiste auf Mehr.")} <img src={SettingsIconSrc} alt="More" /> <img src={NextIconSrc} alt="and then" /> <b>{_("Einstellungen")}</b>
                </li>
                <li>
                    <TranslatedString str={__("Tap <b>Site settings</b>")}/> <img src={NextIconSrc} alt="and then" /> <b>{_("Standort")}.</b>
                </li>
                <li>
                    <TranslatedString str={__("Turn <b>Location</b> on or off.")}/>
                </li>
            </ol>

            <b>iPhone & iPad</b>
            <ol>
                <li>
                    {_("Öffnen Sie auf Ihrem iPhone oder iPad die App Einstellungen.")}
                </li>
                <li>
                    {_("Suchen Sie Ihren Browser und tippen Sie darauf.")}
                </li>
                <li>
                    <TranslatedString str={__("Tippen Sie auf <b>Standort</b>")}/>
                </li>
                <li>
                    {_("Wählen Sie die gewünschte Option als Standardeinstellung.")}
                </li>
            </ol>
        </div>
    </>
}
