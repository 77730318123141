import "./CartSticky.sass";
import {DownStick} from "../../common/DownStick";
import {useAppSelector} from "../../store/store";
import {cartSelector, getCartQty} from "../../service/checkout.slice";
import {useNavigate} from "react-router-dom";
import {displayAmount, getOrderSubTotal, useLg} from "../../service/utils";

export const CartSticky = () => {
    const navigate = useNavigate();
    const _ = useLg();
    const cart = useAppSelector(cartSelector);

    if (!cart) return null;

    const totalQty = getCartQty(cart);

    const goToCart = () => {
        navigate("/checkout");
    };

    return <div className="cart-sticky-container">
        <DownStick>
            <button className="btn btn-primary big-primary cart-sticky"
                    onClick={goToCart}
            >
                <div className="cart-qty">
                    <div className="cart-qty-inner">
                        <div className="checkout-icon checkout-icon-cart"/>
                        <div className="qty">{totalQty}</div>
                    </div>
                </div>
                <div className="label">{_("Warenkorb")}</div>
                <div className="price">
                    {cart?.total?.currency}&nbsp;
                    {displayAmount(getOrderSubTotal(cart))}
                </div>
            </button>
        </DownStick>
    </div>
};

