import * as React from "react";
import {useNavigate} from "react-router-dom";
import "./PageUnavailable.sass";
import {__,  TranslatedString} from "../translations";
import notFound from "./../assets/common/images/not-found.svg";
import {useLg} from "../service/utils";

export const PageNotFound = () => {
    const _ = useLg();
    const navigate = useNavigate();
    const back = () => {
        navigate("/");
    };

    return <div className="page-unavailable bb-container">
        <div className="img">
            <img src={notFound} alt="Not found"/>
        </div>
        <h2>404</h2>
        <h3>
            {_("Seite nicht gefunden")}
        </h3>
        <div className="comment">
            <TranslatedString str={__("Entschuldige die Unannehmlichkeit.")}/>
        </div>
        <div className="bb-button-container">
            <button className="btn btn-primary big-primary"
                    onClick={back}
            >
                {_("Zu 1-2-eat")}
            </button>
        </div>
    </div>;
};
