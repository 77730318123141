import {useEffect, useState} from "react";


export const useIsInputFocus = () => {
    const [isInFocus, setInFocus] = useState(false);

    useEffect(()=>{
        const createEvent = (action: VoidFunction) => {
            return (e: FocusEvent) => {
                if (
                    !e.target
                    || !('matches' in e.target)
                    || !(e.target as HTMLElement).matches("input[type=text],input[type=number],input[type=tel]")
                ) return;
                action();
            }
        }
        const onFocus = createEvent(() => setInFocus(true));
        const onBlur = createEvent(() => setInFocus(false));

        document.addEventListener("focusin", onFocus);
        document.addEventListener("focusout", onBlur);

        return () => {
            document.removeEventListener("focusin", onFocus);
            document.removeEventListener("focusout", onBlur);
        }
    }, []);

    return isInFocus;
}
