import {getOrderedTime, useLg, isOrderDelivery, dateToString} from "../../service/utils";
import {__, LanguageTranslationFunc, TranslatedString} from "../../translations";
import {OrderStatus} from "../../globalTypes";
import {CreatedOrderFragment} from "../../service/__generated__/CreatedOrderFragment";
import { useTimeToDeliverySeconds } from "../../service/common.slice";


export const OrderSummaryRows = (
    {
        error,
        order
    }:
    {
        error?: LanguageTranslationFunc,
        order: CreatedOrderFragment
    }
) => {
    const _ = useLg();
    const isDelivery = order?isOrderDelivery(order):false;

    if (order.status === OrderStatus.REJECTED) {
        error = __("Bestellung storniert");
    }
    const timeToDeliverySeconds = useTimeToDeliverySeconds();
    const deliveryText = isDelivery
        ?_("Voraussichtl. Lieferzeitpunkt")
        :_("Voraussichtl. Fertigstellung");

    const isReservation = order.orderlyReservationDate && !order.orderlyIsReservationConfirmed;

    const commentText = isDelivery
        ?__("Wir benachrichtigen dich per SMS sobald deine Bestellung geliefert wird. ")
        :__("Du wirst per SMS benachrichtigt sobald deine Bestellung bereit ist.");

    return <div className={"summary-rows " + (error?"danger":"")}>
        {!error&&<>
            {isReservation&&<>
                <div className="summary-row">
                    <div className="fw-bolder">{_("Vorbestellt auf")}</div>
                    <div className="fw-bolder right">
                        {dateToString(new Date(order.orderlyReservationDate))}
                    </div>
                </div>
                <div className="summary-row comment">
                    {isDelivery
                        ?_("Ihre Bestellung wird für diese Zeit vorbereitet. Wir benachrichtigen Sie per SMS, sobald die Lieferung erfolgt")
                        :_("Deine Bestellung wird auf diese Uhrzeit vorbereitet. Wir benachrichtigen dich per SMS sobald du sie abholen kannst")
                    }
                </div>
            </>}
            {!isReservation&&<>
                <div className="summary-row">
                    <div className="fw-bolder">{deliveryText}</div>
                    <div className="fw-bolder right">
                        {getOrderedTime(order, timeToDeliverySeconds)}
                    </div>
                </div>
            </>}
        </>}
        {error&&<>
            <div className="summary-row">
                <div><strong>{_("Bestellnummer")}</strong></div>
                <div className="right">
                    <strong>{order?.orderNumber}</strong>
                </div>
            </div>
            <div className="summary-row">
                <div className="checkout-icon checkout-icon-danger" />
                <div><TranslatedString str={error} /></div>
            </div>
        </>}
        {!order.orderlyReservationDate&&<>
            <div className="summary-row comment">
                <div><TranslatedString str={commentText}/></div>
            </div>
        </>}
    </div>;
};
