import React from "react";
import "./ProductsHorizontalList.sass";
import {ImageBlock} from "../../common/ImageBlock";
import { IconStar } from "../../common/Icons";


export const ProductsLoading = () => {
    const line = () => <div className="product-list-container loading">
        <div className="header-container">
            <div className="main-header">
                <div className="icon">
                    <IconStar />
                </div>
                <div className="header" />
            </div>
            <div className="sub-header" />
        </div>
        <div className="product-list">
            {Array.from(
                Array(3)
            ).map((_, k)=>
                <div className="product" key={k}>
                    <div className="image">
                        <ImageBlock />
                    </div>
                    <div className="product-name">
                        Pommes
                    </div>
                    <div className="price">
                        <div className="value">5.00</div>
                        <div className="icon" />
                    </div>
                </div>
            )}
        </div>
    </div>;

    return <>
        {line()}
        <div className="product-category-list show loading">
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
        {line()}
    </>;
};

