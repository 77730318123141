import "./ProductsHorizontalList.sass"
import React from "react";
import { Product } from "../../service/products.service";
import { ProductCard } from "./ProductCard";
import { IconStar } from "../../common/Icons";

export const ProductsHorizontalList = (
    { products }: { products: Product[] }
) => {
    return <div className="product-list-container">
        <div className="header-container">
            <div className="main-header">
                <div className="icon">
                    <IconStar />
                </div>
                <div className="header">
                    Beliebtes Essen
                </div>
            </div>
        </div>
        <div className="product-list">
            {products.map((product, i) => {
                // Assuming `isDelivery` is a prop or state that needs to be passed or imported

                if (!product.isActive) {
                    // Return an empty container or a placeholder for undeliverable products
                    return <div key={i}></div>
                } else {
                    return <ProductCard product={product} key={i} />;
                }

                // Return the ProductCard component for deliverable products

            })}
        </div>
    </div>;
};

